import { fetchGetFlags } from "./actions";
import { getIsFlagsLoaded, isUnhitchEnable } from "./selectors";
import FlagsSlice from "./reducers";

// actions
export const { resetFlags } = FlagsSlice.actions;

// async actions
export { fetchGetFlags };

// selectors
export { getIsFlagsLoaded, isUnhitchEnable };

// reducer
export default FlagsSlice.reducer;

// types
export enum MissionPageUpdates {
  enabledOldMissionPage = 0, // old mission page on, new mission page off
  enabledNewMissionPages = 1, // old mission page off, new mission page on
  enabledAllMissionPages = 2, // both mission pages on
}
