import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import { getOperatorPickedStation } from "../operatorStation";
import { DataStatus } from "../../types";

const getOperatorMissionPlansByStopId = (state: RootState) =>
  state.operatorMissionPlans.missionPlansByStopId;
const getOperatorMissionPlansStatus = (state: RootState) =>
  state.operatorMissionPlans.status;
export const getIsOperatorMissionPlansLoaded = createSelector(
  getOperatorMissionPlansStatus,
  (status) => status === DataStatus.succeeded
);
export const getIsOperatorMissionPlansLoadingError = createSelector(
  getOperatorMissionPlansStatus,
  (status) => status === DataStatus.failed
);

export const getIsOperatorMissionPlansEmpty = createSelector(
  getOperatorMissionPlansByStopId,
  getOperatorMissionPlansStatus,
  (operatorMissionPlansByStopId, status) =>
    status === DataStatus.succeeded &&
    operatorMissionPlansByStopId &&
    Object.values(operatorMissionPlansByStopId).length === 0
);

export const getCurrentOperatorMissionPlan = createSelector(
  getOperatorMissionPlansByStopId,
  getOperatorPickedStation,
  (missionPlansByStopId, pickedStation) => {
    if (!pickedStation) return null;
    return missionPlansByStopId?.[pickedStation.id] ?? null;
  }
);
