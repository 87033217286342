export interface InterectionEntityWithVehicleInfo extends IntersectionEntity {
  vehicle_name: string;
  vehicle_link: string | undefined;
}

export interface IntersectionNamePermission {
  id: string;
  name: string;
  activePermissions: InterectionEntityWithVehicleInfo[];
}

function getVehicleInfoById(
  vehicle_id: string,
  vehicles: Vehicle[],
  types: VehicleType[]
) {
  let name = "Vehicle";
  let link;
  const vehicle = vehicles.filter((each) => each.id === vehicle_id);
  if (vehicle.length > 0) {
    const { vehicleTypeId, name: vehicleName } = vehicle[0];
    name = vehicleName;
    const type = types.filter((each) => each.id === vehicleTypeId);
    if (type[0]?.image?.link) {
      link = type[0].image.link;
    }
  }
  return {
    name,
    link,
  };
}

export function buildIntersectionNameAndPermissionsMap(
  intersections: Record<string, string>,
  permissions: IntersectionEntity[],
  vehicles: Vehicle[],
  vehicleTypes: VehicleType[]
): IntersectionNamePermission[] {
  const data: Record<string, IntersectionNamePermission> = {};
  for (const [key, name] of Object.entries(intersections)) {
    const targetPermissons = permissions.filter(
      (each) => each.intersection_id === parseInt(key)
    );
    data[key] = {
      id: key,
      name,
      activePermissions: targetPermissons.map((each) => {
        const { vehicle_id } = each;
        const { name, link } = getVehicleInfoById(
          vehicle_id,
          vehicles,
          vehicleTypes
        );
        return {
          vehicle_name: name,
          vehicle_link: link,
          ...each,
        };
      }),
    };
  }
  return Object.values(data).sort(
    (a, b) => b.activePermissions.length - a.activePermissions.length
  );
}
