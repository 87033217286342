import React, { FC, useState } from "react";

import { validateEmail } from "Utils";

import { Title } from "./common/Title/Title";
import { Input } from "./common/Input/Input";
import { SubmitButton } from "./common/SubmitButton/SubmitButton";
import { FormContainer } from "./common/FormContainer/FormContainer";
import { Text } from "./common/Text/Text";
import { CancelStyled, ErrMsgStyled } from "./styles";

type ForgotPasswordProps = {
  onSubmit: (data: { email: string; callback: () => void }) => void;
  onCancel: () => void;
};

export const ForgotPasswordComponent: FC<ForgotPasswordProps> = ({
  onCancel,
  onSubmit,
}) => {
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    const currentEmail = email || "";

    setIsEmailInvalid(false);
    if (!currentEmail || !validateEmail(currentEmail)) {
      setIsEmailInvalid(true);
      return;
    }

    onSubmit({ email: currentEmail, callback: onCancel });
  };

  return (
    <FormContainer handleSubmit={handleSubmit}>
      <Title>Forgot Password</Title>
      {isEmailInvalid && <ErrMsgStyled>Valid email required.</ErrMsgStyled>}
      <Text>
        Enter your email, and if there’s an associated account, we’ll send you a
        recoverylink.
      </Text>
      <Input
        type="email"
        name="email"
        onChange={(e) => setEmail(e.currentTarget.value.toLowerCase())}
        placeholder="Email"
        value={email}
      />
      <SubmitButton>Send recovery e-mail</SubmitButton>
      <CancelStyled onClick={onCancel}>CANCEL</CancelStyled>
    </FormContainer>
  );
};
