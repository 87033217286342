import axios from "axios";

import { FMSLogger } from "FMSLogger";
import { sendSentryEvent } from "./sentryUtils";
import { BASE } from "./api";

const networkLogger = FMSLogger.byPrefix("network-logs");

const instance = axios.create({
  baseURL: BASE,
});

instance.defaults.headers.common["Authorization"] =
  window.localStorage.getItem("JWT");

instance.interceptors.response.use(
  (response) => {
    networkLogger.debug(
      "API response",
      response.config.url as string,
      response.data
    );
    return response;
  },
  async (error) => {
    networkLogger.error(
      "API error",
      error.message,
      error.code,
      error.config.url,
      error.response?.data ?? ""
    );
    if (!axios.isCancel(error)) {
      sendSentryEvent(error, "API error");
    }
    throw error;
  }
);

instance.interceptors.request.use(function (config) {
  const token = window.localStorage.getItem("JWT");
  config.headers.Authorization = token;
  config.headers["X-Api-Key"] = window._env_.CLOUD_INTERSECTION_BROKER_API_KEY;
  networkLogger.debug(
    "API request",
    config.url as string,
    config.method as string,
    config.data ?? ""
  );
  return config;
});

export default instance;
