import styled from "styled-components";
import Background from "./assets/backgroung.svg";

export const AuthenticationContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url("${Background}");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;

  button:focus {
    border: 2px solid #8fbdff;
  }
`;

export const LoggedInNotify = styled.div`
  background-color: #e4d44b;
`;
