import React, { ReactNode, FC } from "react";

import { ReactComponent as Logo } from "../../assets/Logo.svg";
import { FormContainerStyled } from "./styles";

type FormContainerProps = {
  children?: ReactNode;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
};

export const FormContainer: FC<FormContainerProps> = ({
  children,
  handleSubmit,
}) => (
  <FormContainerStyled onSubmit={handleSubmit}>
    <Logo />
    {children}
  </FormContainerStyled>
);
