import { toast } from "react-toastify";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "../..";
import { getCurrentUserId } from "Slices/currentUser";
import { revokeEntryPermission } from "Slices/intersections";
import API, { showNetworkErrorToast } from "Utils/api";
import CloudIntersectionBrokerAPI from "Utils/cloudIntersectionBrokerAPI";

export const fetchGetIntersectionIdsFromSemanticMap = createAsyncThunk(
  "intersections/fetchGetIntersectionIdsFromSemanticMap",
  async () => {
    try {
      const response = await API.get("semantic_map_intersections/");
      const semanticMapIntersectionMapping: Record<
        string,
        Record<number, string>
      > = {};
      response.data.results.forEach((each: SemanticMapIntersection) => {
        const { intersectionId, intersectionName, semanticMap } = each;
        if (semanticMapIntersectionMapping[semanticMap] === undefined) {
          semanticMapIntersectionMapping[semanticMap] = {};
        }
        semanticMapIntersectionMapping[semanticMap][intersectionId] =
          intersectionName;
      });
      return semanticMapIntersectionMapping;
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

export const fetchGetIntersections = createAsyncThunk(
  "intersections/fetchGetIntersections",
  async (intersectionIds: number[]) => {
    const data: IntersectionEntity[][] = await Promise.all(
      intersectionIds.map(async (id) => {
        try {
          // Note: This API call requires redirecting to cloud-intersection-broker server (see INFR-11076)
          // The API call might not work on local server
          const intersectionResponse = await CloudIntersectionBrokerAPI.get(
            `intersections/${id}/entry-permissions?is_active=true`
          );
          return intersectionResponse.data ?? [];
        } catch (error) {
          showNetworkErrorToast(error);
          return Promise.reject();
        }
      })
    );
    return data.flat();
  }
);

export const fetchPatchPermission = createAsyncThunk<
  void,
  {
    intersection_id: number;
    entry_permission_id: string;
    vehicle_id: string;
    successMessage: string;
  }
>(
  "intersections/fetchPatchPermission",
  async (
    { intersection_id, entry_permission_id, vehicle_id, successMessage },
    thunkAPI
  ) => {
    const currentUserId = getCurrentUserId(thunkAPI.getState() as RootState);
    const payload = {
      update_op_type: "revoke",
      intersection_id,
      entry_permission_id,
      vehicle_id,
      requested_by: currentUserId,
    };
    try {
      const response = await CloudIntersectionBrokerAPI.patch(
        `/intersections/${intersection_id}/entry-permissions/${entry_permission_id}`,
        payload
      );
      thunkAPI.dispatch(revokeEntryPermission(response.data.id));
      toast.success(successMessage);
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);
