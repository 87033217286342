import styled from "styled-components";

export const AutoMissionGeneratorStyled = styled.div`
  height: calc(100% - 72px);
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  position: relative;
`;

export const AutoMissionGeneratorContentStyled = styled.div`
  height: 100%;
  width: 594px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid #e9e9e9;
  box-sizing: border-box;
  padding: 24px 0;
`;

export const AutoMissionGeneratorBackgroundStyled = styled.div`
  background: rgba(37, 63, 115, 0.6);
  flex: 1;
`;

export const HeaderStyled = styled.div`
  font-size: 32px;
  font-weight: 400;
  color: #172a3f;
  box-sizing: border-box;
  padding: 12px 32px 24px 32px;
  border-bottom: 1px solid #f4f4f4;
`;

export const FooterStyled = styled.div`
  box-sizing: border-box;
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 400;
`;

const ButtonStyled = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  height: 56px;
  width: 260px;
  cursor: pointer;
  opacity: 1;
  transition: all 0.3s;

  &:hover {
    opacity: 0.8;
  }
`;

export const CancelButtonStyled = styled(ButtonStyled)`
  border: 2px solid #d1d1d1;
  color: #1b1b1b;
`;

interface SuccessButton {
  isDisabled: boolean;
}

export const SuccessButtonStyled = styled(ButtonStyled)<SuccessButton>`
  border: 2px solid #253f73;
  background: #253f73;
  color: #fff;
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
  cursor: ${(props) => (props.isDisabled ? "default" : "pointer")};

  &:hover {
    opacity: ${(props) => (props.isDisabled ? 0.5 : 0.8)};
  }
`;

export const PickerStyled = styled.div`
  color: #1b1b1b;
  font-size: 20px;
  font-weight: 300;
  box-sizing: border-box;
  padding: 0;

  > span {
    margin-bottom: 8px;
    display: flex;
  }
`;

export const MessageStyled = styled.div`
  font-weight: 300;
  font-size: 14px;
  color: #1b1b1b;
  box-sizing: border-box;
  padding: 16px 48px 16px 0;
  border-bottom: 1px solid #f4f4f4;
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  svg {
    margin-right: 16px;
  }

  span {
    font-weight: 700;
    margin-right: 8px;
  }
`;

export const PageContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
