import React, { memo } from "react";
import {
  ButtonStyled,
  CounterBodyStyled,
  CounterValueStyled,
  LabelStyled,
} from "./styles";
import { ReactComponent as PolygonIcon } from "../../../common/assets/polygonIcon.svg";
import { TabProps } from "../../types";

export const TabButton = memo(
  ({ name, isActive, counter, Icon, handleClick }: TabProps) => (
    <ButtonStyled onClick={() => handleClick(name)} active={isActive}>
      {Icon && <Icon />}
      <LabelStyled active={isActive}>{name}</LabelStyled>
      <CounterBodyStyled active={isActive}>
        <PolygonIcon />
        <CounterValueStyled active={isActive}>{counter}</CounterValueStyled>
      </CounterBodyStyled>
    </ButtonStyled>
  )
);
