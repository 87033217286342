import styled from "styled-components";

export const ModalStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1002;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ModalBackgroundStyled = styled.div<{ isTransparent?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ isTransparent }) =>
    isTransparent ? "none" : "#253F7366"};
  box-sizing: border-box;
`;

export const ModalContentStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  height: 100%;
  width: 594px;
  background: #ffffff;
  border-right: 1px solid #e9e9e9;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 24px 0;
`;

export const HeaderStyled = styled.div`
  font-size: 32px;
  font-weight: 400;
  color: #172a3f;
  box-sizing: border-box;
  padding: 12px 32px 24px 32px;
  border-bottom: 1px solid #f4f4f4;
`;

export const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 24px;
  flex: 1;

  input {
    height: 40px;
  }
`;

export const FooterStyled = styled.div`
  box-sizing: border-box;
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 400;
`;

const ButtonStyled = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  height: 56px;
  width: 260px;
  cursor: pointer;
  opacity: 1;
  transition: all 0.3s;

  &:hover {
    opacity: 0.8;
  }
`;

export const CancelButtonStyled = styled(ButtonStyled)`
  border: 2px solid #d1d1d1;
  color: #1b1b1b;
`;

interface SuccessButton {
  isDisabled: boolean;
}

export const SuccessButtonStyled = styled(ButtonStyled)<SuccessButton>`
  border: 2px solid #253f73;
  background: #253f73;
  color: #fff;
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
  cursor: ${(props) => (props.isDisabled ? "default" : "pointer")};
  transition: all 0.3s;

  &:hover {
    opacity: ${(props) => (props.isDisabled ? 0.5 : 0.8)};
  }
`;
