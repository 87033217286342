import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import API, { showNetworkErrorToast } from "Utils/api";

import { RowData } from "Components/NewList/types";
import { reorderQueueDeploymentsSection } from ".";

export const changeQueueDeploymentsSectionOrder = createAsyncThunk<
  void,
  {
    deploymentId: string;
    order: number;
    cancelAction: () => RowData[];
    newDeploymentsOrder: RowData[];
  }
>(
  "changeQueueDeploymentsSectionOrder",
  async (
    data: {
      deploymentId: string;
      order: number;
      cancelAction: () => RowData[];
      newDeploymentsOrder: RowData[];
    },
    thunkAPI
  ) => {
    thunkAPI.dispatch(
      reorderQueueDeploymentsSection(
        data.newDeploymentsOrder.map((item) => item.id)
      )
    );
    try {
      await API.patch(`mission_deployment/${data.deploymentId}/`, {
        order: data.order,
      });
      toast.success("Mission deployment reordered successfully");
    } catch (error) {
      const oldItems = data.cancelAction();
      thunkAPI.dispatch(
        reorderQueueDeploymentsSection(oldItems.map((item) => item.id))
      );

      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);
