import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import API, { showNetworkErrorToast } from "Utils/api";
import { RootState } from "../..";
import { fetchPatchUser } from "../users";
import { getCurrentUserId, getIsCurrentUserLoaded } from "./selectors";
import {
  setPhoneNumberUpdating,
  setPhoneNumber,
  setImageLoading,
  setCurrentUserImage,
} from ".";
import { UserValidator } from "Slices/validationTypes";

type ErrorWithCode = {
  response: {
    data: {
      errorCode: string;
    };
  };
};

type UserImageError = BaseError & ErrorWithCode;

const currentUserImageErrorCodes: Record<string, string> = {
  EA002: "Maximum file size is 8 MB",
  EA003: "File content is invalid",
  EA006: "File has invalid format (please use png or jpeg)",
  EA007: "File has invalid size (min 400x400 and max 7680x4320)",
  EFF002: "File is invalid",
  EFF004: "File is empty",
  EFF005: "File name is too long",
  E000: "Something went wrong, please try again later",
};

export const patchCurrentUserCompany = createAsyncThunk(
  "currentUser/patchCurrentUserCompany",
  async (companyId: string, thunkAPI) => {
    const localUserId = getCurrentUserId(thunkAPI.getState() as RootState);

    if (!localUserId) return;

    try {
      await thunkAPI.dispatch(
        fetchPatchUser({
          id: localUserId,
          data: { companyId },
          successMessage: "User company has been changed",
        })
      );
    } catch (error) {
      showNetworkErrorToast(error);
    }
  }
);

export const patchPhoneNumber = createAsyncThunk(
  "currentUser/patchPhoneNumber",
  async (phoneNumber: string | null, thunkAPI) => {
    const localUserId = getCurrentUserId(thunkAPI.getState() as RootState);
    try {
      thunkAPI.dispatch(setPhoneNumberUpdating(true));
      const resp = await API.patch(`users/${localUserId}/`, {
        phoneNumber,
      });
      thunkAPI.dispatch(setPhoneNumber(resp.data.phoneNumber));
    } catch (error) {
      showNetworkErrorToast(error);
    }
    thunkAPI.dispatch(setPhoneNumberUpdating(false));
  }
);

export const fetchGetCurrentUser = createAsyncThunk(
  "currentUser/fetchGetCurrentUser",
  async (_, thunkAPI) => {
    const isCurrentUserLoaded = getIsCurrentUserLoaded(
      thunkAPI.getState() as RootState
    );
    if (isCurrentUserLoaded) return;

    try {
      const response = await API.get("users/me/");

      return UserValidator.parse(response.data);
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

const saveUserImage = createAsyncThunk(
  "currentUser/saveUserImage",
  async (imageId: string, thunkAPI) => {
    try {
      const localUserId = getCurrentUserId(thunkAPI.getState() as RootState);
      await API.patch(`users/${localUserId}/`, {
        image: {
          id: imageId,
        },
      });
    } catch (error) {
      showNetworkErrorToast(error);
    }
  }
);

export const postCurrentUserImage = createAsyncThunk(
  "currentUser/postCurrentUserImage",
  async (file: File, thunkAPI) => {
    thunkAPI.dispatch(setImageLoading(true));
    try {
      const { data } = await API.postForm("assets/", {
        file: file,
        target: "avatar",
      });
      thunkAPI.dispatch(setCurrentUserImage(data.link));
      await thunkAPI.dispatch(saveUserImage(data.id));
    } catch (error) {
      const userImageError = error as UserImageError;
      const errorCode = userImageError?.response?.data?.errorCode;
      const errorDetail = userImageError?.response?.data?.detail;

      const errorMessage =
        currentUserImageErrorCodes[errorCode] ||
        errorDetail ||
        currentUserImageErrorCodes.E000;
      toast.error(errorMessage);
    }
    thunkAPI.dispatch(setImageLoading(false));
  }
);
