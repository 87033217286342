import React, { memo, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { HeaderControlsStyled, HeaderStyled, TitleStyled } from "./styles";
import { AddMissionsDropdown } from "../../../common/AddMissionsDropdown/AddMissionsDropdown";
import { MissionListSearch } from "Components/LiveQueueDeployments/components/MissionListSearch/MissionListSearch";
import {
  LIVE_MISSIONS_PATH,
  QUEUE_MISSIONS_PATH,
} from "../../../../routers/AuthorizedRouter";
import { getLiveQueueDeploymentsPageFleetId } from "Slices/liveQueueDeploymentsPage";
import { useSelector } from "react-redux";

export const Header = memo(() => {
  const { pathname } = useLocation();

  const title = useMemo(() => {
    if (pathname === LIVE_MISSIONS_PATH) return "Live Missions";
    else if (pathname === QUEUE_MISSIONS_PATH) return "Queue Manager";
    else return "Missions";
  }, [pathname]);

  const deploymentsPageFleetId = useSelector(
    getLiveQueueDeploymentsPageFleetId
  );

  return (
    <HeaderStyled>
      <TitleStyled>{title}</TitleStyled>
      <HeaderControlsStyled>
        <MissionListSearch />
        <AddMissionsDropdown fleetId={deploymentsPageFleetId} />
      </HeaderControlsStyled>
    </HeaderStyled>
  );
});
