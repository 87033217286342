import React, { ReactNode, FC } from "react";

import { InputStyled } from "./styles";

type InputProps = {
  children?: ReactNode;
  value: string;
  type: string;
  name: string;
  placeholder: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Input: FC<InputProps> = ({
  children,
  value,
  onChange,
  type,
  name,
  placeholder,
}) => (
  <InputStyled
    type={type}
    placeholder={placeholder}
    name={name}
    value={value}
    onChange={onChange}
  >
    {children}
  </InputStyled>
);
