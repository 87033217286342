import styled from "styled-components";

interface SelectStationButtonProps {
  isPickedStation: boolean;
}

export const SelectedStationStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const SelectedStationNameStyled = styled.div`
  font-size: 48px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #172a3f;
`;

export const SelectStationButtonStyled = styled.button<SelectStationButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.isPickedStation ? "239px" : "100%")};
  border: ${(props) => (props.isPickedStation ? "2px solid #3f7f7d" : "none")};
  height: 56px;
  background: #3f7f7d;
  background: ${(props) => (props.isPickedStation ? "#f4f4f4" : "#3f7f7d")};
  font-weight: 400;
  font-size: 20px;
  color: ${(props) => (props.isPickedStation ? "#3f7f7d" : "#ffffff")};
  cursor: pointer;
  gap: 15px;

  &:disabled {
    border: 2px solid #e9e9e9;
    cursor: default;
    background: none;
    color: #a4a4a4;

    svg {
      path {
        fill: #a4a4a4;
      }
    }
  }
`;
