import React, { Dispatch, FC, memo, SetStateAction, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUserId } from "Slices/currentUser";
import {
  closeAnyModal,
  CONFIRM_ACTIVE_ROLE_USER_MODAL,
  toggleModal,
} from "Slices/modals";
import { fetchPatchUser } from "Slices/users";
import { getRolesById } from "Slices/roles";
import { getLocalStorageItem, storageKeys } from "Utils/localStorageUtils";
import {
  DropdownAreaStyled,
  DropdownContentStyled,
  UserListDropdownItemStyled,
  MessageStyled,
  MessageBodyStyled,
} from "./styles";
import { UserInfo } from "./types";

type DropdownFieldProps = {
  roles?: UserRole[];
  pickedRoleId: UserRoleId | null | undefined;
  setPickedRoleId: Dispatch<SetStateAction<UserRoleId | null | undefined>>;
  setShowDropdown: Dispatch<SetStateAction<boolean>>;
  containerRef: React.MutableRefObject<null | HTMLDivElement>;
  userInfo: UserInfo;
};

export const DropdownField: FC<DropdownFieldProps> = memo(
  ({
    pickedRoleId,
    setPickedRoleId,
    setShowDropdown,
    containerRef,
    roles,
    userInfo,
  }) => {
    const dispatch = useDispatch();
    // move out selectors from here
    const currentUserId = useSelector(getCurrentUserId);
    const userRolesById = useSelector(getRolesById);
    const isHideConfirmationModalWindows = !!getLocalStorageItem(
      storageKeys.HIDE_CONFIRMATION_MODAL_WINDOWS
    );
    const boundingOptionsButtonRect =
      containerRef.current?.getBoundingClientRect();
    const availableScreenHeight = window.innerHeight;
    const heightDropdown = 106;
    const isNotVisibleElement =
      availableScreenHeight - (boundingOptionsButtonRect?.bottom ?? 0) <
      heightDropdown;
    const dropdownTopOffset = `${
      (boundingOptionsButtonRect?.top ?? 0) +
      (isNotVisibleElement ? -heightDropdown : 26)
    }px`;
    const dropdownLeftOffset = `${
      (boundingOptionsButtonRect?.left ?? 0) - 82
    }px`;
    const isChangingCurrentUser = currentUserId === userInfo.userId;

    const generateMessage = useCallback(
      (firstName: string, lastName: string, role?: string) => (
        <MessageBodyStyled>
          Are you sure you want to change the role of “
          <MessageStyled>
            {firstName} {lastName}
          </MessageStyled>
          ” to “<MessageStyled>{role}</MessageStyled>”?
        </MessageBodyStyled>
      ),
      []
    );
    const handlePatchUser = useCallback((roleId: UserRoleId) => {
      dispatch(
        fetchPatchUser({
          id: userInfo.userId,
          data: { role: roleId },
          successMessage: "User role changed",
          isCurrentUser: isChangingCurrentUser,
        })
      );
      setPickedRoleId(roleId);
    }, []);

    const handlePatchUserWithConfirmation = useCallback(
      (roleId: UserRoleId) => {
        dispatch(
          toggleModal({
            type: CONFIRM_ACTIVE_ROLE_USER_MODAL,
            data: {
              isModalWithCheckbox: true,
              headerText: "Change Role",
              message: generateMessage(
                userInfo.firstName,
                userInfo.lastName,
                userRolesById?.[roleId]?.name
              ),
              confirmAction: (checkboxStatus: boolean) => {
                localStorage.setItem(
                  storageKeys.HIDE_CONFIRMATION_MODAL_WINDOWS,
                  JSON.stringify(checkboxStatus)
                );
                handlePatchUser(roleId);
                handleCloseAction();
              },
              cancelAction: () => {
                setPickedRoleId(pickedRoleId);
                handleCloseAction();
              },
            },
          })
        );
      },
      []
    );

    const changeUserRoleAction = useCallback((roleId: UserRoleId) => {
      if (isHideConfirmationModalWindows) {
        handlePatchUser(roleId);
      } else {
        handlePatchUserWithConfirmation(roleId);
      }
    }, []);

    const handleCloseAction = () => {
      dispatch(closeAnyModal());
    };

    return (
      <>
        <DropdownAreaStyled
          onClick={() => {
            setShowDropdown(false);
          }}
        />
        <DropdownContentStyled
          top={dropdownTopOffset}
          left={dropdownLeftOffset}
        >
          {roles?.map((role) => (
            <UserListDropdownItemStyled
              isSelected={pickedRoleId === role.id}
              key={role.id}
              onClick={() => {
                changeUserRoleAction(role.id);
                setShowDropdown(false);
              }}
            >
              {role.name}
            </UserListDropdownItemStyled>
          ))}
        </DropdownContentStyled>
      </>
    );
  }
);
