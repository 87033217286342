import { z } from "zod";
import { createAsyncThunk } from "@reduxjs/toolkit";

import API, { showNetworkErrorToast } from "Utils/api";

export const fetchGetStops = createAsyncThunk(
  "stops/fetchGetStopsBySemanticMapId",
  async (semanticMapId: string) => {
    try {
      const response = await API.get(`/stops/?semantic_map=${semanticMapId}`);
      const validatedStops = StopsValidator.parse(response.data.results);

      return { stops: validatedStops, semanticMapId };
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

// validator
const StopsValidator = z.array(
  z.object({
    id: z.string(),
    initialization: z.string(),
    latitude: z.number(),
    longitude: z.number(),
    markerColor: z.string(),
    markerSize: z.string(),
    name: z.string(),
    semanticMap: z.string(),
    shortName: z.string(),
    utmX: z.number(),
    utmY: z.number(),
    utmZone: z.string(),
  })
);
