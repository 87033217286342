import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { z } from "zod";

import API, { showNetworkErrorToast } from "Utils/api";

import {
  setVehiclesPageTypes,
  setVehiclesPageTypeWithNewTypes,
  setVehiclesPageTypeWithUpdatedTypes,
} from "../vehiclesPage";
import { addNew, updatedVehicleType } from ".";

export const fetchGetVehicleTypes = createAsyncThunk(
  "vehicleTypes/fetchGetVehicleTypes",
  async (_, thunkAPI) => {
    try {
      const response = await API.get("/vehicle_types/");

      const validatedVehicleTypes = VehicleTypesValidator.parse(
        response.data.results
      );
      thunkAPI.dispatch(setVehiclesPageTypes(validatedVehicleTypes));

      return validatedVehicleTypes;
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

export const addVehicleTypeImage = async (file: File) => {
  try {
    const { data } = await API.postForm("assets/", {
      file: file,
      target: "default",
    });
    return data.id;
  } catch (error) {
    showNetworkErrorToast(error);
    return Promise.reject();
  }
};

export const fetchPostVehicleTypes = createAsyncThunk<void, VehicleTypePatch>(
  "vehicleTypes/fetchPostVehicleTypes",
  async (data, thunkAPI) => {
    try {
      const response = await API.post("/vehicle_types/", data);
      thunkAPI.dispatch(addNew(response.data));
      thunkAPI.dispatch(setVehiclesPageTypeWithNewTypes(response.data));
      toast.success("Vehicle Type added successfully");
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

export const fetchPutVehicleTypesId = createAsyncThunk<
  void,
  { id: string; data: Partial<VehicleType> }
>(
  "vehicleTypes/fetchPutVehicleTypesId",
  async (vehicleType: { id: string; data: Partial<VehicleType> }, thunkAPI) => {
    const { id, data } = vehicleType;
    try {
      const response = await API.patch(`/vehicle_types/${id}/`, {
        ...data,
      });
      thunkAPI.dispatch(updatedVehicleType(response.data));
      thunkAPI.dispatch(setVehiclesPageTypeWithUpdatedTypes(response.data));
      toast.success(
        `Vehicle type ${data.manufacturer} ${data.model} edited successfully`
      );
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

// validators
const ImageMetaDataValidator = z.object({
  fileSize: z.number(),
  fileName: z.string(),
});

const ImageValidator = z.object({
  id: z.string(),
  link: z.string().optional(),
  meta: ImageMetaDataValidator.optional(),
});

export const VehicleTypesValidator = z.array(
  z.object({
    id: z.string(),
    name: z.string(),
    weightUnit: z.string(),
    details: z.string(),
    manufacturer: z.string(),
    model: z.string(),
    weight: z.string(),
    bodyStyle: z.string(),
    deleted: z.boolean(),
    description: z.string(),
    height: z.string(),
    image: ImageValidator.nullable(),
    length: z.string(),
    maxPassengers: z.number(),
    motorType: z.string(),
    shortName: z.string(),
    url: z.string(),
    width: z.string(),
  })
);
