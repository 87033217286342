import React from "react";

import { HeaderControlsStyled } from "./styles";
import { useSelector } from "react-redux";
import { getMissionPlannerFleetId } from "Slices/allDeploymentsPage";
import { AddMissionsDropdown } from "Components/common/AddMissionsDropdown/AddMissionsDropdown";

export const HeaderControls = () => {
  const missionPlannerFleetId = useSelector(getMissionPlannerFleetId);
  return (
    <HeaderControlsStyled>
      <AddMissionsDropdown fleetId={missionPlannerFleetId} />
    </HeaderControlsStyled>
  );
};
