import React, { FC, memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { List } from "Components/NewList";
import { RowData } from "Components/NewList/types";
import { createReorderHandler, OrderType } from "Utils";
import { toggleModal } from "Slices/modals";
import { getUsersFilteredByCurrentCompany } from "Slices/users";
import { getRolesById } from "Slices/roles";
import { getUserRoleField, getUserStatusField } from "./utils";

import { ReactComponent as MoreIcon } from "Components/common/assets/moreIcon.svg";

const useUserListReordering = (
  preparedUsersData: UserListRowData[],
  setPreparedUsersData: React.Dispatch<React.SetStateAction<UserListRowData[]>>
) => {
  const [firstNameOrder, setFirstNameOrder] = useState<OrderType>(1);
  const [lastNameOrder, setLastNameOrder] = useState<OrderType>(1);
  const [roleOrder, setRoleOrder] = useState<OrderType>(1);
  const [statusOrder, setStatusOrder] = useState<OrderType>(1);

  const reorderOptions = useMemo(
    () => ({
      firstName: createReorderHandler(
        "firstName",
        preparedUsersData,
        setPreparedUsersData,
        firstNameOrder,
        setFirstNameOrder
      ),
      lastName: createReorderHandler(
        "lastName",
        preparedUsersData,
        setPreparedUsersData,
        lastNameOrder,
        setLastNameOrder
      ),
      role: createReorderHandler(
        "rawRole",
        preparedUsersData,
        setPreparedUsersData,
        roleOrder,
        setRoleOrder
      ),
      status: createReorderHandler(
        "rawStatus",
        preparedUsersData,
        setPreparedUsersData,
        statusOrder,
        setStatusOrder
      ),
    }),
    [preparedUsersData]
  );
  return reorderOptions;
};
type UserListRowData = RowData & {
  firstName: string;
  lastName: string;
  role: () => JSX.Element;
  status: JSX.Element;
  rawStatus: string;
  rawRole: string;
};

type UserListProps = {
  searchValue: string;
};

export const UsersList: FC<UserListProps> = memo(({ searchValue }) => {
  const dispatch = useDispatch();
  const users: Array<User> = useSelector(getUsersFilteredByCurrentCompany);
  const rolesById = useSelector(getRolesById);
  const [preparedUsersData, setPreparedUsersData] = useState<UserListRowData[]>(
    []
  );
  const reorderOptions = useUserListReordering(
    preparedUsersData,
    setPreparedUsersData
  );

  const headerData = useMemo(
    () => ({
      firstName: "First Name",
      lastName: "Last Name",
      role: "Role",
      status: "Status",
    }),
    []
  );

  const options = useMemo(
    () => ({
      icon: <MoreIcon />,
      callback: (data: UserListRowData) => {
        dispatch(
          toggleModal({
            type: "USER_DETAILS_MODAL",
            data: { userId: data.id },
          })
        );
      },
    }),
    []
  );

  useEffect(() => {
    if (users.length === 0) return;

    const usersData = users.map((user) => ({
      id: user.id,
      firstName: `${user.firstName}`,
      lastName: `${user.lastName}`,
      role: getUserRoleField({
        roleId: user.role,
        userInfo: {
          userId: user.id,
          firstName: `${user.firstName}`,
          lastName: `${user.lastName}`,
        },
      }),
      status: getUserStatusField(user.status as UserStatus),
      rawStatus: user.status ?? "",
      rawRole: user.role ? rolesById[user.role]?.name ?? "" : "",
    }));

    const filteredData = searchValue
      ? usersData.filter(
          (user) =>
            (user.lastName !== null &&
              user.lastName
                .toLowerCase()
                .includes(searchValue.toLowerCase())) ||
            (user.firstName !== null &&
              user.firstName.toLowerCase().includes(searchValue.toLowerCase()))
        )
      : usersData;
    setPreparedUsersData(filteredData);
  }, [users, searchValue, rolesById]);

  return (
    <List
      data={preparedUsersData}
      headerData={headerData}
      options={options}
      reorderOptions={reorderOptions}
    />
  );
});
