import {
  getVehiclesPageVehicles,
  getFleetIdsRelatesToVehiclePage,
} from "./selectors";
import VehiclesPageVehiclesSlice from "./reducers";
import { prepareVehiclesData } from "./utils";

//utils
export { prepareVehiclesData };

// actions
export const {
  setVehiclesPageDeployments,
  setVehiclesPageVehicles,
  setVehiclesPageFleets,
  setVehiclesPageTypeWithNewTypes,
  setVehiclesPageTypeWithUpdatedTypes,
  setVehiclesPageTypes,
  updateVehiclesPageDeployments,
  updateVehicleDriveModeStatus,
  setInitialDriveModeStatuses,
  setVehiclesPageSorting,
  resetVehiclesPageData,
} = VehiclesPageVehiclesSlice.actions;

// selectors
export { getVehiclesPageVehicles, getFleetIdsRelatesToVehiclePage };

// reducer
export default VehiclesPageVehiclesSlice.reducer;
