import React, { useRef, useState } from "react";
import { TableFieldBodyStyled } from "../NewList/styles";

import { userStatusesMap } from "./constants";
import { DropdownField } from "./DropdownField";
import { StatusNameStyled, UserRoleContainerStyled } from "./styles";
import { ReactComponent as ArrowIcon } from "./assets/arrowIcon.svg";
import { UserInfo } from "./types";
import { getAvailableRoles, getRolesById } from "Slices/roles/selectors";
import { useSelector } from "react-redux";

export const getUserStatusField = (userStatusCode: UserStatus) => {
  const { icon, userStatus } = userStatusesMap[userStatusCode];

  return (
    <TableFieldBodyStyled>
      {icon}
      <StatusNameStyled>{userStatus}</StatusNameStyled>
    </TableFieldBodyStyled>
  );
};

type UserInfoFieldRole = {
  roleId: UserRoleId | null | undefined;
  userInfo: UserInfo;
};

export const getUserRoleField =
  ({ roleId, userInfo }: UserInfoFieldRole) =>
  () => {
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const [pickedRoleId, setPickedRoleId] = useState<
      UserRoleId | null | undefined
    >(roleId);
    const dropdownContainerRef = useRef(null);

    const availableRoles = useSelector(getAvailableRoles);
    const userRolesById = useSelector(getRolesById);
    const userRole = pickedRoleId
      ? userRolesById?.[pickedRoleId]?.name || "No Data"
      : "No Data";

    return (
      <>
        <TableFieldBodyStyled
          ref={dropdownContainerRef}
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <UserRoleContainerStyled>
            <StatusNameStyled>{String(userRole)}</StatusNameStyled>
            <ArrowIcon />
          </UserRoleContainerStyled>
        </TableFieldBodyStyled>
        {showDropdown && (
          <DropdownField
            userInfo={userInfo}
            roles={availableRoles}
            pickedRoleId={pickedRoleId}
            setShowDropdown={setShowDropdown}
            setPickedRoleId={setPickedRoleId}
            containerRef={dropdownContainerRef}
          />
        )}
      </>
    );
  };
