import styled from "styled-components";

export const VehicleTooltipStyled = styled.div`
  font-family: "Readex Pro", sans-serif;
  font-size: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0 6px 12px rgba(73, 73, 73, 0.15);
  width: 320px;
  height: 120px;
`;

export const VehicleTooltipInfoStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  box-sizing: border-box;
  padding: 12px;
`;

export const VehicleTooltipTopInfoStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const VehicleTooltipLineInfoStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;
`;

export const VehicleTooltipNameStyled = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #1b1b1b;
`;

export const VehicleTooltipLastUpdateStyled = styled.div`
  display: flex;
  align-items: center;
  font-weight: 300;
  line-height: 120%;
  color: #767676;

  span {
    margin-left: 4px;
    color: #1b1b1b;
  }
`;

export const VehicleTooltipProgressStyled = styled.div`
  font-weight: 500;
  line-height: 120%;
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`;

export const VehicleTooltipDriverStyled = styled.div`
  position: relative;
  background: #d9d9d9;
  height: 20px;
  box-sizing: border-box;
  padding: 0 8px;
  display: flex;
  align-items: center;
  clip-path: polygon(
    0 0,
    calc(100% - 6px) 0,
    100% calc(0% + 6px),
    100% 100%,
    calc(0% + 6px) 100%,
    0 calc(100% - 6px)
  );

  svg {
    margin-right: 4px;
  }
`;

export const VehicleTooltipOnlineContentStyled = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #1b1b1b;
`;

export const VehicleTooltipLinkContentStyled = styled.div`
  height: 32px;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 12px;
  border-top: 1px solid #e9e9e9;
`;

export const VehicleTooltipLinkStyled = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #3f7f7d;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.7;
  }
`;
