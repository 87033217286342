import React from "react";

import { ReactComponent as ActiveIcon } from "./assets/activeIcon.svg";
import { ReactComponent as DeactivatedIcon } from "./assets/deactivatedIcon.svg";
import { ReactComponent as PendingIcon } from "./assets/pendingIcon.svg";

type UserStatusMeta = {
  icon: JSX.Element;
  userStatus: string;
};

export const userStatusesMap: {
  [key in UserStatus]: UserStatusMeta;
} = {
  active: { icon: <ActiveIcon />, userStatus: "Active" },
  deactivated: { icon: <DeactivatedIcon />, userStatus: "Deactivated" },
  pending: { icon: <PendingIcon />, userStatus: "Pending" },
};
