import React, { memo } from "react";
import { useSelector } from "react-redux";

import {
  getIsVehiclesLoaded,
  getVehiclesByCurrentSemanticMap,
} from "Slices/vehicles";
import { getVehicleTypes } from "Slices/vehicleTypes";
import { getVehiclesUpdateData } from "Slices/vehiclesState";
import { getIsVehiclesLoading } from "Slices/vehicles/selectors";
import { AsideLoader } from "Components/common/Loader/Loader";
import { VehicleItem } from "../VehicleItem";
import { SemanticMapPicker } from "Components/common/SemanticMapPicker/SemanticMapPicker";

import { ReactComponent as EmptyImg } from "Components/common/assets/empty.svg";
import {
  VehiclesListStyled,
  SemanticMapPickerBodyStyled,
  VehiclesListContentStyled,
  VehiclesListEmptyImageStyled,
  VehiclesListEmptyMessageStyled,
} from "./styles";

export const VehiclesList = memo(() => {
  const vehiclesByCurrentSemantic = useSelector(
    getVehiclesByCurrentSemanticMap
  );
  const vehicleTypes = useSelector(getVehicleTypes);
  const vehicleUpdatedData = useSelector(getVehiclesUpdateData);
  const isVehiclesLoading = useSelector(getIsVehiclesLoading);
  const isVehiclesLoaded = useSelector(getIsVehiclesLoaded);

  return (
    <VehiclesListStyled>
      <SemanticMapPickerBodyStyled>
        <SemanticMapPicker />
      </SemanticMapPickerBodyStyled>
      <VehiclesListContentStyled>
        {isVehiclesLoading && <AsideLoader />}
        {isVehiclesLoaded && (
          <>
            {vehiclesByCurrentSemantic.length === 0 && (
              <VehiclesListEmptyImageStyled>
                <EmptyImg />
                <VehiclesListEmptyMessageStyled>
                  No vehicles found for current zone
                </VehiclesListEmptyMessageStyled>
              </VehiclesListEmptyImageStyled>
            )}
            {vehiclesByCurrentSemantic.map((vehicleItem) => {
              return (
                <VehicleItem
                  key={vehicleItem.id}
                  vehicleData={vehicleItem}
                  vehicleStatesData={vehicleUpdatedData}
                  vehicleType={vehicleTypes.find(
                    ({ id }) => id === vehicleItem.vehicleTypeId
                  )}
                />
              );
            })}
          </>
        )}
      </VehiclesListContentStyled>
    </VehiclesListStyled>
  );
});
