import React, { memo } from "react";
import ReactDOM from "react-dom";

import { OptionsDropdownProps, RowData } from "./types";

import { ModalBackgroundStyled } from "../ModalComponent/styles";
import { DropdownElementStyled, DropdownStyled } from "./styles";

type OptionsDropdownComponent = <T extends RowData>(
  props: OptionsDropdownProps<T>
) => JSX.Element | null;

export const OptionsDropdown: OptionsDropdownComponent = memo(
  ({
    toggleDropdown,
    optionsButtonRef,
    options,
    item,
    optionsDropdownOffset = { top: 0, right: 0 },
    parentElementId,
  }) => {
    const boundingOptionsButtonRect =
      optionsButtonRef.current?.getBoundingClientRect();
    const dropdownTopOffset = boundingOptionsButtonRect?.top;
    const dropdownRightOffset =
      document.body.clientWidth - (boundingOptionsButtonRect?.right || 0);
    const handleClick = (callback: (param: typeof item) => void) => {
      callback(item);
      toggleDropdown();
    };

    return ReactDOM.createPortal(
      Array.isArray(options) && (
        <>
          <ModalBackgroundStyled
            onClick={toggleDropdown}
            isTransparent={true}
          />
          <DropdownStyled
            topOffset={dropdownTopOffset}
            rightOffset={dropdownRightOffset}
            optionsDropdownOffset={optionsDropdownOffset}
          >
            {options
              .filter(({ getIsHidden }) => !getIsHidden?.(item))
              .map(({ name, callback, getIsDisabled }) => {
                const isDisabled = getIsDisabled?.(item);
                return (
                  <DropdownElementStyled
                    key={name}
                    isDisabled={isDisabled}
                    onClick={() =>
                      isDisabled ? void 0 : handleClick(callback)
                    }
                  >
                    {name}
                  </DropdownElementStyled>
                );
              })}
          </DropdownStyled>
        </>
      ),
      document.getElementById(parentElementId) as Element
    );
  }
);
