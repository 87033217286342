import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { closeWarning, getWarning } from "Slices/warning";
import { AbstractWarning } from "Components/Warning/AbstractWarning";
import { storageKeys } from "Utils/localStorageUtils";

import {
  ActionButtonStyled,
  WarningButtonsContainerStyled,
  LogoutButtonStyled,
  ReloadPageButtonStyled,
} from "./styles";

export const Warning = memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { message, type } = useSelector(getWarning);

  if (!message) return <></>;

  const actionHandlers = {
    primary: (
      <ActionButtonStyled
        onClick={() => {
          dispatch(closeWarning());
        }}
      >
        I acknowledge
      </ActionButtonStyled>
    ),
    error: (
      <WarningButtonsContainerStyled>
        <LogoutButtonStyled
          onClick={() => {
            window.localStorage.removeItem("JWT");
            window.localStorage.removeItem("JWTrefresh");
            navigate("/login");
          }}
        >
          Logout
        </LogoutButtonStyled>
        <ReloadPageButtonStyled
          onClick={() => {
            // try to prevent stuck in some 'bad' semantic map with wrong data
            localStorage.removeItem(storageKeys.CURRENT_SEMANTIC_MAP_ID);
            location.reload();
          }}
        >
          Reload page
        </ReloadPageButtonStyled>
      </WarningButtonsContainerStyled>
    ),
  };

  return (
    <AbstractWarning message={message} actionHandler={actionHandlers[type]} />
  );
});
