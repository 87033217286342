import React, { memo, useMemo } from "react";

import { useSelector } from "react-redux";
import { getCurrentSemanticMapId } from "Slices/appState";
import { getVehicleTypes } from "Slices/vehicleTypes";
import { getVehicles } from "Slices/vehicles";
import {
  getIntersectionEntries,
  getIsIntersectionsLoaded,
  getIsIntersectionsLoadingError,
  getSematicIntersectionMapping,
} from "Slices/intersections";
import { AsideLoader } from "Components/common/Loader/index";

import { IntersectionItem } from "./IntersectionItem";
import {
  IntersectionListBodyStyled,
  IntersectionItemWrapperStyled,
} from "./styles";
import { buildIntersectionNameAndPermissionsMap } from "../utils";

export const IntersectionEntities = memo(() => {
  const currentSemanticMapId = useSelector(getCurrentSemanticMapId);
  const semanticIntersectionMapping = useSelector(
    getSematicIntersectionMapping
  );
  const intersectionData = useSelector(getIntersectionEntries);
  const isIntersectionsLoaded = useSelector(getIsIntersectionsLoaded);
  const hasIntersectionsLoadingError = useSelector(
    getIsIntersectionsLoadingError
  );
  const vehicleTypes: VehicleType[] = useSelector(getVehicleTypes);
  const vehicles: Array<Vehicle> = useSelector(getVehicles);

  const details = useMemo(() => {
    if (
      currentSemanticMapId &&
      semanticIntersectionMapping?.[currentSemanticMapId]
    ) {
      return buildIntersectionNameAndPermissionsMap(
        semanticIntersectionMapping[currentSemanticMapId],
        intersectionData,
        vehicles,
        vehicleTypes
      );
    }
    return [];
  }, [
    intersectionData,
    currentSemanticMapId,
    semanticIntersectionMapping,
    vehicles,
    vehicleTypes,
  ]);

  return (
    <IntersectionListBodyStyled data-testid="intersection-list-entities">
      {hasIntersectionsLoadingError ? (
        <IntersectionItemWrapperStyled standAlone={true}>
          Something went wrong. Please try it again.
        </IntersectionItemWrapperStyled>
      ) : !isIntersectionsLoaded ? (
        <AsideLoader />
      ) : currentSemanticMapId &&
        semanticIntersectionMapping &&
        semanticIntersectionMapping[currentSemanticMapId] === undefined ? (
        <IntersectionItemWrapperStyled standAlone={true}>
          No intersections configured
        </IntersectionItemWrapperStyled>
      ) : (
        details.map((intersection) => {
          const { id } = intersection;
          return <IntersectionItem data={intersection} key={id} />;
        })
      )}
    </IntersectionListBodyStyled>
  );
});
