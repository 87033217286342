import React from "react";

import {
  MissionNameCellStyled,
  VehicleFieldBodyStyled,
  VehicleNameStyled,
  VehicleTypeImgStyled,
} from "Components/LiveDeploymentsTable/styles";
import { EMPTY_ICON } from "Utils";

export const parseTime = (inputDateString: string) => {
  const parsedDate = new Date(inputDateString);

  // Format the date and time in the desired output format
  return new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }).format(parsedDate);
};

export const getDeploymentNameField = (
  missionName: string,
  missionId: string,
  handleClick: (missionId: string) => void
) => {
  return (
    <MissionNameCellStyled onClick={() => handleClick(missionId)}>
      {missionName}
    </MissionNameCellStyled>
  );
};

export const getAssignedToField = (
  vehicleId: string,
  vehicles: Vehicle[],
  vehicleTypes: VehicleType[]
) => {
  const vehicle = vehicles.find((vehicle) => vehicle?.id === vehicleId);
  const vehicleType = vehicleTypes.find(
    (vehicleType) => vehicleType.id === vehicle?.vehicleTypeId
  );
  const vehicleName = vehicle?.name ?? "NONE";

  return (
    <VehicleFieldBodyStyled>
      <VehicleTypeImgStyled src={vehicleType?.image?.link || EMPTY_ICON} />
      <VehicleNameStyled title={vehicleName}>{vehicleName}</VehicleNameStyled>
    </VehicleFieldBodyStyled>
  );
};
