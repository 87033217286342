import React, { ReactNode, FC } from "react";

import { TitleStyled } from "./styles";

type TitleProps = {
  children?: ReactNode;
};

export const Title: FC<TitleProps> = ({ children }) => (
  <TitleStyled>{children}</TitleStyled>
);
