import React, { memo } from "react";
import { useSelector } from "react-redux";

import { AllDeploymentsLoaderSpinner } from "Components/common/Loader/Loader";
import { getIsAllDeploymentsUpdating } from "Slices/allDeploymentsPage";

import {
  AllDeploymentsContainerStyled,
  AllDeploymentsListContentStyled,
} from "./styles";
import { Header } from "./components/Header";
import { AllDeploymentsList } from "./components/AllDeploymentsList/AllDeploymentsList";
import { AllDeploymentsListControls } from "./components/AllDeploymentsListControls/AllDeploymentsListControls";
import { AsideFilters } from "./components/AsideFilters/AsideFilters";

export const AllDeployments = memo(() => {
  const isAllDeploymentsUpdating = useSelector(getIsAllDeploymentsUpdating);

  return (
    <AllDeploymentsContainerStyled>
      <AsideFilters />
      <AllDeploymentsListContentStyled>
        {isAllDeploymentsUpdating && <AllDeploymentsLoaderSpinner />}
        <Header />
        <AllDeploymentsListControls />
        <AllDeploymentsList />
      </AllDeploymentsListContentStyled>
    </AllDeploymentsContainerStyled>
  );
});
