import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useState,
} from "react";

type SearchContextType = {
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>> | null;
};

export const MissionsPageSearchContext = createContext<SearchContextType>({
  searchValue: "",
  setSearchValue: null,
});

type SearchContextHelperProps = {
  children: ReactNode;
};

export const SearchContextHelper: FC<SearchContextHelperProps> = ({
  children,
}) => {
  const [searchValue, setSearchValue] = useState<string>("");

  return (
    <MissionsPageSearchContext.Provider value={{ searchValue, setSearchValue }}>
      {children}
    </MissionsPageSearchContext.Provider>
  );
};
