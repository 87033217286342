import ModalsSlice from "./reducers";
import {
  getIsUserDetailsModal,
  getIsConfirmActiveStatusUserModal,
  getIsConfirmActiveRoleUserModal,
  getIsConfirmChangeVehicleModal,
  getIsInvitationSentModal,
  getIsInviteUserModal,
  getIsShowVehicleModal,
  getIsShowVehicleTypeModal,
  getIsShowFleetModal,
  getIsShowDeploymentDetailsModal,
  getIsShowGamepadSettingModal,
  getIsShowUserModal,
  getIsShowVersionModal,
  getIsShowChangeCompanyModal,
  getIsShowAddEditMissionModal,
  getIsShowContactSupportModal,
  getIsShowDeployedMissionListModal,
  getIsModalOpen,
  getModalData,
} from "./selectors";

import {
  ADD_EDIT_MISSION_MODAL,
  CHANGE_COMPANY_MODAL,
  CONFIRM_ACTIVE_ROLE_USER_MODAL,
  CONFIRM_ACTIVE_STATUS_USER_MODAL,
  CONFIRM_CHANGE_VECHICLE_MODAL,
  CONTACT_SUPPORT_MODAL,
  DEPLOYED_MISSION_LIST_MODAL,
  FLEET_MODAL,
  GAMEPAD_SETTINGS_MODAL,
  INVITATION_SENT_MODAL,
  INVITE_USER_MODAL,
  USER_DETAILS_MODAL,
  USER_MODAL,
  DEPLOYMENT_DETAILS_MODAL,
  VEHICLE_MODAL,
  VEHICLE_TYPE_MODAL,
  VERSION_MODAL,
} from "./constants";

//constants
export {
  ADD_EDIT_MISSION_MODAL,
  CHANGE_COMPANY_MODAL,
  CONFIRM_ACTIVE_ROLE_USER_MODAL,
  CONFIRM_ACTIVE_STATUS_USER_MODAL,
  CONFIRM_CHANGE_VECHICLE_MODAL,
  CONTACT_SUPPORT_MODAL,
  DEPLOYED_MISSION_LIST_MODAL,
  DEPLOYMENT_DETAILS_MODAL,
  FLEET_MODAL,
  GAMEPAD_SETTINGS_MODAL,
  INVITATION_SENT_MODAL,
  INVITE_USER_MODAL,
  USER_DETAILS_MODAL,
  USER_MODAL,
  VEHICLE_MODAL,
  VEHICLE_TYPE_MODAL,
  VERSION_MODAL,
};

// actions
export const { toggleModal, closeAnyModal } = ModalsSlice.actions;

// selectors
export {
  getIsUserDetailsModal,
  getIsConfirmActiveStatusUserModal,
  getIsConfirmActiveRoleUserModal,
  getIsConfirmChangeVehicleModal,
  getIsInvitationSentModal,
  getIsInviteUserModal,
  getIsShowVehicleModal,
  getIsShowVehicleTypeModal,
  getIsShowFleetModal,
  getIsShowGamepadSettingModal,
  getIsShowUserModal,
  getIsShowVersionModal,
  getIsShowDeploymentDetailsModal,
  getIsShowChangeCompanyModal,
  getIsShowAddEditMissionModal,
  getIsShowContactSupportModal,
  getIsShowDeployedMissionListModal,
  getIsModalOpen,
  getModalData,
};

// reducer
export default ModalsSlice.reducer;
