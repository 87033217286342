import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getServerErrorMessage } from "Utils";
import { ChildrenProps } from "Utils/types";
import { getCurrentSemanticMapId, setShowGlobalLoader } from "Slices/appState";
import { fetchStation } from "Slices/operatorStation";
import { fetchOperatorMissionPlans } from "Slices/operatorMissionPlans";
import { fetchGetFleets, getIsFleetsLoaded } from "Slices/fleets";
import { getIsFleetsLoadingError } from "Slices/fleets/selectors";
import { setErrorWarning } from "Slices/warning";
import {
  fetchGetVehicles,
  getIsVehiclesLoaded,
  getIsVehiclesLoadingError,
} from "Slices/vehicles";
import { useStopsLoader } from "Utils/loaders";

const useHandleDataLoadingErrors = () => {
  const dispatch = useDispatch();
  const isFleetsLoadingError = useSelector(getIsFleetsLoadingError);
  const isVehiclesLoadingError = useSelector(getIsVehiclesLoadingError);

  useEffect(() => {
    if (isFleetsLoadingError || isVehiclesLoadingError) {
      const problemData = `
       ${isFleetsLoadingError ? "FLEETS" : ""}
       ${isVehiclesLoadingError ? "VEHICLES" : ""}
      `.trim();

      dispatch(setShowGlobalLoader(false));
      dispatch(setErrorWarning(getServerErrorMessage(problemData)));
    }
  }, [isFleetsLoadingError, isVehiclesLoadingError]);
};

export const MissionOperatorLoader = ({ children }: ChildrenProps) => {
  const dispatch = useDispatch();
  const semanticMapId = useSelector(getCurrentSemanticMapId);
  const isFleetsLoaded = useSelector(getIsFleetsLoaded);
  const currentSemanticMapId = useSelector(getCurrentSemanticMapId);
  const isVehiclesLoaded = useSelector(getIsVehiclesLoaded);

  useHandleDataLoadingErrors();

  const { isStopsLoaded, isStopsLoadingError } =
    useStopsLoader(currentSemanticMapId);

  useEffect(() => {
    if (!isStopsLoadingError) return;

    dispatch(setShowGlobalLoader(false));
    dispatch(setErrorWarning(getServerErrorMessage("STOPS")));
  }, [isStopsLoadingError]);

  // stops loading
  useEffect(() => {
    if (isStopsLoaded) return;
    dispatch(setShowGlobalLoader(true));
  }, [isStopsLoaded]);

  // GET /user/stations?semantic_map
  useEffect(() => {
    if (!semanticMapId) return;

    dispatch(fetchStation());
  }, [semanticMapId]);

  // GET /operatorMissionPlans
  useEffect(() => {
    if (!semanticMapId) return;

    dispatch(fetchOperatorMissionPlans());
  }, [semanticMapId]);

  // GET /vehicles
  useEffect(() => {
    if (isVehiclesLoaded) return;
    dispatch(setShowGlobalLoader(true));
    dispatch(fetchGetVehicles());
  }, [isVehiclesLoaded]);

  // GET /fleets
  useEffect(() => {
    if (isFleetsLoaded) return;
    dispatch(setShowGlobalLoader(true));
    dispatch(fetchGetFleets());
  }, [isFleetsLoaded]);

  // turn the loader off
  useEffect(() => {
    if (isFleetsLoaded && isStopsLoaded && isVehiclesLoaded) {
      dispatch(setShowGlobalLoader(false));
    }
  }, [isFleetsLoaded, isStopsLoaded, isVehiclesLoaded]);

  return <>{children}</>;
};
