import { createSlice } from "@reduxjs/toolkit";

export type AppState = {
  menuIsOpen: boolean;
  loginErrorText: string;
  currentSemanticMapId: string | null;
  expirationDate: number | null;
  backUrl: string | null;
  showGlobalLoader: boolean;
};

const initialState: AppState = {
  menuIsOpen: false,
  loginErrorText: "",
  currentSemanticMapId: null,
  expirationDate: null,
  backUrl: null,
  showGlobalLoader: false,
};

export const Slice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    resetAppState: () => initialState,
    setShowGlobalLoader: (state, action) => {
      state.showGlobalLoader = action.payload;
    },
    setBackUrl: (state, action) => {
      state.backUrl = action.payload;
    },
    setExpirationDate: (state, action) => {
      state.expirationDate = action.payload;
    },
    setCurrentSemanticMapId: (state, action) => {
      state.currentSemanticMapId = action.payload;
    },
    setLoginErrorText: (state, action) => {
      state.loginErrorText = action.payload;
      return state;
    },
  },
});

export default Slice;
