import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { FMSLogger } from "FMSLogger";
import { LIVE_DEPLOYMENTS_ACTIONS } from "Utils/constants";
import { cancelLiveQueueDeployment } from "Slices/liveQueueDeploymentsPage";
import { getVehicles } from "Slices/vehicles";
import { getLiveDeployments } from "Slices/liveQueueDeploymentsPage/selectors";
import { List } from "Components/NewList";
import { getDeploymentStatusField } from "Components/AllDeployments/components/AllDeploymentsList/utils";
import {
  getLoopsField,
  getActionsField,
} from "Components/common/TableElements";
import { MissionsPageSearchContext } from "Components/LiveQueueDeployments/SearchContextHelper";
import {
  getAssignedToField,
  getDeploymentNameField,
  parseTime,
} from "Components/LiveDeploymentsTable/utils";
import { getVehicleTypes } from "Slices/vehicleTypes";
import { toggleModal } from "Slices/modals";
import { OverrideDeploymentWarning } from "./OverrideDeploymentWarning";
import { getVehiclesSyncStateById } from "Slices/vehiclesSyncState";
import { MissionPlanner } from "Components/common/MissionPlanner/MissionPlanner";
import { getCurrentSemanticMap } from "Slices/semanticMaps";

import { LiveDeploymentsWrapperStyled } from "./styles";

const liveDeploymentsActionsLogger = FMSLogger.byPrefix(
  LIVE_DEPLOYMENTS_ACTIONS
);

const UNCANCELABLE_CODES = [
  "CANCELED",
  "FINISHED",
  "CANCEL_PENDING",
  "CANCEL_SENT",
  "FAILURE",
];

type LiveDeploymentRowData = {
  id: string;
  name: JSX.Element;
  rawName: string;
  assignedTo: JSX.Element;
  status: JSX.Element;
  actions: JSX.Element;
  loops: JSX.Element;
  vehicleId: string;
  deployedAt: string;
  startedExecutionAt: string;
  deploymentStatusCode: MissionStatusCode;
};

type LiveDeployment = Deployment & {
  vehicleDeployment: VehicleDeployment;
  vehicleId: string;
};

export const LiveDeploymentsTable = memo(() => {
  const dispatch = useDispatch();

  const vehicles = useSelector(getVehicles);
  const vehicleTypes = useSelector(getVehicleTypes);
  const liveDeployments = useSelector(
    getLiveDeployments
  ) as unknown as LiveDeployment[];
  const vehiclesSyncStateById = useSelector(getVehiclesSyncStateById);
  const currentSemanticMap = useSelector(getCurrentSemanticMap);

  const { searchValue, setSearchValue } = useContext(MissionsPageSearchContext);

  useEffect(
    () => () => {
      setSearchValue?.("");
    },
    []
  );

  const [
    overrideDeploymentWarningOptions,
    setOverrideDeploymentWarningOptions,
  ] = useState<{
    isShown: boolean;
    deploymentId: string | null;
    vehicleId: string | null;
    vehicleName: string | null;
    vehicleTypeId: string | null;
    fleetId: string | null;
  }>({
    isShown: false,
    deploymentId: null,
    vehicleId: null,
    vehicleName: null,
    vehicleTypeId: null,
    fleetId: null,
  });

  const [missionPlannerData, setMissionPlannerData] =
    useState<MissionPlannerData | null>(null);

  const optionsDropdownOffset = useMemo(() => ({ top: 40, right: 0 }), []);

  const options = useMemo(
    () => [
      {
        name: "Cancel",
        getIsHidden: (item: LiveDeploymentRowData) =>
          UNCANCELABLE_CODES.includes(item.deploymentStatusCode),
        callback: (item: LiveDeploymentRowData) => {
          liveDeploymentsActionsLogger.debug(
            `Pressed cancel live mission with id:${item.id}`
          );
          dispatch(
            cancelLiveQueueDeployment({
              deploymentId: item.id,
              deploymentName: item.rawName,
            })
          );
        },
      },
      {
        name: "Override",
        getIsDisabled: (item: LiveDeploymentRowData) =>
          vehiclesSyncStateById[item.vehicleId],
        callback: (item: LiveDeploymentRowData) => {
          const vehicle = vehicles.find(
            (vehicle) => vehicle?.id === item.vehicleId
          );
          liveDeploymentsActionsLogger.debug(
            `Pressed override live mission with id:${item.id}`
          );
          setOverrideDeploymentWarningOptions({
            isShown: true,
            deploymentId: item.id || null,
            vehicleId: vehicle?.id || null,
            vehicleName: vehicle?.name || null,
            vehicleTypeId: vehicle?.vehicleTypeId || null,
            fleetId: vehicle?.fleetId || null,
          });
        },
      },
    ],
    [vehicles, vehiclesSyncStateById]
  );

  const headerData = useMemo(
    () => ({
      name: "Mission Name",
      assignedTo: "Vehicle Assigned",
      status: "Status",
      actions: "Actions",
      loops: "Cycles",
      deployedAt: "Deployed at",
      startedExecutionAt: "Started Execution at",
    }),
    []
  );

  const handleDeploymentNameClick = useCallback((deploymentId: string) => {
    dispatch(
      toggleModal({
        type: "DEPLOYMENT_DETAILS_MODAL",
        data: { deploymentId },
      })
    );
  }, []);

  const preparedLiveDeploymentData: LiveDeploymentRowData[] = useMemo(() => {
    liveDeploymentsActionsLogger.debug("Recalculate preparedLiveData");
    if (!liveDeployments) return [];
    const preparedLiveDeployments = liveDeployments.map((liveDeployment) => ({
      id: liveDeployment.id,
      deploymentStatusCode: liveDeployment.vehicleDeployment.status,
      name: getDeploymentNameField(
        liveDeployment.name,
        liveDeployment.id,
        handleDeploymentNameClick
      ),
      rawName: liveDeployment.name,
      assignedTo: getAssignedToField(
        liveDeployment?.vehicleId ?? "",
        vehicles,
        vehicleTypes
      ),
      status: getDeploymentStatusField(liveDeployment.vehicleDeployment.status),
      actions: getActionsField(
        liveDeployment.vehicleDeployment.passedActions,
        liveDeployment.vehicleDeployment.totalActions
      ),
      loops: getLoopsField(
        liveDeployment.vehicleDeployment.passedLoops,
        liveDeployment.vehicleDeployment.totalLoops
      ),
      vehicleId: liveDeployment.vehicleId,
      deployedAt: parseTime(liveDeployment.vehicleDeployment.deployedAt),
      startedExecutionAt: parseTime(
        liveDeployment.vehicleDeployment.startedExecutionAt
      ),
    }));

    if (!searchValue) return preparedLiveDeployments;

    return preparedLiveDeployments.filter((liveDeployment) =>
      liveDeployment.rawName?.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [liveDeployments, vehicles, vehicleTypes, searchValue]);

  const handleCloseOverrideDeploymentWarningAction = useCallback(() => {
    setOverrideDeploymentWarningOptions({
      isShown: false,
      deploymentId: null,
      vehicleId: null,
      vehicleName: null,
      vehicleTypeId: null,
      fleetId: null,
    });
  }, []);

  const handleSuccessOverrideDeploymentWarningAction = useCallback(() => {
    liveDeploymentsActionsLogger.debug(
      `handle plan deployment for vehicle with override ${overrideDeploymentWarningOptions.vehicleName}`
    );
    const semanticMap = currentSemanticMap ? currentSemanticMap.id : null;
    const vehicleType =
      vehicleTypes.find(
        (vehicleType) =>
          vehicleType.id === overrideDeploymentWarningOptions.vehicleTypeId
      ) || null;

    if (
      !overrideDeploymentWarningOptions.vehicleId ||
      !overrideDeploymentWarningOptions.vehicleName ||
      !vehicleType ||
      !overrideDeploymentWarningOptions.fleetId
    )
      throw Error(
        `no required data:${JSON.stringify({
          vehicleId: overrideDeploymentWarningOptions.vehicleId,
          vehicleName: overrideDeploymentWarningOptions.vehicleName,
          vehicleType: vehicleType,
          fleetId: overrideDeploymentWarningOptions.fleetId,
        })}`
      );

    setMissionPlannerData({
      vehicleId: overrideDeploymentWarningOptions.vehicleId,
      vehicleName: overrideDeploymentWarningOptions.vehicleName,
      vehicleType: vehicleType,
      semanticMap,
      fleetId: overrideDeploymentWarningOptions.fleetId,
    });

    setOverrideDeploymentWarningOptions({
      isShown: false,
      deploymentId: null,
      vehicleId: null,
      vehicleName: null,
      vehicleTypeId: null,
      fleetId: null,
    });
  }, [overrideDeploymentWarningOptions, currentSemanticMap, vehicleTypes]);

  const setMissionPlannerDataAction = (
    missionPlannerData: MissionPlannerData | null
  ) => setMissionPlannerData(missionPlannerData);

  return (
    <LiveDeploymentsWrapperStyled>
      <List
        data={preparedLiveDeploymentData}
        headerData={headerData}
        options={options}
        optionsDropdownOffset={optionsDropdownOffset}
      />
      {overrideDeploymentWarningOptions.isShown && (
        <OverrideDeploymentWarning
          vehicleName={overrideDeploymentWarningOptions.vehicleName}
          handleCloseAction={handleCloseOverrideDeploymentWarningAction}
          handleSuccessAction={handleSuccessOverrideDeploymentWarningAction}
        />
      )}
      {missionPlannerData && (
        <MissionPlanner
          missionPlannerData={missionPlannerData}
          setMissionPlannerData={setMissionPlannerDataAction}
        />
      )}
    </LiveDeploymentsWrapperStyled>
  );
});
