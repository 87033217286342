import styled from "styled-components";

type MissionItemProps = {
  isOpen: boolean;
};

export const MissionItemStyled = styled.div<MissionItemProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 0 12px 0 12px;
  transition: all 0.3s;
  border-left: ${(props) =>
    props.isOpen ? "12px solid #3abebc" : "12px solid transparent"};
`;

export const MissionItemContentStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 24px 0;
  border-bottom: 1px solid #000000;
`;

export const MissionItemColumnStyled = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
`;

export const MissionItemColumnHeadStyled = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 8px;
  transition: all 0.3s;
`;

export const MissionItemLastDeployStyled = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #687684;
  margin-bottom: 8px;

  strong {
    margin-left: 2px;
  }
`;

export const MissionItemAdditionHeadStyled = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #838383;
  height: 24px;
`;

export const MissionItemAdditionTextStyled = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  height: 24px;
`;

export const MissionItemDetailsStyled = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  margin-bottom: 8px;
  opacity: 1;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.8;
  }
`;

export const MissionItemButtonsStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 12px;
  margin-top: 16px;
`;

type MissionItemButtonProps = {
  isEdit?: boolean;
};

export const MissionItemButtonStyled = styled.div<MissionItemButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 40px;
  border: ${(props) =>
    props.isEdit ? "3px solid #253F73" : "3px solid #A50000"};
  box-sizing: border-box;
  border-radius: 5px;
  font-family: "Red Hat Display", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: ${(props) => (props.isEdit ? "#253F73" : "#A50000")};
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.8;
  }
`;

type MissionItemDeployBtnProps = {
  disabled: boolean;
};

export const MissionItemDeployBtnStyled = styled.div<MissionItemDeployBtnProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 120px;
  min-width: 135px;
  height: 40px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  margin: 12px;
  color: #ffffff;
  cursor: pointer;
  transition: opacity 0.3s;
  background: ${(props) => (props.disabled ? "#687684" : "#3579c6")};
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};

  &:hover {
    opacity: ${(props) => (props.disabled ? 0.7 : 0.8)};
  }
`;
