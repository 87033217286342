import { createAsyncThunk } from "@reduxjs/toolkit";

import API, { showNetworkErrorToast } from "Utils/api";
import { RootState } from "../..";

import { getCurrentSemanticMapId } from "../appState";
import { MissionPlansValidator } from "Slices/validationTypes";

export const fetchOperatorMissionPlans = createAsyncThunk<
  MissionPlan[],
  void,
  {
    rejectValue: unknown;
  }
>("fetchOperatorMissionPlans", async (_, thunkAPI) => {
  try {
    const semanticMapId = getCurrentSemanticMapId(
      thunkAPI.getState() as RootState
    );
    const response = await API.get(
      `/mission_plans/operator_flow/?semantic_map=${semanticMapId}`
    );
    return MissionPlansValidator.parse(response.data.results);
  } catch (error) {
    showNetworkErrorToast(error);
    return Promise.reject();
  }
});
