import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";

import { ButtonComponent } from "Components/common/ButtonComponent/Button.component";
import { DropdownComponent } from "Components/common/DropdownComponent";
import { CheckboxComponent } from "Components/common/CheckBoxComponent/CheckBox.component";
import { ReactComponent as AdditionalInfoIcon } from "Components/common/assets/additionalInfo.svg";
import { getVehiclesByCurrentSemanticMap } from "Slices/vehicles";
import { getFleets } from "Slices/fleets";

import { InputStyled } from "../ActionsTable/styles";
import {
  ConfirmMissionsModalButtonsStyled,
  ConfirmMissionsModalTextStyled,
} from "../ConfirmMissionsModal/styles";

import {
  MissionDetailsHelperTextStyled,
  MissionDetailsItemContentStyled,
  MissionDetailsItemStyled,
  MissionDetailsLabelStyled,
  MissionDetailsModalBodyWrapper,
  MissionDetailsModalContentStyled,
  MissionDetailsModalDropdown,
  MissionDetailsModalStyled,
  MissionDetailsTitleItemStyled,
  MissionDetailsModalHeaderWrapper,
} from "./styles";

type MissionsModalProps = {
  handleVehiclePick: (
    vehicleId: string,
    selectedLoopsValue: number,
    fleet: string
  ) => void;
  handleFleetPick: (currentFleetId: string, selectedLoopsValue: number) => void;
  cancelAction: () => void;
  currentMissionActions: Array<MissionAction | MissionActionPatch> | [];
  vehicleName?: string | null;
  vehicleId?: string;
  currentFleetId: string;
};

export const AssignModal: FC<MissionsModalProps> = ({
  vehicleId,
  vehicleName,
  handleVehiclePick,
  handleFleetPick,
  cancelAction,
  currentMissionActions,
  currentFleetId,
}) => {
  const [infinitelyLoop, setInfinitelyLoop] = useState<boolean>(false);
  const [loopsNumber, setLoopsNumber] = useState<string>("1");
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle>();
  const [selectedFleet, setSelectedFleet] = useState<string>();
  const vehiclesByCurrentSemanticMap: Vehicle[] = useSelector(
    getVehiclesByCurrentSemanticMap
  );

  const fleets = useSelector(getFleets);
  const currentFleetName = useMemo(
    () => fleets.find(({ id }) => id === currentFleetId)?.name,
    [currentFleetId, fleets]
  );

  const currentVehicles = useMemo(() => {
    return vehiclesByCurrentSemanticMap.filter(
      (vehicle) => vehicle.fleetId === currentFleetId
    );
  }, [vehiclesByCurrentSemanticMap, currentFleetId]);

  const isOnlyOneAction = currentMissionActions.length === 1;

  useEffect(() => {
    if (isOnlyOneAction) {
      setLoopsNumber("1");
    }
  }, [isOnlyOneAction]);

  const selectVehicle = (vehicle: Vehicle) => {
    setSelectedVehicle(vehicle);
    setSelectedFleet("");
  };

  const deployMission = useCallback(() => {
    const selectedLoopsValue = infinitelyLoop ? "-1" : loopsNumber;

    if (vehicleId) {
      handleVehiclePick(vehicleId, Number(selectedLoopsValue), currentFleetId);
    }
    if (selectedVehicle) {
      handleVehiclePick(
        selectedVehicle.id,
        Number(selectedLoopsValue),
        currentFleetId
      );
    }
    if (selectedFleet && currentFleetId) {
      handleFleetPick(currentFleetId, Number(selectedLoopsValue));
    }
  }, [
    selectedVehicle,
    currentFleetId,
    vehicleId,
    selectedFleet,
    loopsNumber,
    infinitelyLoop,
  ]);

  const isDeployButtonDisabled = useMemo(() => {
    const isLoopsFilled = loopsNumber || infinitelyLoop;
    const isMissionPlannerAssignedDataFilled = selectedVehicle || selectedFleet;
    const isRealTimeMapAssignedDataFilled = Boolean(vehicleId);
    const isAssignedDataFilled =
      isMissionPlannerAssignedDataFilled || isRealTimeMapAssignedDataFilled;

    return !isLoopsFilled || !isAssignedDataFilled;
  }, [loopsNumber, infinitelyLoop, selectedVehicle, selectedFleet, vehicleId]);

  const handleSelectFleet = useCallback(() => {
    setSelectedFleet(currentFleetName);
  }, [currentFleetName]);

  useEffect(() => {
    if (selectedFleet && infinitelyLoop) {
      // handling case: pick vehicle -> pick fleet -> tick infinite loop checkbox
      if (selectedVehicle) {
        setSelectedVehicle(undefined);
      }
      setSelectedFleet(undefined);
    }
  }, [infinitelyLoop, selectedFleet, selectedVehicle]);

  return (
    <MissionDetailsModalStyled>
      <MissionDetailsModalContentStyled>
        {vehicleName && (
          <MissionDetailsModalHeaderWrapper>
            <MissionDetailsLabelStyled>
              You are planning a mission for
            </MissionDetailsLabelStyled>
            <MissionDetailsLabelStyled header bold>
              {vehicleName}
            </MissionDetailsLabelStyled>
          </MissionDetailsModalHeaderWrapper>
        )}
        <MissionDetailsModalBodyWrapper>
          <MissionDetailsTitleItemStyled>
            <MissionDetailsLabelStyled>
              Number of Times to Run Mission
            </MissionDetailsLabelStyled>
          </MissionDetailsTitleItemStyled>
          <MissionDetailsItemContentStyled>
            <MissionDetailsItemStyled>
              <InputStyled
                type="number"
                onKeyDown={(evt) =>
                  (["e", "E", "+", "-", "."].includes(evt.key) ||
                    (loopsNumber === "" && ["0"].includes(evt.key))) &&
                  evt.preventDefault()
                }
                min="1"
                disabled={isOnlyOneAction || infinitelyLoop}
                placeholder="No."
                value={isOnlyOneAction ? 1 : loopsNumber}
                onChange={(e) => setLoopsNumber(e.target.value)}
              />
              <CheckboxComponent
                disabled={isOnlyOneAction}
                checked={infinitelyLoop}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setLoopsNumber("");
                  setInfinitelyLoop(event.target.checked);
                }}
                labelText="Infinitely Loop"
              />
            </MissionDetailsItemStyled>
          </MissionDetailsItemContentStyled>
          {isOnlyOneAction && (
            <MissionDetailsHelperTextStyled>
              <AdditionalInfoIcon />
              Single-stop missions can run only once
            </MissionDetailsHelperTextStyled>
          )}
          {!vehicleName && currentVehicles.length !== 0 && (
            <>
              <ConfirmMissionsModalTextStyled>
                Assign to vehicle or fleet
              </ConfirmMissionsModalTextStyled>
              <MissionDetailsModalDropdown>
                <DropdownComponent
                  placeholderValue={"Select a Vehicle"}
                  listData={currentVehicles}
                  currentItem={selectedFleet || selectedVehicle?.name || ""}
                  changeItemAction={selectVehicle}
                  specialAction={infinitelyLoop ? undefined : currentFleetName}
                  specialActionHandler={handleSelectFleet}
                />
              </MissionDetailsModalDropdown>
            </>
          )}
          {!vehicleName && currentVehicles.length === 0 && (
            <ConfirmMissionsModalTextStyled>
              No vehicles assigned to fleet
            </ConfirmMissionsModalTextStyled>
          )}
        </MissionDetailsModalBodyWrapper>
        <ConfirmMissionsModalButtonsStyled>
          <ButtonComponent
            disabled={isDeployButtonDisabled}
            clickAction={deployMission}
            type="primary"
          >
            Deploy Mission
          </ButtonComponent>
          <ButtonComponent clickAction={cancelAction} type="cancel">
            Cancel
          </ButtonComponent>
        </ConfirmMissionsModalButtonsStyled>
      </MissionDetailsModalContentStyled>
    </MissionDetailsModalStyled>
  );
};
