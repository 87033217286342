import styled from "styled-components";

export const ForgotPasswordButtonContentStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const ForgotPasswordButtonStyled = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 130%;
  text-transform: uppercase;
  margin-bottom: 24px;
  color: #767676;
  cursor: pointer;
  opacity: 1;
  transition: all 0.3s;

  &:hover {
    opacity: 0.8;
  }
`;
