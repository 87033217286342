import React, { memo } from "react";
import { Outlet } from "react-router-dom";

import {
  DeploymentsContainerStyled,
  DeploymentsListContentStyled,
} from "Components/LiveQueueDeployments/styles";
import { AsideFilters } from "Components/LiveQueueDeployments/components/AsideFilters/AsideFilters";
import { Header } from "Components/LiveQueueDeployments/components/Header/Header";
import { SearchContextHelper } from "Components/LiveQueueDeployments/SearchContextHelper";
import { LiveQueuePageLoader } from "Components/common/Loader/Loader";
import { useSelector } from "react-redux";
import { getIsLiveQueueDeploymentsUpdating } from "Slices/liveQueueDeploymentsPage";

export const LiveQueueDeployments = memo(() => {
  const isLiveQueueDeploymentsUpdating = useSelector(
    getIsLiveQueueDeploymentsUpdating
  );
  return (
    <DeploymentsContainerStyled>
      <AsideFilters />
      <DeploymentsListContentStyled>
        <SearchContextHelper>
          {isLiveQueueDeploymentsUpdating && <LiveQueuePageLoader />}
          <Header />
          <Outlet />
        </SearchContextHelper>
      </DeploymentsListContentStyled>
    </DeploymentsContainerStyled>
  );
});
