import styled from "styled-components";

export const InitialContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 32px;
`;

export const UploadFileWrapperStyled = styled.div`
  box-sizing: border-box;
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  color: #1b1b1b;
  font-size: 20px;
  font-weight: 300;

  span {
    margin-bottom: 16px;
  }
`;

export const UploadedFileContentStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const UploadedFileInfoStyled = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #1b1b1b;
  display: flex;
  align-items: center;

  span {
    font-weight: 300;
    margin: 0 12px 0 0;
  }

  svg {
    margin-right: 16px;
  }
`;

export const RemoveFileButtonStyled = styled.div`
  font-weight: 400;
  color: #3f7f7d;
  cursor: pointer;
  font-size: 14px;
  opacity: 1;
  transition: all 0.3s;

  &:hover {
    opacity: 0.8;
  }
`;

export const ZonePickerStyled = styled.div`
  color: #1b1b1b;
  font-size: 20px;
  font-weight: 300;
  box-sizing: border-box;
  padding: 0;

  span {
    margin-bottom: 8px;
    display: flex;
  }
`;
