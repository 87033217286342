import React, { useState, useCallback, FC } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";

import { getBackUrl, getLogin } from "Slices/appState";
import { BASE } from "Utils/api";
import { getLoginErrorText } from "Slices/appState/selectors";
import { Title } from "./common/Title/Title";
import { Input } from "./common/Input/Input";
import { SubmitButton } from "./common/SubmitButton/SubmitButton";
import { ForgotPasswordButton } from "./common/ForgotPasswordButton/ForgotPasswordButton";
import { FormContainer } from "./common/FormContainer/FormContainer";

import { LoginErrMsgStyled, GoogleLoginContainerStyled } from "./styles";

const GOOGLE_CLIENT_ID = window._env_.REACT_APP_GOOGLE_CLIENT_ID;

type LoginProps = {
  onForgotSelected: () => void;
};

export const LoginComponent: FC<LoginProps> = ({ onForgotSelected }) => {
  const loginErrorText = useSelector(getLoginErrorText);
  const backUrl = useSelector(getBackUrl);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoginDataEmpty, setIsLoginDataEmpty] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onGoogleLoginSuccess = useCallback(
    async (credentialResponse: CredentialResponse) => {
      if (!credentialResponse.credential) {
        toast.error("Invalid google response, token required");
        return;
      }
      const headers = {
        Authorization: credentialResponse.credential,
        "Content-Type": "application/json",
      };

      const responseAuth = await axios.post(
        `${BASE}/oauth/google/`,
        {},
        {
          headers,
        }
      );

      if (responseAuth.data.access) {
        window.localStorage.setItem(
          "JWT",
          `Bearer ${responseAuth.data.access}`
        );
        window.localStorage.setItem(
          "JWTrefresh",
          `Bearer ${responseAuth.data.refresh}`
        );

        navigate(backUrl || "/");
      } else {
        toast.error(
          "Invalid username/password. Try again or click Forgot password to reset it."
        );
      }
    },
    [dispatch, navigate]
  );

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsLoginDataEmpty(false); // clear out errors on each submit

    if (!email || !password) {
      setIsLoginDataEmpty(true);
      return;
    }

    dispatch(getLogin({ email, password, navigateCallBack: navigate }));
  };

  return (
    <FormContainer handleSubmit={handleSubmit}>
      <Title>Sign In</Title>
      {isLoginDataEmpty && (
        <LoginErrMsgStyled>Username and password required.</LoginErrMsgStyled>
      )}
      <Input
        type="email"
        name="email"
        onChange={(e) => setEmail(e.currentTarget.value.toLowerCase())}
        placeholder="Email"
        value={email}
      />
      <Input
        type="password"
        name="password"
        onChange={(e) => setPassword(e.currentTarget.value)}
        placeholder="Password"
        value={password}
      />
      <ForgotPasswordButton onClickAction={onForgotSelected}>
        FORGOT PASSWORD
      </ForgotPasswordButton>
      <SubmitButton>Sign In</SubmitButton>
      {GOOGLE_CLIENT_ID && (
        <GoogleLoginContainerStyled>
          <GoogleLogin
            size="large"
            width={368}
            onSuccess={onGoogleLoginSuccess}
            onError={() => {
              console.log("Google sign in error");
            }}
          />
        </GoogleLoginContainerStyled>
      )}
      {loginErrorText && (
        <LoginErrMsgStyled>{loginErrorText}</LoginErrMsgStyled>
      )}
    </FormContainer>
  );
};
