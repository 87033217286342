import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { setShowGlobalLoader } from "Slices/appState";
import { fetchGetFleets, getIsFleetsLoaded } from "Slices/fleets";
import { getIsFleetsLoadingError } from "Slices/fleets/selectors";
import { setErrorWarning } from "Slices/warning";
import {
  fetchGetVehicles,
  getIsVehiclesLoaded,
  getIsVehiclesLoadingError,
} from "Slices/vehicles";
import { ChildrenProps } from "Utils/types";
import { getServerErrorMessage } from "Utils";

const useHandleDataLoadingErrors = () => {
  const dispatch = useDispatch();
  const isFleetsLoadingError = useSelector(getIsFleetsLoadingError);
  const isVehiclesLoadingError = useSelector(getIsVehiclesLoadingError);

  useEffect(() => {
    if (isFleetsLoadingError || isVehiclesLoadingError) {
      const problemData = `
       ${isFleetsLoadingError ? "FLEETS" : ""}
       ${isVehiclesLoadingError ? "VEHICLES" : ""}
      `.trim();

      dispatch(setShowGlobalLoader(false));
      dispatch(setErrorWarning(getServerErrorMessage(problemData)));
    }
  }, [isFleetsLoadingError, isVehiclesLoadingError]);
};

export const AutoMissionGeneratorLoader = ({ children }: ChildrenProps) => {
  const dispatch = useDispatch();
  const isFleetsLoaded = useSelector(getIsFleetsLoaded);
  const isVehiclesLoaded = useSelector(getIsVehiclesLoaded);

  useHandleDataLoadingErrors();

  // GET /fleets
  useEffect(() => {
    if (isFleetsLoaded) return;
    dispatch(setShowGlobalLoader(true));
    dispatch(fetchGetFleets());
  }, [isFleetsLoaded]);

  // GET /vehicles
  useEffect(() => {
    if (isVehiclesLoaded) return;
    dispatch(setShowGlobalLoader(true));
    dispatch(fetchGetVehicles());
  }, [isVehiclesLoaded]);

  // turn the loader off
  useEffect(() => {
    if (isFleetsLoaded && isVehiclesLoaded) {
      dispatch(setShowGlobalLoader(false));
    }
  }, [isFleetsLoaded, isVehiclesLoaded]);

  return <>{children}</>;
};
