import React, { memo, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { List } from "Components/NewList";
import { RowData } from "Components/NewList/types";
import { ReactComponent as Edit } from "Components/common/assets/edit.svg";
import { getDeploymentStatusField } from "Components/AllDeployments/components/AllDeploymentsList/utils";
import {
  getVehiclesPageVehicles,
  setVehiclesPageSorting,
} from "Slices/vehiclesPage";
import { toggleModal } from "Slices/modals";
import { OrderType } from "Utils";

import { getVehicleDriveModeField } from "./utils";

const useVehicleListReordering = (vehicles: VehiclesPageVehicle[]) => {
  const dispatch = useDispatch();

  const [nameOrder, setNameOrder] = useState<OrderType>(1);
  const [licensePlateOrder, setLicensePlateOrder] = useState<OrderType>(1);
  const [typeOrder, setTypeOrder] = useState<OrderType>(1);
  const [fleetOrder, setFleetOrder] = useState<OrderType>(1);
  const [driveModeOrder, setDriveModeOrder] = useState<OrderType>(1);
  const [missionStatusOrder, setMissionStatusOrder] = useState<OrderType>(1);
  const [missionNameOrder, setMissionNameOrder] = useState<OrderType>(1);

  const reorderOptions = useMemo(
    () => ({
      name: () => {
        dispatch(
          setVehiclesPageSorting({ columnName: "name", order: nameOrder })
        );
        setNameOrder((nameOrder * -1) as OrderType);
      },
      licensePlate: () => {
        dispatch(
          setVehiclesPageSorting({
            columnName: "licensePlate",
            order: licensePlateOrder,
          })
        );
        setLicensePlateOrder((licensePlateOrder * -1) as OrderType);
      },
      type: () => {
        dispatch(
          setVehiclesPageSorting({ columnName: "type", order: typeOrder })
        );
        setTypeOrder((typeOrder * -1) as OrderType);
      },
      fleet: () => {
        dispatch(
          setVehiclesPageSorting({ columnName: "fleetName", order: fleetOrder })
        );
        setFleetOrder((fleetOrder * -1) as OrderType);
      },
      driveMode: () => {
        dispatch(
          setVehiclesPageSorting({
            columnName: "driveMode",
            order: driveModeOrder,
          })
        );
        setDriveModeOrder((driveModeOrder * -1) as OrderType);
      },
      missionStatus: () => {
        dispatch(
          setVehiclesPageSorting({
            columnName: "missionStatus",
            order: missionStatusOrder,
          })
        );
        setMissionStatusOrder((missionStatusOrder * -1) as OrderType);
      },
      missionName: () => {
        dispatch(
          setVehiclesPageSorting({
            columnName: "missionName",
            order: missionNameOrder,
          })
        );
        setMissionNameOrder((missionNameOrder * -1) as OrderType);
      },
    }),
    [vehicles]
  );

  return reorderOptions;
};

type VehicleListRowData = RowData & {
  name: string;
  licensePlate: string;
  type: string;
  fleet: string;
  driveMode: JSX.Element;
  missionStatus: JSX.Element;
};

export const VehiclesList = memo(() => {
  const dispatch = useDispatch();
  const vehicles = useSelector(getVehiclesPageVehicles);
  const reorderOptions = useVehicleListReordering(vehicles);

  const headerData = useMemo(
    () => ({
      name: "Vehicle Name",
      licensePlate: "VIN #",
      type: "Type",
      fleet: "Fleet",
      driveMode: "Vehicle Status",
      missionStatus: "Mission Status",
      missionName: "Mission Name",
    }),
    []
  );

  const options = useMemo(
    () => ({
      icon: <Edit />,
      callback: (data: VehicleListRowData) => {
        dispatch(
          toggleModal({
            type: "VEHICLE_MODAL",
            data: { vehicleId: data.id },
          })
        );
      },
    }),
    []
  );

  const preparedVehiclesData: VehicleListRowData[] = useMemo(() => {
    if (vehicles.length === 0) return [];

    return vehicles.map((vehicle) => ({
      id: vehicle.id,
      name: vehicle.name,
      licensePlate: vehicle.licensePlate,
      type: vehicle.type,
      fleet: vehicle.fleetName,
      driveMode: getVehicleDriveModeField(
        vehicle.driveMode as VehicleDriveMode
      ),
      missionStatus: getDeploymentStatusField(vehicle.missionDeploymentStatus),
      missionName: vehicle.missionDeploymentName,
    }));
  }, [vehicles]);

  return (
    <List
      data={preparedVehiclesData}
      headerData={headerData}
      options={options}
      reorderOptions={reorderOptions}
    />
  );
});
