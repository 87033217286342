import { createSlice } from "@reduxjs/toolkit";

import { RowData } from "Components/NewList/types";

type QueueDeploymentsSection = {
  deployments: null | Array<Deployment | LimitedDeployment>;
  isQueueDeploymentsUpdatesPaused: null | boolean;
  queueDeploymentsUpdatesStorage: Array<Deployment | LimitedDeployment | never>;
};

const initialState: QueueDeploymentsSection = {
  deployments: null,
  isQueueDeploymentsUpdatesPaused: false,
  queueDeploymentsUpdatesStorage: [],
};

export const Slice = createSlice({
  name: "queueDeploymentsSection",
  initialState: initialState,
  reducers: {
    resetQueueDeploymentsSectionData: () => initialState,
    setQueueDeploymentsSection: (state, action) => {
      state.deployments = action.payload;
      return state;
    },
    reorderQueueDeploymentsSection: (state, action) => {
      // TODO: implement placing near ordered element procedure
      const reorderedQueueDeployments = action.payload
        ?.map?.((id: RowData["id"]) => {
          const foundedReorderedItem = state.deployments?.find(
            (item) => item.id === id
          );
          return foundedReorderedItem;
        })
        .filter(Boolean);

      state.deployments = reorderedQueueDeployments
        ? reorderedQueueDeployments
        : state.deployments;
      return state;
    },
    updateQueueDeploymentsSection: (state, action) => {
      const receivedDeployment = action.payload;
      if (!receivedDeployment) return state;

      if (state.isQueueDeploymentsUpdatesPaused) {
        state.queueDeploymentsUpdatesStorage.push(action.payload?.data);
        return state;
      }

      const isNewDeployment = !state.deployments?.find(
        (deployment) => deployment?.id === receivedDeployment?.id
      );

      if (isNewDeployment) {
        state.deployments = [
          ...(state.deployments?.length ? state.deployments : []),
          receivedDeployment,
        ];
      } else {
        state.deployments =
          state.deployments?.map((deployment) =>
            deployment.id === receivedDeployment?.id
              ? receivedDeployment
              : deployment
          ) ?? [];
      }
      return state;
    },
    // handleDragEnd
    pauseQueueDeploymentsSectionUpdates: (state) => {
      state.isQueueDeploymentsUpdatesPaused = true;
      return state;
    },
    // handleDragEnd
    continueQueueDeploymentsSectionUpdates: (state) => {
      state.isQueueDeploymentsUpdatesPaused = false;
      return state;
    },
    // after ejecting all missions from the storage
    resetQueueDeploymentsSectionUpdatesStorage: (state) => {
      state.queueDeploymentsUpdatesStorage = [];
      return state;
    },
  },
});

export default Slice;
