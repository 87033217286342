import React, { memo, FC, useCallback, useRef } from "react";

import { IconLoaderProps } from "../../types";
import { DragAndDropFileHOC } from "../../../../common/DragAndDropFileHOC";
import { acceptableIconLoaderFormats } from "../constants";

import { ReactComponent as EmptyIcon } from "../../assets/emptyIcon.svg";
import {
  IconLoaderBodyStyled,
  TextHelperStyled,
  FileInputStyled,
  TextFormatLoaderStyled,
  FilePickerStyled,
} from "./styles";

export const AddIconLoader: FC<IconLoaderProps> = memo(({ setImageFile }) => {
  const fileInputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const handleFilePicker = useCallback(() => {
    fileInputRef.current.click();
  }, []);
  const handlerLoadIcon = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      setImageFile(event.target.files?.[0] || null);
    },
    []
  );

  return (
    <DragAndDropFileHOC
      setFile={setImageFile}
      acceptableFileFormats={acceptableIconLoaderFormats}
      errorMessage="Invalid image format"
    >
      {(isDragActive) => (
        <IconLoaderBodyStyled isDragActive={isDragActive}>
          <EmptyIcon />
          <TextHelperStyled>
            Drop your image here, or
            <FilePickerStyled onClick={handleFilePicker}>
              browse
            </FilePickerStyled>
            <FileInputStyled
              ref={fileInputRef}
              onChange={handlerLoadIcon}
              type="file"
              accept="image/*,.png,.jpg,.svg+xml,.svg,"
            />
          </TextHelperStyled>
          <TextFormatLoaderStyled>Supports: JPG,PNG,SVG</TextFormatLoaderStyled>
        </IconLoaderBodyStyled>
      )}
    </DragAndDropFileHOC>
  );
});
