import { createSlice } from "@reduxjs/toolkit";
import { EMPTY_ARRAY, EMPTY_OBJECT } from "Utils";

type OperatorDataState = {
  operatorMissionState: {
    queuedMissionId?: string;
    status?: "NO_MISSION" | "IN_QUEUE" | "IN_PROGRESS";
    order?: number;
    fleetId?: string | null;
  };
  pickedFleetId: string | null;
  operatorDeployments: Deployment[];
};

const initialState: OperatorDataState = {
  operatorMissionState: EMPTY_OBJECT,
  operatorDeployments: EMPTY_ARRAY,
  pickedFleetId: null,
};

export const Slice = createSlice({
  name: "operatorData",
  initialState,
  reducers: {
    setInitialMissionOperatorData: (state, action) => {
      state.operatorMissionState =
        action.payload.missionOperatorData ?? EMPTY_OBJECT;
      state.operatorDeployments = action.payload.deployments ?? EMPTY_ARRAY;
    },
    setMissionOperatorFleetId: (state, action) => {
      state.pickedFleetId = action.payload;
    },
    resetMissionOperatorData: () => initialState,
    updateMissionOperatorData: (state, action) => {
      if (action.payload.missionOperatorData) {
        state.operatorMissionState = action.payload.missionOperatorData;
      }

      if (action.payload.deployment) {
        const receivedDeployment = action.payload.deployment;
        const isNewDeployment = !state.operatorDeployments?.find(
          (deployment) => deployment?.id === receivedDeployment?.id
        );
        if (isNewDeployment) {
          state.operatorDeployments = [
            receivedDeployment,
            ...state.operatorDeployments,
          ];
        } else {
          state.operatorDeployments = state.operatorDeployments.map(
            (deployment) =>
              deployment.id === receivedDeployment.id
                ? {
                    ...receivedDeployment,
                  }
                : {
                    ...deployment,
                  }
          );
        }
      }
    },
  },
});

export default Slice;
