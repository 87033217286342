import { z } from "zod";
import { createAsyncThunk } from "@reduxjs/toolkit";

import API, { showNetworkErrorToast } from "Utils/api";
import { sortCollectionByName } from "Utils/collectionUtils";

export const fetchGetSemanticMaps = createAsyncThunk(
  "semantic_maps/fetchGetSemanticMaps",
  async () => {
    try {
      const response = await API.get(
        "semantic_maps/?fields=id%2C%20name%2C%20bbox%2C%20track"
      );
      const validatedSemanticMaps = SemanticMapsValidator.parse(
        response.data.results
      );
      return sortCollectionByName(validatedSemanticMaps);
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

const Bbox = z.tuple([z.number(), z.number(), z.number(), z.number()]);

const SemanticMapFeature = z.object({
  type: z.string(),
  geometry: z.object({
    type: z.string(),
    coordinates: z.array(z.array(z.number())),
  }),
  properties: z.record(z.string(), z.union([z.string(), z.number()])),
});

const SemanticMapTrack = z.object({
  type: z.literal("FeatureCollection"),
  features: z.array(SemanticMapFeature),
});

const SemanticMapsValidator = z.array(
  z.object({
    id: z.string(),
    name: z.string(),
    bbox: Bbox,
    track: SemanticMapTrack,
  })
);
