import styled from "styled-components";

export const InputStyled = styled.input.attrs({
  autoCorrect: "off",
  autoCapitalize: "off",
  spellCheck: "false",
})`
  display: flex;
  align-items: center;
  border: 1px solid #cfd0d5;
  font-family: "Red Hat Display", sans-serif;
  padding: 7px;
  appearance: none;
  font-size: 14px;
  margin-bottom: 8px;
  box-sizing: border-box;
  height: 40px;
  border-radius: 4px;

  &:focus {
    border: 2px solid #8fbdff;
    padding: 6px;
  }
`;

export const SubmitStyled = styled.button.attrs({ type: "submit" })`
  color: rgb(255, 255, 255);
  font-family: "Red Hat Display", sans-serif;
  background-color: rgb(76, 136, 255);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  margin-bottom: 8px;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 14px;
  height: 40px;
  border: 2px solid transparent;
  transition: all 0.2s;

  &:focus {
    border: 2px solid #8fbdff;
  }

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.5;
  }
`;

export const TitleStyled = styled.h2`
  font-size: 28px;
  color: #0f1731;
  text-align: center;
  padding: 20px 0;
`;

export const LoginErrMsgStyled = styled.div`
  color: red;
  margin: 0 auto;
  font-size: 14px;
  position: absolute;
  width: 308px;
  box-sizing: border-box;
  justify-content: center;
  display: flex;
  bottom: 0;
`;

export const CancelStyled = styled.button`
  padding: 8px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 40px;
  border: 2px solid transparent;
  background-color: transparent;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.54);

  &:focus {
    border: 2px solid #8fbdff;
  }

  &:active {
    opacity: 0.5;
  }
`;

export const ErrMsgStyled = styled.div`
  color: red;
  font-style: italic;
  margin: 8px 0;
`;

export const GoogleLoginContainerStyled = styled.div`
  margin-top: 24px;
`;
