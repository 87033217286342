import React, { FC } from "react";

import { ButtonComponent } from "Components/common/ButtonComponent/Button.component";

import {
  ConfirmMissionsModalStyled,
  ConfirmMissionsModalContentStyled,
  ConfirmMissionsModalButtonsStyled,
  ConfirmMissionsModalTextStyled,
} from "./styles";

type ConfirmMissionsModalProps = {
  text: string;
  confirmAction: () => void;
  cancelAction: () => void;
};

export const ConfirmMissionsModal: FC<ConfirmMissionsModalProps> = ({
  text,
  confirmAction,
  cancelAction,
}) => {
  return (
    <ConfirmMissionsModalStyled>
      <ConfirmMissionsModalContentStyled>
        <ConfirmMissionsModalTextStyled>{text}</ConfirmMissionsModalTextStyled>
        <ConfirmMissionsModalButtonsStyled>
          <ButtonComponent clickAction={confirmAction} type="primary">
            Yes
          </ButtonComponent>
          <ButtonComponent clickAction={cancelAction} type="cancel">
            No
          </ButtonComponent>
        </ConfirmMissionsModalButtonsStyled>
      </ConfirmMissionsModalContentStyled>
    </ConfirmMissionsModalStyled>
  );
};
