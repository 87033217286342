import styled from "styled-components";

export const ActionsTableStyled = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const PlanListStyled = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 443px);
  box-sizing: border-box;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
`;

export const PlanItemsBodyStyled = styled.div`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid rgb(221, 221, 221);
  background-color: #f4f4f4;
`;

export const PlanItemsStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 16px;
`;

interface PlanItemNameProps {
  width?: number;
}

export const PlanItemNameStyled = styled.div<PlanItemNameProps>`
  width: ${(props) => (props.width ? `${props.width}px` : "140px")};
  font-weight: 400;
  font-size: 14px;
  color: rgb(51, 51, 51);
`;

export const RemoveItemStyled = styled.div`
  opacity: 0.7;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    opacity: 1;
  }
`;

export const DropdownContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  box-sizing: border-box;
  top: 40px;
  width: 100%;
  left: 0;
  z-index: 1;
  border-top: 2px solid rgb(85, 184, 179);
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
  max-height: 271px;
  overflow-y: auto;
`;

export const InputStyled = styled.input`
  font-size: 16px;
  height: 100%;
  padding: 12px 16px;
  min-width: 140px;
  box-sizing: border-box;
  border: 2px solid;
  border-color: transparent;
  color: rgb(96, 96, 96);

  ::placeholder {
    color: #c4c4c4;
  }

  &:focus {
    outline: none;
  }
`;

export const PlanHeaderStyled = styled.div`
  width: 100%;
  height: 48px;
  font-size: 16px;
  font-weight: 400;
  color: rgb(27, 27, 27);
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(27, 27, 27);
`;

export const PlanHeaderItemStyled = styled.div`
  box-sizing: border-box;
  width: 140px;
  padding: 8px;
`;
