import React, { memo, FC, useState, useRef, useMemo, useCallback } from "react";

import { useOnClickOutside } from "Utils/hooks";
import { HelperText } from "Components/common/HelperText/HelperText";
import { ErrorText } from "Components/common/ErrorText/ErrorText";
import {
  DropdownBtnStyled,
  ListItemTextStyled,
} from "Components/Modals/ListDataPagesModals/components/Dropdown/styles";
import { LabelField } from "../LabelField";

import { ReactComponent as ArrowIcon } from "../../assets/arrowIcon.svg";
import { ReactComponent as SearchIcon } from "../../assets/searchIcon.svg";
import {
  SearchDropdownInputStyled,
  SearchDropdownContainerStyled,
  SearchInputContainerStyled,
  SearchDropdownListContainerStyled,
  SearchDropdownListStyled,
  DelimiterStyled,
  SearchIconStyled,
} from "./styles";
import { DropdownListItemStyled } from "./../SearchDropdownListItem/styles";

interface SearchDropdownLightProps {
  isLoading?: boolean;
  isLoadingError?: boolean;
  loadingErrorText?: string;
  currentValue: ModalItem | null;
  isIncludesEmptyValue: boolean;
  labelText: string;
  placeholderText: string;
  isRequired?: boolean;
  additionalInfo?: string;
  options: Array<ModalItem>;
  changeAction: (dropdownItem: ModalItem | null) => void;
}

export const SearchDropdownLight: FC<SearchDropdownLightProps> = memo(
  ({
    isLoading = false,
    isLoadingError = false,
    loadingErrorText = "",
    currentValue,
    isIncludesEmptyValue,
    labelText,
    placeholderText,
    options,
    additionalInfo,
    isRequired = false,
    changeAction,
  }) => {
    const [searchValue, setSearchValue] = useState<string>("");
    const [isOpened, setIsOpened] = useState<boolean>(false);

    const dropdownRef = useRef() as React.MutableRefObject<HTMLDivElement>;
    const currentValueId = currentValue?.id || null;
    const handleClickOutside = useCallback(() => setIsOpened(false), []);
    useOnClickOutside(dropdownRef, handleClickOutside);

    const filteredOptions: Array<ModalItem> = useMemo(
      () =>
        options.filter((option) =>
          option.text.toLowerCase().includes(searchValue.toLowerCase())
        ),
      [options, searchValue]
    );

    const dropdownFooter = useMemo(() => {
      if (isLoadingError) {
        return <ErrorText errorMessage={loadingErrorText} />;
      } else if (additionalInfo) {
        return <HelperText additionalInfo={additionalInfo} />;
      } else return null;
    }, [isLoadingError, additionalInfo]);

    return (
      <>
        <LabelField labelText={labelText} isRequired={isRequired} />
        <SearchDropdownContainerStyled ref={dropdownRef}>
          <DropdownBtnStyled
            isLoading={isLoading}
            isOpened={isOpened}
            onClick={() => {
              if (!isLoading) setIsOpened(!isOpened);
            }}
          >
            {isOpened ? (
              <SearchInputContainerStyled>
                <SearchIconStyled>
                  <SearchIcon />
                </SearchIconStyled>
                <SearchDropdownInputStyled
                  autoFocus
                  isLoading={isLoading}
                  onClickCapture={(event) => {
                    if (!isLoading) setIsOpened(true);
                    event.stopPropagation();
                  }}
                  onChange={(e) => setSearchValue(e.target.value)}
                  value={searchValue}
                  placeholder={placeholderText}
                />
              </SearchInputContainerStyled>
            ) : (
              <span>{currentValue?.text ?? "Empty"}</span>
            )}
            <ArrowIcon />
          </DropdownBtnStyled>
          {isOpened && (
            <SearchDropdownListContainerStyled>
              <DelimiterStyled />
              <SearchDropdownListStyled>
                {isIncludesEmptyValue && (
                  <DropdownListItemStyled
                    onClick={() => {
                      changeAction(null);
                      setIsOpened(false);
                    }}
                  >
                    Empty
                  </DropdownListItemStyled>
                )}
                {filteredOptions.map((item, index) =>
                  item.id !== currentValueId ? (
                    <DropdownListItemStyled
                      key={`${item.id}-${index}`}
                      onClick={() => {
                        changeAction(item);
                        setIsOpened(false);
                      }}
                    >
                      <ListItemTextStyled>{item.text}</ListItemTextStyled>
                    </DropdownListItemStyled>
                  ) : null
                )}
              </SearchDropdownListStyled>
            </SearchDropdownListContainerStyled>
          )}
          {dropdownFooter}
        </SearchDropdownContainerStyled>
      </>
    );
  }
);
