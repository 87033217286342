import styled from "styled-components";

export const InputStyled = styled.input.attrs({
  autoCorrect: "off",
  autoCapitalize: "off",
  spellCheck: "false",
})`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(30, 36, 50, 0.23);
  padding: 12px 24px;
  appearance: none;
  font-size: 14px;
  margin-bottom: 8px;
  box-sizing: border-box;
  height: 48px;
  font-size: 16px;
  font-weight: 300px;
  color: #494949;
  line-height: 130%;

  &::placeholder {
    opacity: 0.5;
  }
`;
