import React, { FC, memo } from "react";
import { useDispatch } from "react-redux";

import { fetchDeployMissionPlan } from "Slices/missionPlans";
import { ADD_EDIT_MISSION_MODAL, toggleModal } from "Slices/modals";
import { MISSION_DEPLOYMENT_ACTIONS } from "Utils/constants";
import { FMSLogger } from "FMSLogger";
import { EMPTY_ICON } from "Utils";
import { HeaderLinksAddButtonStyled } from "Components/ListPageHeader/styles";
import { ReactComponent as AddIcon } from "Components/ListPageHeader/assets/addIcon.svg";
import { DeployedMissionList } from "../../DeployedMissionList/DeployedMissionList";

import {
  MissionPlannerStyled,
  MissionPlannerBackgroundStyled,
  MissionPlannerLabelStyled,
  MissionPlannerRouteNameStyled,
  MissionPlannerHeadContentStyled,
  MissionPlannerHeadLeftStyled,
  MissionPlannerHeadInfoStyled,
  ImgStyled,
  DeployedMissionListBodyStyled,
  MissionPlannerContainerStyled,
  MissionPlannerContentStyled,
  MissionPlannerHeaderStyled,
} from "./styles";

const missionDeploymentActionsLogger = FMSLogger.byPrefix(
  MISSION_DEPLOYMENT_ACTIONS
);

type MissionPlannerProps = {
  missionPlannerData: MissionPlannerData;
  setMissionPlannerData: (
    missionPlannerData: MissionPlannerData | null
  ) => void;
};

export const MissionPlanner: FC<MissionPlannerProps> = memo(
  ({ missionPlannerData, setMissionPlannerData }) => {
    const dispatch = useDispatch();
    const deployPlanMissionAction = async (
      data: Omit<Deployment, "actions">
    ) => {
      await dispatch(fetchDeployMissionPlan({ ...data }));
    };

    return (
      <MissionPlannerStyled>
        <MissionPlannerBackgroundStyled
          onClick={() => setMissionPlannerData(null)}
        />
        <MissionPlannerContainerStyled>
          <MissionPlannerContentStyled withHeader>
            <MissionPlannerHeadContentStyled>
              <MissionPlannerHeaderStyled>
                Mission Planner
              </MissionPlannerHeaderStyled>
              <MissionPlannerHeadInfoStyled>
                <ImgStyled
                  src={
                    missionPlannerData?.vehicleType?.image?.link || EMPTY_ICON
                  }
                />
                <MissionPlannerHeadLeftStyled>
                  <MissionPlannerLabelStyled>
                    You are planning a mission for
                  </MissionPlannerLabelStyled>
                  <MissionPlannerRouteNameStyled>
                    {missionPlannerData?.vehicleName}
                  </MissionPlannerRouteNameStyled>
                  <HeaderLinksAddButtonStyled
                    onClick={() => {
                      missionDeploymentActionsLogger.debug(
                        "choose tab with new mission"
                      );
                      dispatch(
                        toggleModal({
                          type: ADD_EDIT_MISSION_MODAL,
                          data: {
                            vehicleId: missionPlannerData.vehicleId,
                            vehicleName: missionPlannerData.vehicleName,
                            fleetId: missionPlannerData.fleetId,
                          },
                        })
                      );
                    }}
                  >
                    <AddIcon />
                    Create New Mission
                  </HeaderLinksAddButtonStyled>
                </MissionPlannerHeadLeftStyled>
              </MissionPlannerHeadInfoStyled>
            </MissionPlannerHeadContentStyled>
            <DeployedMissionListBodyStyled>
              <DeployedMissionList
                vehicleName={missionPlannerData?.vehicleName}
                vehicleId={missionPlannerData?.vehicleId}
                deployPlanMissionAction={deployPlanMissionAction}
                cancelAction={() => setMissionPlannerData(null)}
                previousMode="CANCEL"
                currentFleetId={missionPlannerData.fleetId}
              />
            </DeployedMissionListBodyStyled>
          </MissionPlannerContentStyled>
        </MissionPlannerContainerStyled>
      </MissionPlannerStyled>
    );
  }
);
