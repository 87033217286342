import React, { memo, useCallback } from "react";
import { TabData } from "../../types";
import { TabButton } from "../TabButton";
import { MissionListTabsStyled } from "./styles";
import { ReactComponent as DeployedIcon } from "../../assets/deployedIcon.svg";
import { ReactComponent as CompletedIcon } from "../../assets/completedIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveTab,
  getAllMissionsCount,
  getCompletedMissionsCount,
  getDeployedMissionsCount,
  setActiveTab,
} from "Slices/allDeploymentsPage";
import { FMSLogger } from "FMSLogger";
import { MISSION_PLANNER_ACTIONS } from "Utils/constants";

const missionPlannerActionsLogger = FMSLogger.byPrefix(MISSION_PLANNER_ACTIONS);

const missionsTabInfo: TabData[] = [
  { name: "All", Icon: null },
  { name: "Deployed", Icon: DeployedIcon },
  { name: "Completed", Icon: CompletedIcon },
];

const [tabAll, tabDeployed, tabCompleted] = missionsTabInfo;

export const MissionListTabs = memo(() => {
  const dispatch = useDispatch();
  const activeTabName = useSelector(getActiveTab);
  const allMissionsCount = useSelector(getAllMissionsCount);
  const deployedMissionsCount = useSelector(getDeployedMissionsCount);
  const completedMissionsCount = useSelector(getCompletedMissionsCount);

  const tabClickHandler = useCallback((name: MissionPlannerTabName) => {
    missionPlannerActionsLogger.debug(
      `Following tab has been clicked: ${name}`
    );
    dispatch(setActiveTab(name));
  }, []);

  return (
    <MissionListTabsStyled>
      <TabButton
        handleClick={tabClickHandler}
        name={tabAll.name}
        isActive={activeTabName === tabAll.name}
        counter={allMissionsCount}
        Icon={null}
      />
      <TabButton
        handleClick={tabClickHandler}
        name={tabDeployed.name}
        isActive={activeTabName === tabDeployed.name}
        counter={deployedMissionsCount}
        Icon={tabDeployed.Icon}
      />
      <TabButton
        handleClick={tabClickHandler}
        name={tabCompleted.name}
        isActive={activeTabName === tabCompleted.name}
        counter={completedMissionsCount}
        Icon={tabCompleted.Icon}
      />
    </MissionListTabsStyled>
  );
});
