import React, { FC, memo, MouseEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { Draggable, Droppable } from "react-beautiful-dnd";

import { DeploymentRow } from "../DeploymentRow/DeploymentRow";
import { toggleModal } from "Slices/modals";

import { ReactComponent as SelectIcon } from "../../assets/selectIcon.svg";
import { ReactComponent as RectangleIcon } from "../../assets/rectangleIcon.svg";
import { ReactComponent as ProgressIcon } from "../../assets/progressIcon.svg";
import { ReactComponent as PolygonIcon } from "../../assets/polygonIcon.svg";
import {
  DraggableItemStyled,
  DraggableContainerStyled,
  VehicleTitleStyled,
  VehicleGroupContainerStyled,
  VehicleTitleContainerStyled,
  ActiveDeploymentBodyStyled,
  ActiveDeploymentStyled,
  SelectIconBodyStyled,
  CounterDeploymentsContainerStyled,
  CounterValueStyled,
  CounterStyled,
} from "./styles";

type VehicleSectionProps = {
  vehicleId: VehicleId;
  vehicleName: string;
  deployments: Array<Deployment | LimitedDeployment>;
  activeDeployment?: Deployment | LimitedDeployment;
};

export const DeploymentsByVehicleSection: FC<VehicleSectionProps> = memo(
  ({ vehicleId, vehicleName, deployments, activeDeployment }) => {
    const dispatch = useDispatch();
    const [isShowDeplouments, setIsShowDeployments] = useState(
      deployments.length === 0
    );

    const handleExpandDeployments = () => {
      if (deployments.length === 0) return;
      setIsShowDeployments(!isShowDeplouments);
    };

    const handleOpenDeploymentDetails = (event: MouseEvent<HTMLDivElement>) => {
      if (activeDeployment) {
        dispatch(
          toggleModal({
            type: "DEPLOYMENT_DETAILS_MODAL",
            data: { deploymentId: activeDeployment.id },
          })
        );
        event.stopPropagation();
      }
    };

    return (
      <>
        <VehicleGroupContainerStyled
          isShowDeplouments={isShowDeplouments}
          onClick={handleExpandDeployments}
        >
          <VehicleTitleContainerStyled>
            <SelectIconBodyStyled isShowDeplouments={isShowDeplouments}>
              <SelectIcon />
            </SelectIconBodyStyled>
            <VehicleTitleStyled>{vehicleName}</VehicleTitleStyled>
            <RectangleIcon />
            <ActiveDeploymentBodyStyled
              onClickCapture={(event) => handleOpenDeploymentDetails(event)}
            >
              <ProgressIcon />
              <ActiveDeploymentStyled>
                {activeDeployment?.name ?? "No active mission"}
              </ActiveDeploymentStyled>
            </ActiveDeploymentBodyStyled>
          </VehicleTitleContainerStyled>
          <CounterDeploymentsContainerStyled
            isShowDeplouments={isShowDeplouments}
          >
            <CounterStyled isShowDeplouments={isShowDeplouments}>
              <PolygonIcon />
              <CounterValueStyled isShowDeplouments={isShowDeplouments}>
                {deployments.length}
              </CounterValueStyled>
            </CounterStyled>
            {deployments.length === 1 ? "Mission" : "Missions"}
          </CounterDeploymentsContainerStyled>
        </VehicleGroupContainerStyled>
        {isShowDeplouments && (
          <Droppable droppableId={vehicleId} key={vehicleId}>
            {(provided) => (
              <DraggableContainerStyled
                isShowDeplouments={isShowDeplouments}
                isNoDeployments={deployments.length === 0}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {deployments.map((deployment, index) => (
                  <Draggable
                    // remove isDragDisabled to enable dnd
                    isDragDisabled={true}
                    key={deployment.id}
                    draggableId={deployment.id}
                    index={index}
                  >
                    {(itemProvided) => (
                      <DraggableItemStyled
                        ref={itemProvided.innerRef}
                        {...itemProvided.draggableProps}
                        {...itemProvided.dragHandleProps}
                      >
                        <DeploymentRow deployment={deployment} />
                      </DraggableItemStyled>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </DraggableContainerStyled>
            )}
          </Droppable>
        )}
      </>
    );
  }
);
