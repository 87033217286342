import { createSlice } from "@reduxjs/toolkit";
import { fetchStation } from ".";
import { DataStatus } from "../../types";

type OperatorStation = {
  id?: string;
  stopId?: string;
  semanticMapId?: string;
};

type OperatorStationState = {
  station: OperatorStation | null;
  status: DataStatus;
  error: string | null;
};

const initialState: OperatorStationState = {
  station: null,
  status: DataStatus.idle,
  error: null,
};

export const Slice = createSlice({
  name: "operatorStation",
  initialState,
  reducers: {
    setStation: (state, action) => {
      state.station = action.payload;
    },
    resetMissionOperatorStation: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStation.pending, (state) => {
        state.status = DataStatus.loading;
        state.error = null;
      })
      .addCase(fetchStation.fulfilled, (state, action) => {
        state.status = DataStatus.succeeded;
        state.station =
          Object.keys(action.payload).length > 0 ? action.payload : null;
      })
      .addCase(fetchStation.rejected, (state, action) => {
        state.status = DataStatus.failed;
        state.error = action.error.message ?? "";
      });
  },
});

export default Slice;
