import { changeQueueDeploymentsSectionOrder } from "./actions";
import {
  getQueueDeploymentsUpdatesStorage,
  getIsQueueDeploymentsUpdatesPaused,
  getQueueDeployments,
} from "./selectors";
import LiveQueueDeploymentsSlice from "./reducers";

// actions
export const {
  resetQueueDeploymentsSectionData,
  setQueueDeploymentsSection,
  reorderQueueDeploymentsSection,
  updateQueueDeploymentsSection,
  pauseQueueDeploymentsSectionUpdates,
  continueQueueDeploymentsSectionUpdates,
  resetQueueDeploymentsSectionUpdatesStorage,
} = LiveQueueDeploymentsSlice.actions;

// async actions
export { changeQueueDeploymentsSectionOrder };

// selectors
export {
  getQueueDeploymentsUpdatesStorage,
  getIsQueueDeploymentsUpdatesPaused,
  getQueueDeployments,
};

// reducer
export default LiveQueueDeploymentsSlice.reducer;

// constants
export const UNASSIGNED_DEPLOYMENT_KEY = "unassigned";
