import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../../index";
import { arrayToObjectByField } from "Utils";
import { DataStatus } from "../../types";
import { getCurrentSemanticMapId } from "Slices/appState";

export const getVehicles = (state: RootState) => state.vehicles.data;
export const getVehiclesTransportFacilityModals = (state: RootState) =>
  state.vehicles.data.map(({ id, name }) => ({ id, text: name }));
export const getVehiclesTransportFacilityModalsById = createSelector(
  getVehiclesTransportFacilityModals,
  (allVehicles) => arrayToObjectByField(allVehicles)
);
const getVehiclesStatus = (state: RootState) => state.vehicles.status;

export const getIsVehiclesLoaded = createSelector(
  getVehiclesStatus,
  (status) => status === DataStatus.succeeded
);
export const getIsVehiclesLoading = createSelector(
  getVehiclesStatus,
  (status) => status === DataStatus.loading
);
export const getIsVehiclesLoadingError = createSelector(
  getVehiclesStatus,
  (status) => status === DataStatus.failed
);
export const getVehiclesByCurrentSemanticMap = createSelector(
  getCurrentSemanticMapId,
  getVehicles,
  (currentSemanticMapId, vehicles) => {
    return vehicles.filter(
      (vehicle) => vehicle.semanticMapId === currentSemanticMapId
    );
  }
);

export const getVehiclesDict = createSelector(getVehicles, (vehicles) =>
  arrayToObjectByField(vehicles)
);

export const getVehicleById = (vehicleId: string | undefined) =>
  createSelector(getVehicles, (vehicles) => {
    if (!vehicles || !vehicleId) return;
    return vehicles.find((vehicle) => vehicle.id === vehicleId);
  });
