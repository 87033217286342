import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getServerErrorMessage } from "Utils/index";
import { ChildrenProps } from "Utils/types";
import { getCurrentSemanticMapId, setShowGlobalLoader } from "Slices/appState";
import { setErrorWarning } from "Slices/warning";
import {
  fetchGetVehicles,
  getIsVehiclesLoaded,
  getIsVehiclesLoadingError,
} from "Slices/vehicles";
import {
  fetchGetVehicleTypes,
  getIsVehicleTypesLoaded,
} from "Slices/vehicleTypes";
import {
  fetchGetIntersections,
  fetchGetIntersectionIdsFromSemanticMap,
  getSematicIntersectionMapping,
  getIsSematicIntersectionMapLoaded,
} from "Slices/intersections";
import { useStopsLoader } from "Utils/loaders";

const useHandleDataLoadingErrors = () => {
  const dispatch = useDispatch();
  const isVehiclesLoadingError = useSelector(getIsVehiclesLoadingError);

  useEffect(() => {
    if (isVehiclesLoadingError) {
      const problemData = `
       ${isVehiclesLoadingError ? "VEHICLES" : ""}
      `.trim();

      dispatch(setShowGlobalLoader(false));
      dispatch(setErrorWarning(getServerErrorMessage(problemData)));
    }
  }, [isVehiclesLoadingError]);
};

export const IntersectionsLoader = ({ children }: ChildrenProps) => {
  const dispatch = useDispatch();
  const currentSemanticMapId = useSelector(getCurrentSemanticMapId);
  const isVehiclesLoaded = useSelector(getIsVehiclesLoaded);
  const isVehicleTypesLoaded = useSelector(getIsVehicleTypesLoaded);
  const isSemanticIntersectionMapLoaded = useSelector(
    getIsSematicIntersectionMapLoaded
  );
  const semanticIntersectionMapping = useSelector(
    getSematicIntersectionMapping
  );

  useHandleDataLoadingErrors();

  const { isStopsLoaded, isStopsLoadingError } =
    useStopsLoader(currentSemanticMapId);

  // Get /intersections
  useEffect(() => {
    if (!isSemanticIntersectionMapLoaded) {
      dispatch(fetchGetIntersectionIdsFromSemanticMap());
    }
  }, [isSemanticIntersectionMapLoaded]);

  useEffect(() => {
    if (!isStopsLoadingError) return;

    dispatch(setShowGlobalLoader(false));
    dispatch(setErrorWarning(getServerErrorMessage("STOPS")));
  }, [isStopsLoadingError]);

  // stops loading
  useEffect(() => {
    if (isStopsLoaded) return;
    dispatch(setShowGlobalLoader(true));
  }, [isStopsLoaded]);

  // GET /vehicles
  useEffect(() => {
    if (isVehiclesLoaded) return;
    dispatch(setShowGlobalLoader(true));
    dispatch(fetchGetVehicles());
  }, [isVehiclesLoaded]);

  // GET /vehicleTypes
  useEffect(() => {
    if (isVehicleTypesLoaded) return;
    dispatch(setShowGlobalLoader(true));
    dispatch(fetchGetVehicleTypes());
  }, [isVehicleTypesLoaded]);

  // GET entry permission by the intersection ids
  useEffect(() => {
    if (currentSemanticMapId && semanticIntersectionMapping) {
      const intersectionIds = semanticIntersectionMapping[currentSemanticMapId]
        ? Object.keys(semanticIntersectionMapping[currentSemanticMapId]).map(
            (each) => parseInt(each)
          )
        : [];
      dispatch(fetchGetIntersections(intersectionIds));
    }
  }, [currentSemanticMapId, semanticIntersectionMapping]);

  // turn the loader off
  useEffect(() => {
    if (isStopsLoaded && isVehiclesLoaded && isVehicleTypesLoaded) {
      dispatch(setShowGlobalLoader(false));
    }
  }, [isStopsLoaded, isVehiclesLoaded]);

  return <>{children}</>;
};
