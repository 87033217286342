import styled from "styled-components";

export const QueueManagerTableHeaderStyled = styled.div`
  display: flex;
  border-bottom: 1px solid #e9e9e9;
`;

export const ColumnTitleStyled = styled.div`
  display: flex;
  padding: 12px 16px;
  flex: 1 0 0;
  color: #1b1b1b;
  font-family: "Readex Pro", sans-serif;
  font-size: 12px;
  line-height: 130%;
`;
