import React, { ReactNode, FC } from "react";

import { SubmitButtonStyled } from "./styles";

type SubmitButtonProps = {
  children?: ReactNode;
};

export const SubmitButton: FC<SubmitButtonProps> = ({ children }) => (
  <SubmitButtonStyled>{children}</SubmitButtonStyled>
);
