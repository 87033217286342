import styled from "styled-components";
interface DetailToggleIconProps {
  active: boolean;
}

interface IntersectionItemWrapperProps {
  standAlone?: boolean;
}
interface IntersectionDescProps {
  isAction?: boolean;
}

export const IntersectionsListStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  width: 350px;
  overflow-y: auto;
`;

export const SemanticMapPickerBodyStyled = styled.div`
  padding: 16px 18px;
  border-bottom: 1px solid #e9e9e9;
`;

export const IntersectionListBodyStyled = styled.div`
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const IntersectionItemWrapperStyled = styled.div<IntersectionItemWrapperProps>`
  padding: 16px 0;
  border-bottom: ${(props) => (props.standAlone ? "none" : "1px solid #000")};
  font-size: ${(props) => (props.standAlone ? "16px" : "14px")};
`;

export const IntersectionItemColumnsStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

export const IntersectionItemDetailToggleStyled = styled.div<DetailToggleIconProps>`
  padding-top: 8px;
  min-width: 80px;

  svg {
    cursor: pointer;
    min-width: 32px;
    transform: rotate(0deg)
      ${(props) => (props.active ? "rotateX(0deg);" : "rotateX(180deg)")};
  }
`;

export const IntersectionExpandDetailStyled = styled.div`
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const IntersectionDescStyled = styled.div<IntersectionDescProps>`
  color: ${(props) => (props.isAction ? "#606060;" : "#1b1b1b")};
`;

export const IntersectionSubTitleStyled = styled.div`
  font-weight: 600;
`;

export const IntersectionCancelActionStyled = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  padding: 4px 4px 4px 8px;
  border: 2px solid #df2020;
  color: #861313;
  cursor: pointer;
`;

export const ImageStyled = styled.img`
  display: flex;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-right: 16px;
  border-radius: 5px;
  object-fit: contain;
`;
