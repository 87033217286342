import styled from "styled-components";

export const SuccessContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 32px;
  height: calc(100% - 80px);
`;

export const GeneratedMissionsHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  color: #1b1b1b;
  font-size: 20px;
  font-weight: 700;
  box-sizing: border-box;
  padding: 24px 0;
`;

export const MissionsItemStyled = styled.div`
  display: flex;
  justify-content: space-between;
  color: #1b1b1b;
  font-size: 16px;
  font-weight: 400;
  box-sizing: border-box;
  padding: 8px 0;
  width: 100%;
  min-height: 64px;
`;

export const MissionsContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 415px);
  width: 100%;
  overflow-y: auto;
`;
