import styled from "styled-components";

export const VehiclesPickerPaddingStyled = styled.div`
  padding-left: 32px;
  box-shadow: rgb(149 157 165 / 20%) 0 8px 20px 0;
`;

export const VehiclesPickerTitleStyled = styled.div`
  display: flex;
  align-items: center;
  height: 72px;
  font-family: "Readex Pro", sans-serif;
  font-size: 20px;
  color: #172a3f;
  cursor: default;
`;

export const VehiclesPickerBodyStyled = styled.div`
  max-height: 312px;
  overflow-y: auto;
`;
