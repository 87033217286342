import styled from "styled-components";

export const QueueManagerTableBodyStyled = styled.div`
  height: calc(100% - 72px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
`;
