import styled from "styled-components";

import CloseIcon from "./assets/closeIcon.svg";

export const VersionsModalCopyBtnStyled = styled.div.attrs({
  type: "primary",
})`
  margin-bottom: 0;
  height: 40px;
  background-color: #253f73;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  color: #ffffff;
  opacity: 1;
  transition: opacity 0.2s;
  font-size: 14px;
  margin-top: 16px;

  &:hover {
    opacity: 0.8;
  }
`;

export const VersionsModalContentStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 240px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  background-color: #ffffff;
  box-sizing: border-box;
  color: #0e152f;
  z-index: 1;
  border-radius: 4px;
  padding: 16px 40px;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;

  strong {
    margin-left: 4px;
  }
`;

export const VersionsModalLineStyled = styled.div`
  display: flex;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }
`;

export const VersionsModalHeaderStyled = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;
  color: #0e152f;
`;

export const VersionsModalCloseIconStyled = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  background: url(${CloseIcon});
  z-index: 12;
  box-sizing: border-box;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }
`;
