import React from "react";

import {
  LoaderContainerStyled,
  LoaderStyled,
  MissionPlannerLoaderContainerStyled,
  AsideLoaderContainerStyled,
  WebsocketLoaderContainerStyled,
  LiveQueuePageLoaderContainerStyled,
  AutoGenerationDeploymentContainerStyled,
  MissionOperatorLoaderContainerStyled,
  WebsocketLoaderMainStyled,
} from "./styles";

export const Loader = () => {
  return (
    <LoaderContainerStyled data-testid="loader">
      <LoaderStyled />
    </LoaderContainerStyled>
  );
};

export const AllDeploymentsLoaderSpinner = () => {
  return (
    <MissionPlannerLoaderContainerStyled data-testid="mission-planner-loader-container">
      <LoaderStyled data-testid="loader" />
    </MissionPlannerLoaderContainerStyled>
  );
};

export const LiveQueuePageLoader = () => {
  return (
    <LiveQueuePageLoaderContainerStyled data-testid="live-queue-loader-container">
      <LoaderStyled data-testid="loader" />
    </LiveQueuePageLoaderContainerStyled>
  );
};

export const AsideLoader = () => {
  return (
    <AsideLoaderContainerStyled data-testid="aside-loader-container">
      <LoaderStyled data-testid="loader" />
    </AsideLoaderContainerStyled>
  );
};

export const WebsocketLoader = () => {
  return (
    <WebsocketLoaderMainStyled data-testid="websocket-loader-main">
      <WebsocketLoaderContainerStyled data-testid="websocket-loader-container">
        <LoaderStyled data-testid="loader" />
      </WebsocketLoaderContainerStyled>
    </WebsocketLoaderMainStyled>
  );
};

export const AutoGenerationDeploymentLoader = () => {
  return (
    <AutoGenerationDeploymentContainerStyled data-testid="auto-generation-loader-container">
      <LoaderStyled data-testid="loader" />
    </AutoGenerationDeploymentContainerStyled>
  );
};

export const MissionOperatorLoader = () => {
  return (
    <MissionOperatorLoaderContainerStyled data-testid="mission-operator-loader-container">
      <LoaderStyled data-testid="loader" />
    </MissionOperatorLoaderContainerStyled>
  );
};
