import styled from "styled-components";

export const ImgStyled = styled.img`
  display: flex;
  align-items: center;
  width: 120px;
  height: 120px;
  margin-right: 16px;
  border-radius: 5px;
  object-fit: contain;
`;

export const VehicleImageContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 50px;
  height: 50px;
  margin-right: 16px;
  background: #c4c4c4;
  border-radius: 5px;
`;

export const MissionPlannerStyled = styled.div`
  position: fixed;
  top: 71px;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
`;

export const MissionPlannerBackgroundStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  background: rgba(37, 63, 115, 0.5);
`;

export const SpinnerWrapperStyled = styled.div`
  position: absolute;
  top: 1px;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
`;

export const MissionPlannerHeadContentStyled = styled.div`
  box-sizing: border-box;
  padding: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const MissionPlannerHeadInfoStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const MissionPlannerHeadLeftStyled = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;

export const MissionPlannerLabelStyled = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-weight: normal;
  display: flex;
  align-items: flex-end;
  font-size: 16px;
  line-height: 120%;
  margin-bottom: 2px;
  letter-spacing: 0.005em;
`;

export const MissionPlannerInfoLabelStyled = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #687684;
  margin-bottom: 10px;
`;

export const MissionPlannerRouteNameStyled = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
  margin-bottom: 8px;
`;

export const DeployedMissionListBodyStyled = styled.div`
  position: relative;
  flex: 1;
`;

export const MissionPlannerContainerStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  height: 100%;
  background: #ffffff;
  overflow-y: auto;
  display: flex;
`;

interface MissionPlannerProps {
  withHeader?: boolean;
}

export const MissionPlannerContentStyled = styled.div<MissionPlannerProps>`
  height: ${(props) => (props.withHeader ? "calc(100% - 72px)" : "100% ")};
  background: #ffffff;
  box-sizing: border-box;
  width: 650px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const MissionPlannerHeaderStyled = styled.div`
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.005em;
  margin-bottom: 16px;
`;
