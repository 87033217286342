import styled from "styled-components";

export const AddActionContainerStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 44px;
  justify-content: space-between;
`;

export const AddActionDataStyled = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
interface AddActionItemProps {
  width?: number;
}

export const AddActionItemStyled = styled.div<AddActionItemProps>`
  width: ${(props) => (props.width ? `${props.width}px` : "140px")};
  display: flex;
  align-items: center;
  height: 100%;
`;

export const DropdownStyled = styled.div`
  width: 100%;
  position: relative;
`;

interface DropdownButtonProps {
  active: boolean;
  disabled?: boolean;
}

export const DropdownButtonStyled = styled.div<DropdownButtonProps>`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  font-size: 16px;
  padding: 8px;
  color: rgb(96, 96, 96);
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};

  svg {
    margin-left: 8px;
    transform: ${(props) =>
      props.active ? "rotateX(180deg)" : "rotateX(0deg)"};
    transition: transform 0.3s;
    width: 16px;
    height: 16px;
    visibility: ${(props) => (props.disabled ? "hidden" : "visible")};

    path {
      fill: rgb(96, 96, 96);
    }
  }
`;

export const DropdownContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  box-sizing: border-box;
  top: 40px;
  width: 100%;
  left: 0;
  z-index: 1;
  border-top: 2px solid rgb(85, 184, 179);
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
  max-height: 271px;
  overflow-y: auto;
`;

export const DurationDropdownContentStyled = styled(DropdownContentStyled)`
  overflow-y: visible;
`;

export const ActionsTableDropdownItemStyled = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  font-size: 16px;
  border-bottom: 1px solid rgb(85, 184, 179);
  padding: 8px;
  cursor: pointer;
  transition: all 0.3s;
  opacity: 1;
  flex-shrink: 0;

  &:hover {
    opacity: 0.7;
  }
`;

export const InputStyled = styled.input`
  font-size: 16px;
  height: 100%;
  padding: 12px 16px;
  min-width: 140px;
  box-sizing: border-box;
  border: 2px solid;
  border-color: transparent;
  color: rgb(96, 96, 96);

  ::placeholder {
    color: #c4c4c4;
  }

  &:focus {
    outline: none;
  }
`;
