import React, { memo } from "react";

import { AutoMissionGenerator } from "Components/AutoMissionGeneratorPage/AutoMissionGeneratorPage";

import { AutoMissionGeneratorLoader } from "./AutoMissionGeneratorPage.loader";

export const AutoMissionGeneratorPage = memo(() => (
  <AutoMissionGeneratorLoader>
    <AutoMissionGenerator />
  </AutoMissionGeneratorLoader>
));
