import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import API, { showNetworkErrorToast } from "Utils/api";

import { setIsLiveQueueDeploymentsUpdating, setLiveQueueDeployments } from ".";
import { DeploymentsValidator } from "Slices/validationTypes";
import { setQueueDeploymentsSection } from "Slices/queueDeploymentsSection";

export const fetchLiveQueueDeployment = createAsyncThunk<void, string>(
  "fetchLiveQueueDeployment",
  async (currentFleetId, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLiveQueueDeploymentsUpdating(true));

      const queryStatuses = "&statuses=new,sent,in_progress,paused,received";
      const queryParameters = `?fleetId=${currentFleetId}${queryStatuses}`;
      const response = await API.get(`live_deployments/${queryParameters}`);

      const parsedLiveQueueDeployments = DeploymentsValidator.parse(
        response.data.results
      );
      thunkAPI.dispatch(setIsLiveQueueDeploymentsUpdating(false));
      thunkAPI.dispatch(setLiveQueueDeployments(parsedLiveQueueDeployments));
      thunkAPI.dispatch(setQueueDeploymentsSection(parsedLiveQueueDeployments));
    } catch (error) {
      thunkAPI.dispatch(setIsLiveQueueDeploymentsUpdating(false));

      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

export const cancelLiveQueueDeployment = createAsyncThunk<
  void,
  { deploymentId: string; deploymentName: string }
>(
  "cancelLiveQueueMissionDeployment",
  async ({ deploymentId, deploymentName }) => {
    try {
      const loadingToastId = toast.loading(
        `Deployment ${deploymentName} cancelling...`
      );
      await API.post(`mission_deployment/${deploymentId}/cancel/`);
      toast.update(loadingToastId, {
        render: "Deployment successfully canceled",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error) {
      toast.dismiss();
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);
