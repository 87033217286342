import styled from "styled-components";
import ChangeStationImage from "Components/OperatorSidePanel/assets/change-station-background.svg";

export const OperatorSidePanelWrapperStyled = styled.div`
  width: 544px;
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  cursor: default;
  overflow-y: auto;
  border-right: 1px solid #d1d1d1;
`;

export const MissionOperatorBoardStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
`;

export const PickerPaddingStyled = styled.div`
  padding: 32px 32px 21px;
  background: #f4f4f4;
  margin-bottom: 32px;
`;

export const LoadingStyled = styled.div`
  height: 41px;
`;

export const InteractionPaneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 480px;
  height: 350px;
  clip-path: polygon(97.5% 0, 100% 3%, 100% 100%, 2.5% 100%, 0 97%, 0 0);
  background: #f4f4f4;
  background-image: url(${ChangeStationImage});
  border: 1px solid #e9e9e9;
  padding: 32px;
  font-family: "Readex Pro", sans-serif;
  font-weight: 500;
  color: #606060;
`;

export const EmptyErrorStyled = styled.div`
  color: red;
`;
