import { v4 as uuidv4 } from "uuid";
import { FMSLogger as FMSLoggerClass } from "./FMSLogger";
import {
  addListenerForUnhandledErrors,
  addListenerToDownloadLogs,
} from "./utils";

const LOGGER_KEY = "FMSLogger";

declare global {
  interface Window {
    [LOGGER_KEY]?: FMSLoggerClass;
  }
}

const getLoggerSingleton = (): FMSLoggerClass => {
  if (typeof window[LOGGER_KEY] === "undefined") {
    const logger = new FMSLoggerClass(uuidv4());
    window[LOGGER_KEY] = logger;
    addListenerToDownloadLogs(logger);
    addListenerForUnhandledErrors(logger);
  }
  return window[LOGGER_KEY];
};

export const FMSLogger = getLoggerSingleton();
