import { createSlice } from "@reduxjs/toolkit";
import { fetchOperatorMissionPlans } from ".";
import { DataStatus } from "../../types";

type OperatorMissionPlansState = {
  missionPlansByStopId: Record<string, MissionPlan> | null;
  status: DataStatus;
  error: string | null;
};

const initialState: OperatorMissionPlansState = {
  missionPlansByStopId: null,
  status: DataStatus.idle,
  error: null,
};

export const Slice = createSlice({
  name: "operatorMissionPlans",
  initialState,
  reducers: {
    resetOperatorMissionPlans: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOperatorMissionPlans.pending, (state) => {
        state.status = DataStatus.loading;
        state.error = null;
      })
      .addCase(fetchOperatorMissionPlans.fulfilled, (state, action) => {
        state.status = DataStatus.succeeded;
        state.missionPlansByStopId = action.payload.reduce(
          (acc: Record<string, MissionPlan>, curr: MissionPlan) => {
            acc[curr?.plan.actions[0].stopPk] = curr;
            return acc;
          },
          {}
        );
      })
      .addCase(fetchOperatorMissionPlans.rejected, (state, action) => {
        state.status = DataStatus.failed;
        state.error = action.error.message ?? "";
      });
  },
});

export default Slice;
