import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { RouterProvider } from "react-router-dom";
import { store } from "./store";

import "./index.css";
import { router } from "./routers/MainRouter";
import "FMSLogger";

const SENTRY_URL = window._env_.REACT_APP_SENTRY_URL;
const GOOGLE_CLIENT_ID = window._env_.REACT_APP_GOOGLE_CLIENT_ID;

if (SENTRY_URL) {
  Sentry.init({
    dsn: SENTRY_URL,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: window._env_.REACT_APP_ENV || "development",
  });
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </GoogleOAuthProvider>
);
