import React, { FC, memo } from "react";
import { useSelector } from "react-redux";

import { getMissionStatusData } from "Components/common/MissionStatuses/MissionStatuses";
import { EMPTY_ICON } from "Utils";
import { parseDateTime } from "../../utils";

import { getVehicleTypeById } from "Slices/vehicleTypes";

import { ReactComponent as RectangleIcon } from "../../assets/rectangleIcon.svg";
import { ReactComponent as CloseIcon } from "../../assets/closeIcon.svg";
import {
  DeploymentDetailsHeaderWrapperStyled,
  DeploymentDetailBodyStyled,
  DeploymentNameStyled,
  AdditionalDeploymentInfoBodyStyled,
  FieldValueStyled,
  RectangleIconBodyStyled,
  StatusBodyStyled,
  StatusNameStyled,
  AssignedVehicleBodyStyled,
  VehicleIconStyled,
  VehicleNameStyled,
  CloseModalButtonStyled,
} from "./styles";

type DeploymentDetailsHeaderProps = {
  deployment: Deployment | LimitedDeployment;
  vehicle: Vehicle | undefined;
  handleCloseAction: () => void;
};

export const DeploymentDetailsHeader: FC<DeploymentDetailsHeaderProps> = memo(
  ({ deployment, vehicle, handleCloseAction }) => {
    const { icon, statusName } = getMissionStatusData(
      deployment?.vehicleDeployment?.status ?? "NEW"
    );

    const parsedDateTime = deployment?.vehicleDeployment?.deployedAt
      ? parseDateTime(deployment?.vehicleDeployment?.deployedAt)
      : "Invalid Date";
    const parseMissionSource = () => {
      if (!deployment.vehicleDeployment) return "FMS";
      return (
        deployment?.vehicleDeployment?.missionSource || "No Mission Source"
      );
    };

    const vehicleType = useSelector(getVehicleTypeById(vehicle?.vehicleTypeId));

    return (
      <DeploymentDetailsHeaderWrapperStyled>
        <DeploymentDetailBodyStyled>
          <StatusBodyStyled>
            {icon}
            <StatusNameStyled>{statusName}</StatusNameStyled>
          </StatusBodyStyled>
          <DeploymentNameStyled>{deployment.name}</DeploymentNameStyled>
          <AdditionalDeploymentInfoBodyStyled>
            {deployment.vehicleDeployment && (
              <>
                Deployed at:
                <FieldValueStyled>{parsedDateTime}</FieldValueStyled>
                <RectangleIconBodyStyled>
                  <RectangleIcon />
                </RectangleIconBodyStyled>
              </>
            )}
            Mission Source:
            <FieldValueStyled>{parseMissionSource()}</FieldValueStyled>
          </AdditionalDeploymentInfoBodyStyled>
          <AssignedVehicleBodyStyled>
            Assigned to:
            {vehicle?.name && (
              <VehicleIconStyled src={vehicleType?.image?.link || EMPTY_ICON} />
            )}
            <VehicleNameStyled>
              {vehicle?.name || "No assigned vehicle"}
            </VehicleNameStyled>
          </AssignedVehicleBodyStyled>
        </DeploymentDetailBodyStyled>
        <CloseModalButtonStyled onClick={handleCloseAction}>
          Close <CloseIcon />
        </CloseModalButtonStyled>
      </DeploymentDetailsHeaderWrapperStyled>
    );
  }
);
