import styled from "styled-components";

export const SubmitButtonStyled = styled.button.attrs({ type: "submit" })`
  padding: 12px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 8px;
  border-radius: 8px;
  background: #005ff9;
  font-weight: 400;
  line-height: 130%;
  text-transform: uppercase;
  font-size: 16px;
  color: #ffffff;
  border: none;
  margin-bottom: 8px;
  cursor: pointer;
  opacity: 1;
  transition: all 0.3s;

  &:hover {
    opacity: 0.8;
  }
`;
