import { fetchLiveQueueDeployment, cancelLiveQueueDeployment } from "./actions";
import {
  getLiveQueueDeploymentsPageFleetId,
  getLiveQueueDeployments,
  getActiveLiveQueueDeploymentByVehicleId,
  getLiveDeployments,
  getLiveQueueDeploymentsById,
  getIsLiveQueueDeploymentsUpdating,
} from "./selectors";
import LiveQueueDeploymentsSlice from "./reducers";

// actions
export const {
  setLiveQueueDeployments,
  setLiveQueueDeploymentsPageFleetId,
  updateLiveQueueDeployments,
  resetLiveQueueDeploymentsPageData,
  setIsLiveQueueDeploymentsUpdating,
} = LiveQueueDeploymentsSlice.actions;

// async actions
export { fetchLiveQueueDeployment, cancelLiveQueueDeployment };

// selectors
export {
  getLiveQueueDeploymentsPageFleetId,
  getLiveQueueDeployments,
  getActiveLiveQueueDeploymentByVehicleId,
  getLiveDeployments,
  getLiveQueueDeploymentsById,
  getIsLiveQueueDeploymentsUpdating,
};

// reducer
export default LiveQueueDeploymentsSlice.reducer;
