import React, { FC, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getActionName, getDiffTime } from "Utils";
import { getStopsByCurrentSemanticMapDict } from "Slices/stops";
import { ADD_EDIT_MISSION_MODAL, toggleModal } from "Slices/modals";
import { sortCollectionByNumberField } from "Utils/collectionUtils";
import { FMSLogger } from "FMSLogger";
import { MISSION_DEPLOYMENT_ACTIONS } from "Utils/constants";

import {
  MissionItemStyled,
  MissionItemContentStyled,
  MissionItemDeployBtnStyled,
  MissionItemColumnStyled,
  MissionItemColumnHeadStyled,
  MissionItemLastDeployStyled,
  MissionItemDetailsStyled,
  MissionItemAdditionHeadStyled,
  MissionItemAdditionTextStyled,
  MissionItemButtonsStyled,
  MissionItemButtonStyled,
} from "./styles";

const missionDeploymentActionsLogger = FMSLogger.byPrefix(
  MISSION_DEPLOYMENT_ACTIONS
);

type MissionPlanItemProps = {
  missionData: MissionPlan;
  deletePlanMissionAction: (id: string) => void;
  savedMissionsDisabled: boolean;
  deployAction: VoidFunction;
  currentFleetId: string;
};

export const MissionPlanItem: FC<MissionPlanItemProps> = ({
  missionData,
  deletePlanMissionAction,
  savedMissionsDisabled,
  deployAction,
  currentFleetId,
}) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const stopsObject = useSelector(getStopsByCurrentSemanticMapDict);
  const { name, id, plan, timeStamp } = missionData;
  const { actions } = plan;

  const currentActions = actions.length ? actions : null;

  const sortedCurrentActions = useMemo(
    () => sortCollectionByNumberField(currentActions, "order"),
    [currentActions]
  );

  const handleDeployAction = () => {
    missionDeploymentActionsLogger.debug(
      `mission item deploy action for mission: ${id}`
    );
    deployAction();
  };

  const editAction = () => {
    missionDeploymentActionsLogger.debug(
      `mission item edit action for mission: ${id}`
    );
    dispatch(
      toggleModal({
        type: ADD_EDIT_MISSION_MODAL,
        data: { ...missionData, fleetId: currentFleetId },
      })
    );
  };

  const deleteAction = () => {
    missionDeploymentActionsLogger.debug(
      `mission item delete action for mission: ${id}`
    );
    deletePlanMissionAction(id);
  };

  return (
    <MissionItemStyled isOpen={isOpen}>
      <MissionItemContentStyled>
        <MissionItemColumnStyled>
          <MissionItemColumnHeadStyled>{name}</MissionItemColumnHeadStyled>
          {timeStamp && (
            <MissionItemLastDeployStyled>
              last deployed
              <strong>{getDiffTime(String(new Date(timeStamp)))}</strong>
            </MissionItemLastDeployStyled>
          )}
          <MissionItemDetailsStyled
            onClick={() => {
              missionDeploymentActionsLogger.debug(
                `toggle mission details for mission ${id}`
              );
              setIsOpen(!isOpen);
            }}
          >
            Mission Details {">"}
          </MissionItemDetailsStyled>
        </MissionItemColumnStyled>
        <MissionItemDeployBtnStyled
          disabled={savedMissionsDisabled}
          onClick={handleDeployAction}
        >
          DEPLOY
        </MissionItemDeployBtnStyled>
      </MissionItemContentStyled>
      {isOpen && (
        <MissionItemContentStyled>
          <MissionItemColumnStyled>
            <MissionItemAdditionHeadStyled>
              Stops Planned
            </MissionItemAdditionHeadStyled>
            {(sortedCurrentActions as Array<MissionAction>)?.map(
              ({ name, stopPk, type, duration }, index) => (
                <MissionItemAdditionTextStyled key={`${name}-${index}`}>
                  {getActionName(type, stopsObject[stopPk].name, duration)}
                </MissionItemAdditionTextStyled>
              )
            )}
            <MissionItemButtonsStyled>
              <MissionItemButtonStyled onClick={editAction} isEdit={true}>
                Edit Mission
              </MissionItemButtonStyled>
              <MissionItemButtonStyled onClick={deleteAction}>
                Delete Mission
              </MissionItemButtonStyled>
            </MissionItemButtonsStyled>
          </MissionItemColumnStyled>
        </MissionItemContentStyled>
      )}
    </MissionItemStyled>
  );
};
