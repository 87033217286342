import React, { memo, useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getVehicleTypesTransportFacilityModals } from "Slices/vehicleTypes";
import { getFleetsTransportFacilityModals, getFleetsById } from "Slices/fleets";
import {
  getModalData,
  closeAnyModal,
  toggleModal,
  CONFIRM_CHANGE_VECHICLE_MODAL,
} from "Slices/modals";
import { fetchPostVehicles, fetchPatchVehicle } from "Slices/vehicles";
import { setWarning } from "Slices/warning";
import { getVehiclesDict } from "Slices/vehicles/selectors";
import { useIsFieldChanged } from "Utils/hooks";

import {
  ModalBackgroundStyled,
  ModalStyled,
} from "../../../common/ModalComponent/styles";
import { Input } from "../components/Input";
import { ModalWrapper } from "../components/ModalWrapper";
import { Dropdown } from "../components/Dropdown";

const CHANGE_FLEET_WARNING_MESSAGE =
  "You are reassigning this vehicle to a different fleet. In order to complete this change, please fully restart the vehicle";

export const VehicleModal = memo(() => {
  const dispatch = useDispatch();
  const modalData = useSelector(getModalData);
  const vehiclesDict = useSelector(getVehiclesDict);
  const fleetsById = useSelector(getFleetsById);
  const editedVehicle = modalData ? vehiclesDict[modalData?.vehicleId] : null;
  const fleetsItems = useSelector(getFleetsTransportFacilityModals);
  const vehicleTypesItems = useSelector(getVehicleTypesTransportFacilityModals);
  const [name, setName] = useState<string>(editedVehicle?.name || "");
  const [licensePlate, setLicensePlate] = useState<string>(
    editedVehicle?.licensePlate || ""
  );
  const [vehicleType, setVehicleType] = useState<ModalItem | null>(
    vehicleTypesItems.find(({ id }) => id === editedVehicle?.vehicleTypeId) ||
      vehicleTypesItems[0]
  );
  const [fleet, setFleet] = useState<ModalItem | null>(
    fleetsItems.find(({ id }) => id === editedVehicle?.fleetId) ||
      fleetsItems[0]
  );
  const isFieldsChanged = useIsFieldChanged([name, licensePlate, fleet]);
  const isFormFilled = name && vehicleType && fleet;
  const isDisabled = editedVehicle ? !isFieldsChanged : !isFormFilled;
  const currentSemanticMapId =
    fleetsById[(fleet as ModalItem).id].semanticMapId;

  useEffect(() => {
    if (isFieldsChanged) {
      dispatch(setWarning(CHANGE_FLEET_WARNING_MESSAGE));
    }
  }, [fleet]);

  const handleCloseAction = () => {
    dispatch(closeAnyModal());
  };

  const setVehicleFieldsAction = useCallback(() => {
    if (isDisabled) return;
    const data = {
      name,
      licensePlate,
      vehicleTypeId: vehicleType?.id || "",
      fleetId: fleet?.id || "",
      semanticMapId: currentSemanticMapId,
    };
    dispatch(
      modalData
        ? fetchPatchVehicle({
            id: modalData.vehicleId as string,
            data,
          })
        : fetchPostVehicles(data)
    );
    handleCloseAction();
  }, [isDisabled, name, licensePlate, vehicleType, fleet]);

  const handlePatchVehicleWithConfirmation = useCallback(() => {
    dispatch(
      toggleModal({
        type: CONFIRM_CHANGE_VECHICLE_MODAL,
        data: {
          headerText: "Attention!",
          message:
            "You have unsaved changes on your vehicle. Would you like to save them before leaving?",
          confirmButtonText: "Save",
          cancelButtonText: "Discard Changes",
          confirmAction: () => {
            setVehicleFieldsAction();
            handleCloseAction();
          },
          cancelAction: () => {
            handleCloseAction();
          },
        },
      })
    );
  }, [isDisabled, name, licensePlate, vehicleType, fleet]);

  const handleCloseActionGeneralModal = useCallback(() => {
    if (isFieldsChanged) {
      handlePatchVehicleWithConfirmation();
    } else {
      handleCloseAction();
    }
  }, [isFieldsChanged, name, licensePlate, vehicleType, fleet]);

  return (
    <ModalStyled>
      <ModalBackgroundStyled onClick={handleCloseActionGeneralModal} />
      <ModalWrapper
        headerText={`${modalData ? "Edit" : "Add"} Vehicle`}
        actionButtonText={`${modalData ? "Save" : "Add Vehicle"}`}
        successAction={setVehicleFieldsAction}
        isActionButtonDisabled={isDisabled}
      >
        <Input
          value={name}
          labelText="Name"
          additionalInfo="Please enter vehicle name"
          changeAction={setName}
          isRequired={true}
        />
        <Input
          value={licensePlate}
          labelText="VIN #"
          additionalInfo="Please enter vehicle VIN #"
          changeAction={setLicensePlate}
        />
        {editedVehicle ? (
          <Input
            value={vehicleType?.text || "No Data"}
            labelText="Type"
            disabled={true}
          />
        ) : (
          <Dropdown
            currentValue={vehicleType}
            labelText="Type"
            additionalInfo="Please select vehicle type"
            changeAction={setVehicleType}
            options={vehicleTypesItems}
            isRequired={true}
          />
        )}
        <Dropdown
          currentValue={fleet}
          labelText="Fleet"
          options={fleetsItems}
          additionalInfo="Please select vehicle fleet"
          changeAction={setFleet}
          isRequired={true}
        />
        {editedVehicle && (
          <>
            <Input
              value={editedVehicle.driveModeVersion || "No Data"}
              labelText="DriveMod Version"
              disabled={true}
            />
            <Input
              value={editedVehicle.confFileCode || "No Data"}
              labelText="Configuration File Code"
              disabled={true}
            />
            <Input
              value={editedVehicle.lastDataCollection || "No Data"}
              labelText="Last Data Collection Upload from Vehicle"
              disabled={true}
            />
          </>
        )}
      </ModalWrapper>
    </ModalStyled>
  );
});
