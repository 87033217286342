import React, { memo, FC, useMemo, useRef, useCallback } from "react";
import { toast } from "react-toastify";

import { IconLoaderProps } from "../../types";
import { prettifyFileWeight } from "./utils";
import { DragAndDropFileHOC } from "../../../../common/DragAndDropFileHOC";
import { EMPTY_ICON } from "Utils";
import { acceptableIconLoaderFormats } from "../constants";

import { ReactComponent as EditIcon } from "../../assets/editIcon.svg";
import { ReactComponent as DeleteIcon } from "Components/common/assets/deleteIcon.svg";
import {
  IconUpdateBodyStyled,
  FileNameStyled,
  FileInputStyled,
  FileWeightStyled,
  FileInfoStyled,
  ButtonStyled,
  GroupButtonsStyled,
  FilePreviewStyled,
  ImageStyled,
} from "./styles";

export const EditIconLoader: FC<IconLoaderProps> = memo(
  ({ setImageFile, imageFile, imageData }) => {
    const fileInputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

    const handleEditImage = useCallback(() => {
      fileInputRef.current.click();
    }, []);
    const handlerLoadIcon = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setImageFile(event.target.files?.[0] || null);
      },
      []
    );

    const imageUrl = useMemo(() => {
      if (!imageFile) return imageData?.link ?? null;
      try {
        return URL.createObjectURL(imageFile as Blob);
      } catch {
        toast.error("Failed to load image");
        setImageFile(null);
        return null;
      }
    }, [imageFile]);

    const fileName = imageFile
      ? imageFile.name
      : imageData?.meta?.fileName || "unknown file name";
    const fileWeight = imageFile
      ? imageFile.size || 0
      : (imageData?.meta?.fileSize as number) || 0;

    return (
      <DragAndDropFileHOC
        setFile={setImageFile}
        acceptableFileFormats={acceptableIconLoaderFormats}
        errorMessage="Invalid image format"
      >
        {(isDragActive) => (
          <IconUpdateBodyStyled isDragActive={isDragActive}>
            <FilePreviewStyled>
              {imageUrl && <ImageStyled src={imageUrl || EMPTY_ICON} />}
            </FilePreviewStyled>
            <FileInfoStyled>
              <FileNameStyled>{fileName}</FileNameStyled>
              <FileWeightStyled>
                {prettifyFileWeight(fileWeight)}
              </FileWeightStyled>
            </FileInfoStyled>
            <GroupButtonsStyled>
              <ButtonStyled onClick={handleEditImage}>
                <FileInputStyled
                  ref={fileInputRef}
                  onChange={handlerLoadIcon}
                  type="file"
                  accept="image/*,.png,.jpg,.svg,"
                />
                <EditIcon />
              </ButtonStyled>
              {imageFile && (
                <ButtonStyled onClick={() => setImageFile(null)}>
                  <DeleteIcon />
                </ButtonStyled>
              )}
            </GroupButtonsStyled>
          </IconUpdateBodyStyled>
        )}
      </DragAndDropFileHOC>
    );
  }
);
