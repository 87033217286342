import styled from "styled-components";

export const HeaderStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const TitleStyled = styled.div`
  font-family: "Readex Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 120%;
  min-width: 250px;
  cursor: default;
`;

export const HeaderControlsStyled = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;
