import { createAsyncThunk } from "@reduxjs/toolkit";
import { z } from "zod";

import API, { showNetworkErrorToast } from "Utils/api";
import { RootState } from "../..";

import { getCurrentSemanticMapId } from "../appState";
import { setStation } from ".";

export const fetchStation = createAsyncThunk(
  "station/fetchStation",
  async (_, thunkAPI) => {
    try {
      const semanticMapId = getCurrentSemanticMapId(
        thunkAPI.getState() as RootState
      );
      const response = await API.get(
        `/users/stations/?semantic_map=${semanticMapId}`
      );

      return OperatorStationValidator.parse(response.data.station);
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

export const fetchPostStation = createAsyncThunk(
  "station/fetchPostStation",
  async (data: { stopId: string }, thunkAPI) => {
    try {
      const response = await API.post("/users/stations/", {
        stopId: data.stopId,
      });

      thunkAPI.dispatch(setStation(response.data.station));
      return response.data.station;
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

// validators
const OperatorStationValidator = z.object({
  id: z.string().optional(),
  stopId: z.string().optional(),
  semanticMapId: z.string().optional(),
});
