import React, { memo, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FMSLogger } from "FMSLogger";

import { CallVehicleButtonStyled } from "./styles";
import { MissionOperatorLoader } from "Components/common/Loader/Loader";
import { MISSION_OPERATOR_ACTIONS } from "Utils/constants";
import {
  getCurrentOperatorMissionPlan,
  getIsOperatorMissionPlansEmpty,
  getIsOperatorMissionPlansLoaded,
  getIsOperatorMissionPlansLoadingError,
} from "Slices/operatorMissionPlans/selectors";
import { fetchDeployMissionPlan } from "Slices/missionPlans";
import {
  getIsPickedFleetHasNoVehicles,
  getMissionOperatorPickedFleetId,
  getOperatorMissionStatus,
} from "Slices/operatorData";
import { ReactComponent as HornIcon } from "../../assets/horn-icon.svg";
import { EmptyErrorStyled } from "Components/OperatorSidePanel/styles";
import { getOperatorPickedStation } from "Slices/operatorStation";

const missionOperatorLogger = FMSLogger.byPrefix(MISSION_OPERATOR_ACTIONS);

export const CallVehicleButton = memo(() => {
  const dispatch = useDispatch();

  const [isMissionDeploying, setIsMissionDeploying] = useState(false);

  const isMissionPlansLoaded = useSelector(getIsOperatorMissionPlansLoaded);
  const isMissionPlansLoadingError = useSelector(
    getIsOperatorMissionPlansLoadingError
  );
  const isMissionPlansEmpty = useSelector(getIsOperatorMissionPlansEmpty);
  const currentMissionPlan = useSelector(getCurrentOperatorMissionPlan);
  const operatorPickedStation = useSelector(getOperatorPickedStation);
  const missionOperatorPickedFleetId = useSelector(
    getMissionOperatorPickedFleetId
  );
  const operatorMissionStatus = useSelector(getOperatorMissionStatus);
  const isPickedFleetHasNoVehicles = useSelector(getIsPickedFleetHasNoVehicles);
  const pickedStation = useSelector(getOperatorPickedStation);

  const ErrorMessage = useMemo(() => {
    if (isMissionPlansEmpty)
      return <EmptyErrorStyled>Error: no mission plans!</EmptyErrorStyled>;

    if (
      operatorPickedStation &&
      !currentMissionPlan &&
      (isMissionPlansLoaded || isMissionPlansLoadingError)
    )
      return (
        <EmptyErrorStyled>Error: No appropriate mission plan!</EmptyErrorStyled>
      );

    if (isPickedFleetHasNoVehicles)
      return (
        <EmptyErrorStyled>
          Error: There are no vehicles for picked fleet!
        </EmptyErrorStyled>
      );
    return null;
  }, [
    isMissionPlansEmpty,
    currentMissionPlan,
    isMissionPlansLoaded,
    isMissionPlansLoadingError,
    isPickedFleetHasNoVehicles,
  ]);

  const isCallVehicleButtonDisabled =
    isMissionDeploying ||
    !currentMissionPlan ||
    !missionOperatorPickedFleetId ||
    operatorMissionStatus !== "NO_MISSION" ||
    isPickedFleetHasNoVehicles;

  const handleDeployMissionPlan = useCallback(async () => {
    missionOperatorLogger.debug("Call Vehicle button pressed");
    if (!currentMissionPlan || !missionOperatorPickedFleetId) {
      missionOperatorLogger.error(
        "No currentMissionPlan or missionOperatorPickedFleetId when Call Vehicle button pressed"
      );
      return;
    }

    setIsMissionDeploying(true);
    await dispatch(
      fetchDeployMissionPlan({
        ...currentMissionPlan,
        fleetId: missionOperatorPickedFleetId,
        stationId: pickedStation?.stationId,
        missionPlanId: currentMissionPlan.plan.id,
        loops: 1,
        previous: "CONTINUE",
      })
    );
    setIsMissionDeploying(false);
  }, [currentMissionPlan, missionOperatorPickedFleetId, pickedStation]);

  return (
    <>
      {isMissionDeploying && <MissionOperatorLoader />}
      <CallVehicleButtonStyled
        disabled={isCallVehicleButtonDisabled}
        onClick={handleDeployMissionPlan}
      >
        <HornIcon />
        Call Vehicle
      </CallVehicleButtonStyled>
      {ErrorMessage}
    </>
  );
});
