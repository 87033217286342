import { createSlice } from "@reduxjs/toolkit";

type LiveQueueDeploymentsPage = {
  liveQueueDeploymentsPageFleetId: string | null;
  liveQueueDeployments: null | Array<Deployment | LimitedDeployment>;
  isLiveQueueDeploymentsUpdating: boolean;
};

const initialState: LiveQueueDeploymentsPage = {
  liveQueueDeploymentsPageFleetId: null,
  liveQueueDeployments: null,
  isLiveQueueDeploymentsUpdating: false,
};

export const Slice = createSlice({
  name: "liveQueueDeploymentsPage",
  initialState: initialState,
  reducers: {
    resetLiveQueueDeploymentsPageData: () => initialState,
    setIsLiveQueueDeploymentsUpdating: (state, action) => {
      state.isLiveQueueDeploymentsUpdating = action.payload;
    },
    setLiveQueueDeploymentsPageFleetId: (state, action) => {
      state.liveQueueDeploymentsPageFleetId = action.payload;
      return state;
    },
    setLiveQueueDeployments: (state, action) => {
      state.liveQueueDeployments = action.payload;
      return state;
    },
    updateLiveQueueDeployments: (state, action) => {
      const receivedLiveQueueDeployment = action.payload;
      if (!receivedLiveQueueDeployment) return state;

      const isNewLiveQueueDeployment = !state.liveQueueDeployments?.find(
        (liveQueueDeployment) =>
          liveQueueDeployment?.id === receivedLiveQueueDeployment?.id
      );

      if (isNewLiveQueueDeployment) {
        state.liveQueueDeployments = [
          receivedLiveQueueDeployment,
          ...(state.liveQueueDeployments?.length
            ? state.liveQueueDeployments
            : []),
        ];
      } else {
        state.liveQueueDeployments =
          state.liveQueueDeployments?.map((liveQueueDeployment) =>
            liveQueueDeployment.id === receivedLiveQueueDeployment?.id
              ? receivedLiveQueueDeployment
              : liveQueueDeployment
          ) ?? [];
      }
      return state;
    },
  },
});

export default Slice;
