import { memo, useContext, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getActiveTab,
  getMissionPlannerFleetId,
  getMissionPlannerPeriod,
  fetchGetMissionQueue,
  getSelectedVehicleIds,
  updateDeploymentsQueue,
  getSearchValue,
  getMissionRowsLimit,
  getIsMissionsUpdatesPaused,
  getMissionsUpdatesStorage,
  resetMissionsUpdatesStorage,
} from "Slices/allDeploymentsPage";
import { MISSION_PANNER_WS, WS_SPINNER_LOGS } from "Utils/constants";
import { useFMSWebsocket } from "Utils/webSocketUtils";
import { WebsocketLoaderContext } from "Pages/AuthorizedApp/WebsocketLoaderController";
import { FMSLogger } from "FMSLogger";
import { isDeploymentValid } from "Utils";

const wsSpinnerLogs = FMSLogger.byPrefix(`${WS_SPINNER_LOGS}:MissionPlanner`);

export const AllDeploymentsController = memo(() => {
  const missionPlannerFleetId = useSelector(getMissionPlannerFleetId);
  const selectedVehicleIds = useSelector(getSelectedVehicleIds);
  const period = useSelector(getMissionPlannerPeriod);
  const limit = useSelector(getMissionRowsLimit);
  const searchValue = useSelector(getSearchValue);
  const activeTab = useSelector(getActiveTab);
  const isMissionsUpdatesPaused = useSelector(getIsMissionsUpdatesPaused);
  const missionsUpdatesStorage = useSelector(getMissionsUpdatesStorage);
  const dispatch = useDispatch();

  const setIsWSConnecting = useContext(WebsocketLoaderContext);

  useLayoutEffect(
    () => () => {
      wsSpinnerLogs.debug("unmount component spinner off");
      setIsWSConnecting?.(false);
    },
    []
  );

  useEffect(() => {
    if (missionPlannerFleetId) {
      wsSpinnerLogs.debug("new fleet id spinner on");
      setIsWSConnecting?.(true);
    } else {
      wsSpinnerLogs.debug("no fleet id spinner off");
      setIsWSConnecting?.(false);
    }
  }, [missionPlannerFleetId]);

  useFMSWebsocket({
    url: "/fleets/deployment_states/",
    subscriptionData: missionPlannerFleetId,
    loggerFlag: MISSION_PANNER_WS,
    getDataForSubscribe: (subscriptionId) => ({
      fleet_ids: [subscriptionId],
    }),
    getDataForUnsubscribe: (subscriptionId) => ({
      fleet_ids: [subscriptionId],
    }),
    handleInitialMessage: () => {
      wsSpinnerLogs.debug("create message spinner off");
      setIsWSConnecting?.(false);
    },
    handleMessage: (data) => {
      if (data.deployment && !isDeploymentValid(data.deployment)) {
        delete data.deployment;
      }
      const shouldIgnoreUpdate =
        !data.deployment || data.deployment?.state === "STATE_RUNNING";
      if (shouldIgnoreUpdate) return;

      dispatch(updateDeploymentsQueue(data.deployment));
    },
  });

  // get mission queue if filters has been changed
  useEffect(() => {
    if (!missionPlannerFleetId || !period) return;
    dispatch(
      fetchGetMissionQueue({
        currentFleetId: missionPlannerFleetId,
        activeTab,
        selectedVehicleIds,
        period,
        limit,
        searchValue,
      })
    );
  }, [
    missionPlannerFleetId,
    activeTab,
    selectedVehicleIds.length,
    period,
    limit,
    searchValue,
  ]);

  // update missions from storage
  useEffect(() => {
    if (
      isMissionsUpdatesPaused !== false ||
      missionsUpdatesStorage.length === 0
    )
      return;

    missionsUpdatesStorage.forEach((update) => {
      dispatch(updateDeploymentsQueue({ data: update }));
    });
    dispatch(resetMissionsUpdatesStorage());
  }, [isMissionsUpdatesPaused, missionsUpdatesStorage]);

  return null;
});
