import React, {
  memo,
  useState,
  useEffect,
  useMemo,
  useCallback,
  Dispatch,
  SetStateAction,
} from "react";
import { useSelector } from "react-redux";

import { Dropdown } from "Components/Modals/ListDataPagesModals/components/Dropdown";
import { getFleetsTransportFacilityModals } from "Slices/fleets";
import { getVehiclesTransportFacilityModals } from "Slices/vehicles/selectors";
import { AssignedData } from "Components/AutoMissionGeneratorPage/types";

import { PickerStyled } from "../../styles";
import { AssignMissionsContentStyled } from "./styles";

type AssignMissionsContentProps = {
  handleDisableSuccessButton?: (value: boolean) => void;
  setAssignedData: Dispatch<SetStateAction<AssignedData | null>>;
};

export const AssignMissionsContent = memo<AssignMissionsContentProps>(
  ({ handleDisableSuccessButton, setAssignedData }) => {
    const [selectedfleetOrVehicle, setSelectedFleetOrVehicle] =
      useState<ModalItem | null>(null);
    const fleetItems = useSelector(getFleetsTransportFacilityModals);
    const vehicleItems = useSelector(getVehiclesTransportFacilityModals);
    const changeAction = useCallback((value: ModalItem | null) => {
      setSelectedFleetOrVehicle(value);
    }, []);
    const fleetsAndVehicles = useMemo(
      () => [...fleetItems, ...vehicleItems],
      [fleetItems, vehicleItems]
    );

    useEffect(() => {
      if (!handleDisableSuccessButton) return;
      if (selectedfleetOrVehicle) {
        const fleetIds = fleetItems.map((fleetItem) => fleetItem.id);
        fleetIds.includes(selectedfleetOrVehicle.id)
          ? setAssignedData({
              vehicleId: null,
              fleetId: selectedfleetOrVehicle.id,
            })
          : setAssignedData({
              vehicleId: selectedfleetOrVehicle.id,
              fleetId: null,
            });

        handleDisableSuccessButton(false);
        return;
      }
      handleDisableSuccessButton(true);
    }, [selectedfleetOrVehicle, fleetItems]);

    return (
      <AssignMissionsContentStyled>
        <PickerStyled>
          <span>Select a Vehicle or Fleet</span>
          <Dropdown
            isIncludesEmptyValue
            currentValue={selectedfleetOrVehicle}
            options={fleetsAndVehicles}
            changeAction={changeAction}
          />
        </PickerStyled>
      </AssignMissionsContentStyled>
    );
  }
);
