import { getVehiclesSyncStateById } from "./selectors";
import VehiclesSyncStateSlice from "./reducers";

// actions
export const {
  setVehiclesSyncStateHTTP,
  setVehiclesSyncStateWebsocket,
  updateVehiclesSyncState,
  resetVehicleSyncState,
} = VehiclesSyncStateSlice.actions;

//selectors
export { getVehiclesSyncStateById };

//reducer
export default VehiclesSyncStateSlice.reducer;
