import { RootState } from "../../index";

export const getShowGlobalLoader = (state: RootState) =>
  state.appState.showGlobalLoader;
export const getExpirationDate = (state: RootState) =>
  state.appState.expirationDate;
export const getBackUrl = (state: RootState) => state.appState.backUrl;
export const getLoginErrorText = (state: RootState) =>
  state.appState.loginErrorText;
export const getCurrentSemanticMapId = (state: RootState) =>
  state.appState.currentSemanticMapId;
