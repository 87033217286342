import React, { memo } from "react";

import { LiveQueueDeploymentsLoader } from "Pages/LiveQueueDeployments/LiveQueueDeployments.loader";
import { LiveQueueDeploymentsController } from "Pages/LiveQueueDeployments/LiveQueueDeployments.controller";
import { LiveQueueDeployments } from "Components/LiveQueueDeployments/LiveQueueDeployments";

export const LiveQueueDeploymentsPage = memo(() => {
  return (
    <LiveQueueDeploymentsLoader>
      <LiveQueueDeploymentsController />
      <LiveQueueDeployments />
    </LiveQueueDeploymentsLoader>
  );
});
