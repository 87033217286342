import React, { memo, useCallback, useRef } from "react";
import { useSelector } from "react-redux";

import { getStopsByCurrentSemanticMapDict } from "Slices/stops";
import { ReactComponent as DeleteIcon } from "Components/common/assets/deleteIcon.svg";

import { WAIT_ACTION_TYPE, ACTIONS_TYPES_DICT } from "../../constants";

import {
  RemoveItemStyled,
  ActionsTableStyled,
  PlanListStyled,
  PlanItemsBodyStyled,
  PlanItemsStyled,
  PlanItemNameStyled,
  PlanHeaderStyled,
  PlanHeaderItemStyled,
} from "./styles";
import { AddActionModule } from "Components/Modals/AddEditMissionModal/components/AddActionModule/AddActionModule";

interface ActionsTableProps {
  missionActions: Array<MissionAction | MissionActionPatch>;
  setMissionActions: (
    missionActions: Array<MissionAction | MissionActionPatch>
  ) => void;
}

export const ActionsTable = memo(
  ({ missionActions, setMissionActions }: ActionsTableProps) => {
    const stopsById = useSelector(getStopsByCurrentSemanticMapDict);

    const listRef = useRef<HTMLDivElement>(null);

    const removeAction = useCallback(
      (indexPlanItem: number) => {
        const missionActionItems = [...missionActions];
        missionActionItems.splice(indexPlanItem, 1);
        setMissionActions(missionActionItems);
      },
      [missionActions]
    );

    return (
      <>
        <PlanHeaderStyled>
          <PlanHeaderItemStyled>Step</PlanHeaderItemStyled>
          <PlanHeaderItemStyled>Stop</PlanHeaderItemStyled>
        </PlanHeaderStyled>
        <ActionsTableStyled>
          <PlanListStyled>
            {missionActions.map(({ duration, stopPk, type }, index) => {
              const stop = stopsById[stopPk]?.name || "No Data";
              return (
                <PlanItemsBodyStyled key={`${stopPk}-${index}`}>
                  <PlanItemsStyled>
                    <PlanItemNameStyled>
                      {ACTIONS_TYPES_DICT[type]}
                    </PlanItemNameStyled>
                    <PlanItemNameStyled width={120}>{stop}</PlanItemNameStyled>
                    {type === WAIT_ACTION_TYPE && (
                      <PlanItemNameStyled width={180}>
                        {duration === -1 ? "Manual Release" : `${duration} sec`}
                      </PlanItemNameStyled>
                    )}
                  </PlanItemsStyled>
                  <RemoveItemStyled>
                    <DeleteIcon onClick={() => removeAction(index)} />
                  </RemoveItemStyled>
                </PlanItemsBodyStyled>
              );
            })}
            <div ref={listRef}></div>
          </PlanListStyled>
          <AddActionModule
            missionActions={missionActions}
            setMissionActions={setMissionActions}
          />
        </ActionsTableStyled>
      </>
    );
  }
);
