import React, { memo, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getMissionPlannerFleetId,
  getSelectedVehicleIds,
  setSelectedVehicleIds,
} from "Slices/allDeploymentsPage";
import { getVehicleTypes } from "Slices/vehicleTypes";
import { getVehiclesByCurrentSemanticMap } from "Slices/vehicles";
import { EMPTY_ICON } from "Utils";
import { FilteredVehicle } from "../../types";
import { VehicleItem } from "../VehicleItem";

import { DelimiterStyled } from "../../../common/Delimeter/styles";
import {
  VehiclesPickerBodyStyled,
  VehiclesPickerPaddingStyled,
  VehiclesPickerTitleStyled,
} from "./styles";

export const VehiclesPicker = memo(() => {
  const dispatch = useDispatch();

  const vehiclesByCurrentSemanticMap: Vehicle[] = useSelector(
    getVehiclesByCurrentSemanticMap
  );
  const vehicleTypes: VehicleType[] = useSelector(getVehicleTypes);
  const missionPlannerFleetId = useSelector(getMissionPlannerFleetId);
  const currentVehiclesIds = useSelector(getSelectedVehicleIds);
  // TODO move to selector
  const vehicleList = useMemo(
    () =>
      vehiclesByCurrentSemanticMap
        .filter((vehicle) => vehicle.fleetId === missionPlannerFleetId)
        .map((vehicle) => {
          const foundVehicleType = vehicleTypes.find(
            (vehicleType) => vehicleType.id === vehicle.vehicleTypeId
          );
          const iconUri = foundVehicleType?.image?.link || EMPTY_ICON;
          return {
            ...vehicle,
            iconUri,
          };
        }),
    [vehiclesByCurrentSemanticMap, vehicleTypes, missionPlannerFleetId]
  );

  const updateSelectedVehicles = useCallback(
    (vehicleId: FilteredVehicle["id"], selected: boolean) => {
      dispatch(setSelectedVehicleIds({ vehicleId, selected }));
    },
    []
  );

  return (
    <VehiclesPickerPaddingStyled>
      <VehiclesPickerTitleStyled>Vehicles</VehiclesPickerTitleStyled>
      <DelimiterStyled />
      {vehicleList && (
        <VehiclesPickerBodyStyled>
          {vehicleList.map((vehicle) => (
            <VehicleItem
              key={vehicle.id}
              updateSelectedVehicles={updateSelectedVehicles}
              vehicle={vehicle}
              isSelected={currentVehiclesIds.includes(vehicle.id)}
            />
          ))}
        </VehiclesPickerBodyStyled>
      )}
    </VehiclesPickerPaddingStyled>
  );
});
