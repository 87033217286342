import React from "react";

import { getMissionStatusData } from "Components/common/MissionStatuses/MissionStatuses";
import { ReactComponent as NoMission } from "Components/common/MissionStatuses/assets/noMission.svg";
import { TableFieldBodyStyled } from "../../../NewList/styles";
import { StatusNameStyled } from "./styles";

export const getDeploymentStatusField = (
  missionStatusCode: MissionStatusCode | "No data"
) => {
  if (missionStatusCode === "No data") {
    return (
      <TableFieldBodyStyled>
        <NoMission />
        <StatusNameStyled>No data</StatusNameStyled>
      </TableFieldBodyStyled>
    );
  }

  const { icon, statusName } = getMissionStatusData(missionStatusCode);

  return (
    <TableFieldBodyStyled>
      {icon}
      <StatusNameStyled>{statusName}</StatusNameStyled>
    </TableFieldBodyStyled>
  );
};
