import styled from "styled-components";

export const MissionDetailsModalWrapperStyled = styled.div`
  position: absolute;
  background-color: #ffffff;
  width: 593px;
  height: 100vh;
  top: 0;
  left: 0;
  font-family: "Readex Pro", sans-serif;
  border-right: 1px solid #e9e9e9;
`;
