import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { z } from "zod";

import API, { showNetworkErrorToast } from "Utils/api";

import { addNewVehicle, updateVehicle } from ".";
import { setVehiclesSyncStateHTTP } from "Slices/vehiclesSyncState";
import { resetFleetsStatus } from "Slices/fleets";

export const fetchGetVehicles = createAsyncThunk(
  "vehicles/fetchGetVehicles",
  async (_, thunkAPI) => {
    try {
      const response = await API.get("/vehicles/");

      const validatedVehicles = VehiclesValidator.parse(response.data.results);

      thunkAPI.dispatch(setVehiclesSyncStateHTTP(validatedVehicles));
      return validatedVehicles;
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

export const fetchPostVehicles = createAsyncThunk<void, VehiclePatch>(
  "vehicles/fetchPostVehicles",
  async (data, thunkAPI) => {
    try {
      const response = await API.post("/vehicles/", {
        ...data,
      });
      thunkAPI.dispatch(addNewVehicle(response.data));
      toast.success("Vehicle added successfully");
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

export const fetchPatchVehicle = createAsyncThunk<
  void,
  { id: string; data: VehiclePatch }
>("vehicles/fetchPatchVehicle", async ({ id, data }, thunkAPI) => {
  try {
    const response = await API.patch(`/vehicles/${id}/`, data);
    await thunkAPI.dispatch(updateVehicle(response.data));
    // resetting fleets status triggers loader for re-uploading the fleets for updating it in redux state
    await thunkAPI.dispatch(resetFleetsStatus());
    toast.success(`Vehicle ${data.name} edited successfully`);
  } catch (error) {
    showNetworkErrorToast(error);
    return Promise.reject();
  }
});

// validators
export const VehiclesValidator = z.array(
  z.object({
    id: z.string(),
    name: z.string(),
    vehicleTypeId: z.string(),
    fleetId: z.string().nullable(),
    licensePlate: z.string(),
    batteryLevel: z.number(),
    description: z.string(),
    driveMode: z.string(),
    lastContact: z.string().nullable(),
    latitude: z.number(),
    longitude: z.number(),
    numPassengers: z.number(),
    shortName: z.string(),
    speed: z.number(),
    state: z.string(),
    vbStatus: z.string(),
    vin: z.string(),
    webstate: z.string(),
    driveModeVersion: z.string(),
    lastDataCollection: z.string().nullable(),
    confFileCode: z.string(),
    semanticMapId: z.string().nullable(),
    isSynchronizing: z.boolean().nullable(),
  })
);
