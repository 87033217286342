import React, { memo, useState } from "react";
import { useSelector } from "react-redux";

import { getCurrentSemanticMap } from "Slices/semanticMaps";
import { MapComponent } from "Components/MapComponent";
import { VehiclesList } from "./components/VehiclesList/VehiclesList";
import { CallVehicleButton } from "./components/CallVehicle";
import {
  MapWindowStyled,
  SidebarStyled,
  ToggleSatButtonStyled,
  WrapperStyled,
} from "./styles";

export const RealTimeMap = memo(() => {
  const [showSatView, setShowSatView] = useState<boolean>(false);
  const currentSemanticMap = useSelector(getCurrentSemanticMap);

  const toggleSatView = () => {
    setShowSatView(!showSatView);
  };

  return (
    <WrapperStyled>
      {currentSemanticMap && (
        <SidebarStyled>
          <VehiclesList />
          <CallVehicleButton />
        </SidebarStyled>
      )}
      <MapWindowStyled>
        <MapComponent showSatView={showSatView} />
        <ToggleSatButtonStyled onClick={toggleSatView}></ToggleSatButtonStyled>
      </MapWindowStyled>
    </WrapperStyled>
  );
});
