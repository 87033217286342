import React, { memo, FC } from "react";

import { ReactComponent as AdditionalInfoIcon } from "../assets/additionalInfo.svg";
import { HelperTextStyled } from "./styles";

interface HelperTextProps {
  additionalInfo?: string;
}

export const HelperText: FC<HelperTextProps> = memo(({ additionalInfo }) => (
  <HelperTextStyled>
    <AdditionalInfoIcon />
    {additionalInfo}
  </HelperTextStyled>
));
