import React, { memo, useCallback, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  ModalStyled,
  ModalBackgroundStyled,
} from "Components/common/ModalComponent/styles";
import {
  MissionPlannerContainerStyled,
  MissionPlannerContentStyled,
} from "Components/common/MissionPlanner/styles";

import { closeAnyModal, getModalData } from "Slices/modals";
import { fetchDeployMissionPlan } from "Slices/missionPlans";
import { DeployedMissionList } from "../../DeployedMissionList/DeployedMissionList";

export const DeployedMissionListModal = memo(() => {
  const dispatch = useDispatch();
  const modalData = useSelector(getModalData);
  const currentFleetId = modalData?.fleetId as string;

  // Check currentFleetId existence before component rendered
  useLayoutEffect(() => {
    if (currentFleetId) return;

    toast.error("Please specify particular fleet before create new mission!");
    handleCloseModal();
  }, [currentFleetId]);

  const handleDeployPlanMissionAction = useCallback(
    async (data: Omit<Deployment, "actions">) => {
      if (!currentFleetId) return;

      await dispatch(
        fetchDeployMissionPlan({
          ...data,
          fleetId: currentFleetId,
          missionPlanId: data.missionPlanId,
        })
      );
    },
    [currentFleetId]
  );

  const handleCloseModal = useCallback(() => {
    dispatch(closeAnyModal());
  }, []);

  return (
    <ModalStyled>
      <ModalBackgroundStyled onClick={handleCloseModal} />
      <MissionPlannerContainerStyled>
        <MissionPlannerContentStyled>
          <DeployedMissionList
            currentFleetId={currentFleetId}
            deployPlanMissionAction={handleDeployPlanMissionAction}
            cancelAction={handleCloseModal}
            previousMode="CONTINUE"
          />
        </MissionPlannerContentStyled>
      </MissionPlannerContainerStyled>
    </ModalStyled>
  );
});
