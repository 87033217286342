import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ActionsTableDropdownItemStyled,
  AddActionContainerStyled,
  AddActionDataStyled,
  AddActionItemStyled,
  DropdownButtonStyled,
  DropdownContentStyled,
  DropdownStyled,
  DurationDropdownContentStyled,
  InputStyled,
} from "./styles";
import {
  ACTIONS_TYPES_DICT,
  AVALIBLE_ACTIONS_TYPES,
  WAIT_ACTION_TYPE,
} from "Components/Modals/AddEditMissionModal/constants";
import { CheckboxComponent } from "Components/common/CheckBoxComponent/CheckBox.component";
import { ButtonComponent } from "Components/common/ButtonComponent/Button.component";
import { ReactComponent as ArrowIcon } from "Components/common/assets/arrowIcon.svg";
import { useSelector } from "react-redux";
import {
  getStopsByCurrentSemanticMap,
  getStopsByCurrentSemanticMapDict,
} from "Slices/stops";
import { useOnClickOutside } from "Utils/hooks";
import { getActionName } from "Utils";

type AddActionModuleProps = {
  missionActions: Array<MissionAction | MissionActionPatch>;
  setMissionActions: (
    missionActions: Array<MissionAction | MissionActionPatch>
  ) => void;
};

export const AddActionModule: FC<AddActionModuleProps> = memo(
  ({ missionActions, setMissionActions }) => {
    const [currentStopItem, setCurrentStopItem] = useState<Stop | null>(null);
    const [isInfinitelyStop, setIsInfinitelyStop] = useState(true);
    const [currentTypeItem, setCurrentTypeItem] =
      useState<MissionActionType | null>(null);
    const [actionsDuration, setActionsDuration] = useState("");
    const [showDurationDropdown, setShowDurationDropdown] =
      useState<boolean>(false);
    const [showStopDropdown, setShowStopDropdown] = useState<boolean>(false);
    const [showTypeDropdown, setShowTypeDropdown] = useState<boolean>(false);

    const stopsById = useSelector(getStopsByCurrentSemanticMapDict);
    const stops = useSelector(getStopsByCurrentSemanticMap);

    const sortedStops = useMemo(
      () =>
        stops.sort((prev, next) =>
          prev?.name?.toLowerCase().localeCompare(next?.name?.toLowerCase())
        ),
      [stops]
    );

    const isDisabledAddPlanButton = useMemo(() => {
      if (currentTypeItem === WAIT_ACTION_TYPE) {
        return (
          !currentTypeItem ||
          !currentStopItem ||
          (!actionsDuration && !isInfinitelyStop)
        );
      }
      return !currentTypeItem || !currentStopItem;
    }, [currentTypeItem, currentStopItem, actionsDuration, isInfinitelyStop]);

    const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
    const handleClickOutside = useCallback(() => closeAllDropdowns(), []);
    useOnClickOutside(ref, handleClickOutside);

    // set stop for not a 'GoToPoint' action
    useEffect(() => {
      if (
        currentTypeItem &&
        currentTypeItem !== 1 &&
        missionActions.length !== 0
      ) {
        const tempMissionActions = [...missionActions];
        const lastGoToPointAction = tempMissionActions
          .reverse()
          .find((action) => action.type === 1);
        if (!lastGoToPointAction) return;

        setCurrentStopItem(stopsById[lastGoToPointAction.stopPk]);
      }
    }, [currentTypeItem, missionActions]);

    const closeAllDropdowns = useCallback(() => {
      setShowStopDropdown(false);
      setShowTypeDropdown(false);
      setShowDurationDropdown(false);
    }, []);

    const selectCurrentStop = useCallback(
      (stopItem: Stop) => {
        setCurrentStopItem(stopItem);
        setShowStopDropdown(false);
      },
      [currentStopItem]
    );

    const selectCurrentType = useCallback(
      (type: MissionActionType) => {
        setCurrentTypeItem(type);
        setShowTypeDropdown(false);
      },
      [currentTypeItem]
    );

    const addMissionAction = useCallback(() => {
      if (isDisabledAddPlanButton) {
        return;
      }
      const currentStopItemStrict = currentStopItem as Stop;
      const currentTypeItemStrict = currentTypeItem as MissionActionType;

      setMissionActions([
        ...missionActions,
        {
          name: getActionName(
            currentTypeItemStrict,
            currentStopItemStrict.name,
            isInfinitelyStop ? -1 : Number(actionsDuration)
          ),
          type: currentTypeItemStrict,
          stopPk: currentStopItemStrict.id,
          duration: isInfinitelyStop ? -1 : Number(actionsDuration),
          order: missionActions.length + 1,
          latitude: currentStopItemStrict.latitude,
          longitude: currentStopItemStrict.longitude,
        },
      ]);
      setCurrentStopItem(null);
      setCurrentTypeItem(null);
      setIsInfinitelyStop(true);
      setActionsDuration("");
      closeAllDropdowns();
    }, [
      isDisabledAddPlanButton,
      missionActions,
      currentStopItem?.id,
      actionsDuration,
      currentTypeItem,
    ]);

    return (
      <AddActionContainerStyled ref={ref}>
        <AddActionDataStyled>
          <AddActionItemStyled>
            <DropdownStyled>
              <DropdownButtonStyled
                onClick={() => setShowTypeDropdown(!showTypeDropdown)}
                active={showTypeDropdown}
              >
                {currentTypeItem ? ACTIONS_TYPES_DICT[currentTypeItem] : "Step"}
                <ArrowIcon />
              </DropdownButtonStyled>
              {showTypeDropdown && (
                <DropdownContentStyled>
                  {AVALIBLE_ACTIONS_TYPES.map((type) => (
                    <ActionsTableDropdownItemStyled
                      key={type}
                      onClick={() => selectCurrentType(type)}
                    >
                      {ACTIONS_TYPES_DICT[type]}
                    </ActionsTableDropdownItemStyled>
                  ))}
                </DropdownContentStyled>
              )}
            </DropdownStyled>
          </AddActionItemStyled>
          <AddActionItemStyled width={120}>
            <DropdownStyled>
              <DropdownButtonStyled
                onClick={() => {
                  if (currentTypeItem !== 1) return;

                  setShowStopDropdown(!showStopDropdown);
                }}
                active={showStopDropdown}
                disabled={currentTypeItem !== 1}
              >
                {currentStopItem?.name ?? "Stop"}
                <ArrowIcon />
              </DropdownButtonStyled>
              {showStopDropdown && (
                <DropdownContentStyled>
                  {sortedStops.map((stop) => (
                    <ActionsTableDropdownItemStyled
                      key={stop.id}
                      onClick={() => selectCurrentStop(stop)}
                    >
                      {stop.name}
                    </ActionsTableDropdownItemStyled>
                  ))}
                </DropdownContentStyled>
              )}
            </DropdownStyled>
          </AddActionItemStyled>
          <AddActionItemStyled width={180}>
            {currentTypeItem === WAIT_ACTION_TYPE && (
              <DropdownStyled>
                <DropdownButtonStyled
                  onClick={() => setShowDurationDropdown(!showDurationDropdown)}
                  active={showDurationDropdown}
                >
                  {isInfinitelyStop
                    ? "Manual Release"
                    : `${actionsDuration} sec`}
                  <ArrowIcon />
                </DropdownButtonStyled>
                {showDurationDropdown && (
                  <DurationDropdownContentStyled>
                    <InputStyled
                      type="number"
                      min="1"
                      placeholder="sec"
                      value={actionsDuration}
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-", "."].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      onChange={(e) => {
                        setActionsDuration(e.target.value);
                        setIsInfinitelyStop(false);
                      }}
                    />
                    <ActionsTableDropdownItemStyled>
                      <CheckboxComponent
                        checked={isInfinitelyStop}
                        onChange={(event) => {
                          setIsInfinitelyStop(event.target.checked);
                          setActionsDuration("");
                        }}
                        labelText="Manual Release"
                      />
                    </ActionsTableDropdownItemStyled>
                  </DurationDropdownContentStyled>
                )}
              </DropdownStyled>
            )}
          </AddActionItemStyled>
        </AddActionDataStyled>
        <ButtonComponent
          clickAction={addMissionAction}
          type={isDisabledAddPlanButton ? "disabled" : "add"}
          width={70}
        >
          Add
        </ButtonComponent>
      </AddActionContainerStyled>
    );
  }
);
