import React, { memo, FC, useCallback, useRef } from "react";

import { DragAndDropFileHOC } from "../../../common/DragAndDropFileHOC";

import { ReactComponent as EmptyIcon } from "../../assets/emptyIcon.svg";
import {
  FileLoaderBodyStyled,
  TextHelperStyled,
  FileInputStyled,
  TextFormatLoaderStyled,
  FilePickerStyled,
} from "./styles";

const acceptableFileFormats = [
  "text/csv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export type MissionPlansFileLoaderProps = {
  setFile: (file: File | null) => void;
};

export const MissionPlansFileLoader: FC<MissionPlansFileLoaderProps> = memo(
  ({ setFile }) => {
    const fileInputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

    const handleFilePicker = useCallback(() => {
      fileInputRef.current.click();
    }, []);
    const handleLoadMissionPlanFile = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setFile(event.target.files?.[0] || null);
      },
      []
    );

    return (
      <DragAndDropFileHOC
        setFile={setFile}
        acceptableFileFormats={acceptableFileFormats}
        errorMessage="Invalid file format"
      >
        {(isDragActive) => (
          <FileLoaderBodyStyled isDragActive={isDragActive}>
            <EmptyIcon />
            <TextHelperStyled>
              Drop your file here, or
              <FilePickerStyled onClick={handleFilePicker}>
                browse
              </FilePickerStyled>
              <FileInputStyled
                ref={fileInputRef}
                onChange={handleLoadMissionPlanFile}
                type="file"
                accept="file/*,.csv,.xlsx,"
              />
            </TextHelperStyled>
            <TextFormatLoaderStyled>
              Supports: .csv, .xlsx
            </TextFormatLoaderStyled>
          </FileLoaderBodyStyled>
        )}
      </DragAndDropFileHOC>
    );
  }
);
