import React, { memo } from "react";

import { AllDeployments } from "Components/AllDeployments/AllDeployments";

import { AllDeploymentsController } from "./AllDeployments.controller";
import { AllDeploymentsLoader } from "./AllDeployments.loader";

export const AllDeploymentsPage = memo(() => {
  return (
    <AllDeploymentsLoader>
      <AllDeploymentsController />
      <AllDeployments />
    </AllDeploymentsLoader>
  );
});
