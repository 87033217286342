import { createAsyncThunk } from "@reduxjs/toolkit";
import { z } from "zod";

import API, { showNetworkErrorToast } from "Utils/api";

export const fetchGetRoles = createAsyncThunk(
  "currentUser/fetchGetRoles",
  async () => {
    try {
      const response = await API.get("roles/");

      return RolesValidator.parse(response.data.results);
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

// validator
const RolesValidator = z.array(
  z.object({
    id: z.number(),
    name: z.string(),
  })
);
