import React, { useState, useRef, memo, useCallback, FC } from "react";

import { ReactComponent as DashboardIcon } from "../../assets/dashboardIcon.svg";
import { useOnClickOutside } from "Utils/hooks";

import {
  DashboardDropdownStyled,
  DashboardDropdownBtnStyled,
  DashboardDropdownContentStyled,
  DashboardDropdownLinkStyled,
} from "./styles";
import { FMSLogger } from "FMSLogger";
import { NAVIGATION_BAR_ACTIONS } from "Utils/constants";

const navigationBarActionsLogger = FMSLogger.byPrefix(NAVIGATION_BAR_ACTIONS);

type DashboardsDropdownProps = { linkItems: Array<DashboardLink> };

export const DashboardsDropdown: FC<DashboardsDropdownProps> = memo(
  ({ linkItems }) => {
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
    const handleClickOutside = useCallback(() => setShowDropdown(false), []);
    useOnClickOutside(ref, handleClickOutside);

    return (
      <DashboardDropdownStyled ref={ref}>
        <DashboardDropdownBtnStyled
          data-test-id="Dashboard"
          onClick={() => setShowDropdown(!showDropdown)}
          active={showDropdown}
        >
          <DashboardIcon />
          Dashboards
        </DashboardDropdownBtnStyled>
        {showDropdown && (
          <DashboardDropdownContentStyled>
            {linkItems.map(({ linkName, linkUrl }) => {
              return (
                <DashboardDropdownLinkStyled
                  data-test-id={linkName}
                  key={linkName}
                  target="_blank"
                  href={linkUrl}
                  onClick={() => {
                    navigationBarActionsLogger.debug(
                      `click on dashboards link ${linkUrl}`
                    );
                  }}
                >
                  {linkName}
                </DashboardDropdownLinkStyled>
              );
            })}
          </DashboardDropdownContentStyled>
        )}
      </DashboardDropdownStyled>
    );
  }
);
