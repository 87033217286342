import styled from "styled-components";

export const CellContainerStyled = styled.div`
  display: flex;
  padding: 14px 16px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  color: #1b1b1b;
  font-family: "Readex Pro", sans-serif;
  font-size: 14px;
  line-height: 140%;
`;

export const CellButtonStyled = styled.div`
  width: max-content;
  cursor: pointer;
`;

export const TableOptionsContainerStyled = styled.div`
  height: 100%;
  width: 14px;
`;
