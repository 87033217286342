import { createSlice } from "@reduxjs/toolkit";

type VehiclesSyncState = Record<VehicleId, boolean>;

const initialState: VehiclesSyncState = {};

export const Slice = createSlice({
  name: "vehiclesSyncState",
  initialState,
  reducers: {
    resetVehicleSyncState: () => initialState,
    updateVehiclesSyncState: (state, action) => {
      state[action.payload.vehicle_id] = action.payload.is_synchronizing;
      return state;
    },
    setVehiclesSyncStateWebsocket: (state, action) => {
      action.payload.forEach((vehicleWebsocketData: VehicleState) => {
        state[vehicleWebsocketData.vehicle_id] = Boolean(
          vehicleWebsocketData.is_synchronizing
        );
      });
      return state;
    },
    setVehiclesSyncStateHTTP: (state, action) => {
      if (Object.keys(state).length === 0) {
        state = action.payload.reduce(
          (acc: Record<VehicleId, boolean>, vehicle: Vehicle) => {
            acc[vehicle.id] = Boolean(vehicle.isSynchronizing);
            return acc;
          },
          {}
        );
      }
      return state;
    },
  },
});

export default Slice;
