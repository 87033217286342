import { LoadingStyles } from "Components/common/styles";
import styled from "styled-components";

interface SearchDropdownBtnProps {
  isLoading: boolean;
}

export const SearchDropdownInputStyled = styled.input<SearchDropdownBtnProps>`
  background: #f4f4f4;
  height: 56px;
  width: 100%;
  font-size: 16px;
  line-height: 128%;
  color: #1b1b1b;
  padding: 18px 24px 18px 24px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props) => props.isLoading && LoadingStyles}

  &::placeholder {
    font-weight: 300;
    color: #606060;
  }
`;

export const SearchDropdownContainerStyled = styled.div`
  position: relative;
`;

export const SearchInputContainerStyled = styled.div`
  position: relative;
  width: 100%;
`;

export const SearchIconStyled = styled.div`
  svg {
    position: absolute;
    left: -12px;
    top: 16px;
  }
`;

export const SearchDropdownListContainerStyled = styled.div`
  position: absolute;
  width: 100%;
  top: 56px;
  z-index: 1;
`;

export const SearchDropdownListStyled = styled.ul`
  margin: 0;
  background-color: #f8f8f8;
  box-sizing: border-box;
  color: #3b4256;
  height: 100%;
  padding: 8px 0;
  font-size: 16px;
  max-height: 270px;
  overflow-y: auto;
`;

export const DelimiterStyled = styled.hr`
  position: absolute;
  left: 16px;
  width: 94%;
  background-color: #c5c5c5;
  margin: 0 auto;
  padding: 0;
  height: 1px;
  border: none;
`;
