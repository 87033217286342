import React, { useState, useRef, memo, useCallback, FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { useOnClickOutside } from "Utils/hooks";
import {
  ADD_EDIT_MISSION_MODAL,
  DEPLOYED_MISSION_LIST_MODAL,
  toggleModal,
} from "Slices/modals";
import { FMSLogger } from "FMSLogger";
import { COMMON_COMPONENTS_ACTIONS } from "Utils/constants";
import {
  AUTO_MISSION_GENERATOR_PATH,
  MISSIONS_PATH,
} from "../../../routers/AuthorizedRouter";

import { ReactComponent as AddIcon } from "../assets/addIcon.svg";
import { ReactComponent as SelectIcon } from "Components/common/assets/selectIcon.svg";
import {
  AddMissionsDropdownStyled,
  DropdownButtonStyled,
  DropdownContentStyled,
  AddMissionsDropdownItemStyled,
  ButtonContentStyled,
} from "./styles";
import { getIsAdmin } from "Slices/currentUser";

const commonComponentsActionsLogger = FMSLogger.byPrefix(
  COMMON_COMPONENTS_ACTIONS
);

type AddMissionsDropdownProps = {
  fleetId: string | null;
};

export const AddMissionsDropdown: FC<AddMissionsDropdownProps> = memo(
  ({ fleetId }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const ref = useRef() as React.MutableRefObject<HTMLDivElement>;

    const isUserAdmin = useSelector(getIsAdmin);

    const handleClickOutside = useCallback(() => setShowDropdown(false), []);
    useOnClickOutside(ref, handleClickOutside);

    const [showDropdown, setShowDropdown] = useState<boolean>(false);

    const handleNewMissions = () => {
      commonComponentsActionsLogger.debug("Add mission modal has been opened");
      dispatch(
        toggleModal({ type: ADD_EDIT_MISSION_MODAL, data: { fleetId } })
      );
      setShowDropdown(false);
    };

    const handleMissionList = () => {
      commonComponentsActionsLogger.debug("Mission list has been opened");
      dispatch(
        toggleModal({ type: DEPLOYED_MISSION_LIST_MODAL, data: { fleetId } })
      );
      setShowDropdown(false);
    };

    const isNewMissionPages = location.pathname !== MISSIONS_PATH;
    const buttonTitle = isNewMissionPages ? "Add Mission" : "Add to Queue";

    return (
      <AddMissionsDropdownStyled ref={ref}>
        <DropdownButtonStyled
          onClick={() => setShowDropdown(!showDropdown)}
          active={showDropdown}
        >
          {isNewMissionPages && <AddIcon />}
          <ButtonContentStyled>{buttonTitle}</ButtonContentStyled>
          <SelectIcon />
        </DropdownButtonStyled>
        {showDropdown && (
          <DropdownContentStyled>
            <AddMissionsDropdownItemStyled onClick={handleNewMissions}>
              New Mission
            </AddMissionsDropdownItemStyled>
            <AddMissionsDropdownItemStyled onClick={handleMissionList}>
              Mission List
            </AddMissionsDropdownItemStyled>
            {isUserAdmin && (
              <AddMissionsDropdownItemStyled
                onClick={() => navigate(AUTO_MISSION_GENERATOR_PATH)}
              >
                Auto-Generate Missions
              </AddMissionsDropdownItemStyled>
            )}
          </DropdownContentStyled>
        )}
      </AddMissionsDropdownStyled>
    );
  }
);
