import styled from "styled-components";

interface HelpDropdownBtnProps {
  active: boolean;
}
interface SemanticMapPickerWrapper {
  isDisabled: boolean;
}

export const SemanticMapPickerWrapperStyled = styled.div<SemanticMapPickerWrapper>`
  position: relative;
  cursor: ${(props) => (props.isDisabled ? "default" : "pointer")};
`;

export const SemanticMapPickerDropdownStyled = styled.div<HelpDropdownBtnProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    transform: ${(props) =>
      props.active ? "rotateX(180deg)" : "rotateX(0deg)"};
    transition: 0.3s;
  }
`;

export const PlaceholderStyled = styled.div`
  height: 16px;
  font-family: "Readex Pro", sans-serif;
  font-weight: 300;
  font-size: 12px;
  color: #606060;
`;

export const CurrentItemStyled = styled.div`
  font-family: "Readex Pro", sans-serif;
  color: #172a3f;
  font-size: 20px;
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DropdownContentStyled = styled.div`
  background-color: #ffffff;
  position: absolute;
  width: 100%;
  top: 54px;
  padding: 8px 0;
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
  z-index: 1;
  max-height: 300px;
  overflow-y: auto;
`;

export const SemanticMapPickerDropdownItemStyled = styled.div`
  font-size: 16px;
  color: #606060;
  padding: 14px 18px;
  border-bottom: 1px solid transparent;
  transition: all 0.3s;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &:hover {
    color: #55b8b3;
    border-bottom: 1px solid #55b8b3;
  }
`;
