import React, { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FMSLogger } from "FMSLogger";
import {
  cancelDeployment,
  changeMissionOrder,
  getActiveTab,
  getAllMissionsCount,
  getCompletedMissionsCount,
  getDeployedMissionsCount,
  getHeaderData,
  getDeployments,
  getMissionRowsLimit,
  getMissionPlannerPeriod,
  resetPagination,
  setMissionRowsLimit,
  getMissionPlannerFleetId,
  pauseMissionsUpdates,
  continueMissionsUpdates,
} from "Slices/allDeploymentsPage";
import { getVehicles } from "Slices/vehicles";
import { MISSION_PLANNER_ACTIONS } from "Utils/constants";

import { List } from "../../../NewList";
import { RowData } from "../../../NewList/types";
import {
  getLoopsField,
  getActionsField,
} from "Components/common/TableElements";
import { getDeploymentStatusField } from "./utils";

import {
  CounterStyled,
  PaginationBodyStyled,
  PaginationButton,
  ShowingItemStyled,
} from "./styles";
import { ReactComponent as SelectIcon } from "../../assets/selectIconBold.svg";

const missionPlannerActionsLogger = FMSLogger.byPrefix(MISSION_PLANNER_ACTIONS);

const UNCANCELABLE_CODES = [
  "CANCELED",
  "FINISHED",
  "CANCEL_PENDING",
  "CANCEL_SENT",
  "FAILURE",
];

type MissionPlannerRowData = RowData & {
  missionStatusCode: MissionStatusCode;
  order: number;
};

export const AllDeploymentsList = memo(() => {
  const dispatch = useDispatch();

  const rawDeployments = useSelector(getDeployments);
  const vehicles: Array<Vehicle> = useSelector(getVehicles);
  const headerData = useSelector(getHeaderData);
  const activeTab = useSelector(getActiveTab);
  const limit = useSelector(getMissionRowsLimit);
  const allMissionsTabCount = useSelector(getAllMissionsCount);
  const deployedMissionsTabCount = useSelector(getDeployedMissionsCount);
  const completedMissionsTabCount = useSelector(getCompletedMissionsCount);
  const missionPlannerFleetId = useSelector(getMissionPlannerFleetId);
  const period = useSelector(getMissionPlannerPeriod);

  const options = useMemo(
    () => [
      {
        name: "Cancel",
        getIsHidden: (item: MissionPlannerRowData) =>
          UNCANCELABLE_CODES.includes(item.missionStatusCode) || !item.id,
        callback: (item: MissionPlannerRowData) => {
          missionPlannerActionsLogger.debug(
            `Pressed cancel mission with id:${item.id}`
          );
          dispatch(
            cancelDeployment({
              deploymentId: item.id,
              deploymentName: item.name as string,
            })
          );
        },
      },
    ],
    []
  );

  const preparedDeploymentsData: Array<MissionPlannerRowData> = useMemo(() => {
    missionPlannerActionsLogger.debug("Recalculate preparedMissionsData");
    if (!rawDeployments) return [];
    return rawDeployments.map((deployment) => ({
      id: deployment.id,
      name: deployment.name,
      assignedTo:
        vehicles.find((vehicle) => vehicle?.id === deployment.vehicleId)
          ?.name ?? "NONE",
      status: getDeploymentStatusField(
        deployment?.vehicleDeployment?.status ?? "NEW"
      ),
      missionStatusCode: deployment?.vehicleDeployment?.status ?? "NEW",
      actions: getActionsField(
        deployment?.vehicleDeployment?.passedActions ?? 0,
        deployment?.vehicleDeployment?.totalActions ?? deployment.actions
      ),
      loops: getLoopsField(
        deployment?.vehicleDeployment?.passedLoops ?? 0,
        deployment?.vehicleDeployment?.totalLoops ?? deployment.loops
      ),
      // This is completely not working now, but if in future we will implement reordering
      // for deployments - add here something like deployment.order instead of zero
      order: 0,
    }));
  }, [rawDeployments, vehicles]);

  const handleDragStart = useCallback(() => {
    missionPlannerActionsLogger.debug("HandleDragStart");
    dispatch(pauseMissionsUpdates());
  }, []);

  const handleDragEnd = useCallback(
    async (
      isElementPlacedOnSamePosition: boolean,
      draggedMissionId: string,
      shiftedMission: MissionPlannerRowData,
      cancelAction: () => Array<MissionPlannerRowData>,
      updatedItems: Array<MissionPlannerRowData>
    ) => {
      missionPlannerActionsLogger.debug("HandleDragEnd", {
        isElementPlacedOnSamePosition,
        id: draggedMissionId,
        order: shiftedMission.order,
        updatedItemsIds: updatedItems.map((item) => item.id),
      });
      if (!isElementPlacedOnSamePosition) {
        await dispatch(
          changeMissionOrder({
            missionId: draggedMissionId,
            order: shiftedMission.order,
            cancelAction,
            newMissionsOrder: updatedItems,
          })
        );
      }
      dispatch(continueMissionsUpdates());
    },
    []
  );

  const isDragAndDropActive = useMemo(() => {
    if (!rawDeployments) return false;

    const isSameVehicleForAllDeployments = rawDeployments.every(
      (deployment) => deployment.vehicleId === rawDeployments[0].vehicleId
    );
    const isDeployedTab = activeTab === "Deployed";

    return isDeployedTab && isSameVehicleForAllDeployments;
  }, [activeTab, rawDeployments]);

  const currentActiveTabCount = useMemo(() => {
    if (activeTab === "Deployed") {
      return deployedMissionsTabCount;
    } else if (activeTab === "Completed") {
      return completedMissionsTabCount;
    } else return allMissionsTabCount;
  }, [
    activeTab,
    allMissionsTabCount,
    deployedMissionsTabCount,
    completedMissionsTabCount,
  ]);

  const Pagination = useMemo(() => {
    const paginationClickHandler = () => {
      const currentLimit = limit + 20;
      const paginationLimit =
        currentLimit <= currentActiveTabCount
          ? currentLimit
          : currentActiveTabCount;
      dispatch(setMissionRowsLimit(paginationLimit));
    };

    return (
      limit <= currentActiveTabCount && (
        <PaginationBodyStyled>
          <ShowingItemStyled>
            Showing:
            <CounterStyled>
              {limit > currentActiveTabCount ? currentActiveTabCount : limit}
            </CounterStyled>
            of
            <CounterStyled>{currentActiveTabCount}</CounterStyled>
          </ShowingItemStyled>
          {limit < currentActiveTabCount && (
            <PaginationButton onClick={paginationClickHandler}>
              Load More
              <SelectIcon />
            </PaginationButton>
          )}
        </PaginationBodyStyled>
      )
    );
  }, [limit, currentActiveTabCount]);

  useEffect(() => {
    dispatch(resetPagination());
  }, [activeTab, period, missionPlannerFleetId]);

  const optionsDropdownOffset = useMemo(() => ({ top: 40, right: 0 }), []);

  return (
    <List
      isDraggable={false}
      isDragAndDropActive={isDragAndDropActive}
      data={preparedDeploymentsData}
      headerData={headerData}
      options={options}
      pagination={Pagination}
      handleDragStart={handleDragStart}
      handleDragEnd={handleDragEnd}
      optionsDropdownOffset={optionsDropdownOffset}
    />
  );
});
