import { toast } from "react-toastify";

import API, { showNetworkErrorToast } from "Utils/api";

export const fetchPostMissionsFile = async (
  file: File,
  semanticMapId: string
): Promise<Array<BulkMissionPlan>> => {
  try {
    const response = await API.postForm("/upload-bulk", {
      semanticMapId,
      file: file,
    });
    return response.data;
  } catch (error) {
    showNetworkErrorToast(error);
    return Promise.reject();
  }
};

export const fetchDeployBulkMissions = async (data: {
  bulkMissionPlans: Array<BulkMissionPlan>;
  vehicleId?: string;
  fleetId: string;
}) => {
  try {
    const response = await API.post("/bulk_deployment/", {
      ...data,
    });
    toast.success("Autogenerate mission plans Deployed");
    return response.data.detail;
  } catch (error) {
    showNetworkErrorToast(error);
    return Promise.reject();
  }
};
