import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { closeAnyModal, getModalData } from "Slices/modals";
import { getLiveQueueDeploymentsById } from "Slices/liveQueueDeploymentsPage";
import { getVehicleById } from "Slices/vehicles";
import { MissionDataLoadingError } from "Components/common/MissionDataLoadingError";
import { DeploymentDetailsHeader } from "./components/DeploymentDetailsHeader/DeploymentDetailsHeader";
import { DeploymentDetailsBody } from "./components/DeploymentDetailsBody/DeploymentDetailsBody";
import { AsideLoader } from "Components/common/Loader/Loader";
import { fetchGetMissionPlanById } from "Slices/missionPlans";

import {
  ModalBackgroundStyled,
  ModalStyled,
} from "Components/common/ModalComponent/styles";
import { MissionDetailsModalWrapperStyled } from "./styles";

export const MissionDetailsModal = memo(() => {
  const dispatch = useDispatch();
  const modalData = useSelector(getModalData);
  const currentDeployment = useSelector(
    getLiveQueueDeploymentsById(modalData?.deploymentId as string | undefined)
  );
  const currentVehicle = useSelector(
    getVehicleById(currentDeployment?.vehicleId)
  );
  const [isMissionPlanDataLoading, setIsMissionPlanDataLoading] =
    useState(false);

  const [missionPlan, setMissionPlan] = useState<MissionPlan | null>(null);

  const handleCloseAction = useCallback(() => {
    dispatch(closeAnyModal());
  }, []);

  // request mission plan data
  useEffect(() => {
    const getMissionPlan = async () => {
      setIsMissionPlanDataLoading(true);
      const currentMissionPlan = await fetchGetMissionPlanById(
        currentDeployment?.missionPlanId
      );
      setIsMissionPlanDataLoading(false);
      setMissionPlan(currentMissionPlan);
    };

    getMissionPlan();
  }, [currentDeployment]);

  const deploymentActions = useMemo(() => {
    if (!missionPlan) return null;

    return missionPlan.plan.actions;
  }, [missionPlan]);

  return (
    <ModalStyled>
      <ModalBackgroundStyled onClick={handleCloseAction} />
      <MissionDetailsModalWrapperStyled>
        {isMissionPlanDataLoading && <AsideLoader />}
        {!currentDeployment ? (
          <MissionDataLoadingError errorMessage="Something went wrong!" />
        ) : (
          <>
            <DeploymentDetailsHeader
              deployment={currentDeployment}
              vehicle={currentVehicle}
              handleCloseAction={handleCloseAction}
            />
            {deploymentActions && (
              <DeploymentDetailsBody
                totalLoops={
                  currentDeployment.vehicleDeployment?.totalLoops ??
                  currentDeployment.loops
                }
                passedLoops={
                  currentDeployment.vehicleDeployment?.passedLoops || 0
                }
                passedActions={
                  currentDeployment.vehicleDeployment?.passedActions || 0
                }
                actions={deploymentActions}
              />
            )}
          </>
        )}
      </MissionDetailsModalWrapperStyled>
    </ModalStyled>
  );
});
