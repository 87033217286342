import React, { ReactNode, FC } from "react";

import {
  ForgotPasswordButtonStyled,
  ForgotPasswordButtonContentStyled,
} from "./styles";

type ForgotPasswordButtonProps = {
  children?: ReactNode;
  onClickAction: () => void;
};

export const ForgotPasswordButton: FC<ForgotPasswordButtonProps> = ({
  children,
  onClickAction,
}) => (
  <ForgotPasswordButtonContentStyled>
    <ForgotPasswordButtonStyled onClick={onClickAction}>
      {children}
    </ForgotPasswordButtonStyled>
  </ForgotPasswordButtonContentStyled>
);
