import React, { useCallback, useEffect, useState, memo } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";

import {
  ModalBackgroundStyled,
  ModalStyled,
} from "../../common/ModalComponent/styles";
import { API_URL } from "Utils/api";
import { closeAnyModal } from "Slices/modals";

import {
  VersionsModalLineStyled,
  VersionsModalContentStyled,
  VersionsModalHeaderStyled,
  VersionsModalCopyBtnStyled,
  VersionsModalCloseIconStyled,
} from "./styles";

const APP_VERSION_LONG = window._env_.APP_VERSION_LONG || "";

const VersionsModalLine = ({
  name,
  version,
}: {
  name: string;
  version: string;
}) => {
  return (
    <CopyToClipboard
      text={`${name}: ${version}`}
      onCopy={() => toast.success("Copied to Clipboard!")}
    >
      <VersionsModalLineStyled>
        {name}:<strong>{version}</strong>
      </VersionsModalLineStyled>
    </CopyToClipboard>
  );
};

export const VersionsModal = memo(() => {
  const dispatch = useDispatch();

  const [videoserver1, setVideoserver1] = useState("");
  const [videoserver2, setVideoserver2] = useState("");
  const [telemetry, setTelemetry] = useState("");
  const [cloudCore, setCloudCore] = useState("");

  const handleCloseAction = useCallback(() => {
    dispatch(closeAnyModal());
  }, []);

  const getVideoserverVersion1 = async () => {
    try {
      const response = await axios.get(`${API_URL}vproxy1/livez`);
      if (response.data) {
        setVideoserver1(response.data.APP_VERSION);
      }
    } catch (error) {
      const baseError = error as BaseError;
      if (baseError?.response?.data?.detail) {
        toast.error(baseError.response.data.detail);
        return;
      }
      toast.error("Something went wrong with getVideoserverVersion1");
    }
  };

  const getVideoserverVersion2 = async () => {
    try {
      const response = await axios.get(`${API_URL}vproxy2/livez`);
      if (response.data) {
        setVideoserver2(response.data.APP_VERSION);
      }
    } catch (error) {
      const baseError = error as BaseError;
      if (baseError?.response?.data?.detail) {
        toast.error(baseError.response.data.detail);
        return;
      }
      toast.error("Something went wrong with getVideoserverVersion2");
    }
  };

  const getVideoserverTelemetry = async () => {
    try {
      const response = await axios.get(`${API_URL}telemetry/livez`);
      if (response.data) {
        setTelemetry(response.data.APP_VERSION);
      }
    } catch (error) {
      const baseError = error as BaseError;
      if (baseError?.response?.data?.detail) {
        toast.error(baseError.response.data.detail);
        return;
      }
      toast.error("Something went wrong with getVideoserverTelemetry");
    }
  };

  const getVideoserverCloudCore = async () => {
    try {
      const response = await axios.get(`${API_URL}api/v1/livez`);
      if (response.data) {
        setCloudCore(response.data.APP_VERSION);
      }
    } catch (error) {
      const baseError = error as BaseError;
      if (baseError?.response?.data?.detail) {
        toast.error(baseError.response.data.detail);
        return;
      }
      toast.error("Something went wrong with getVideoserverCloudCore");
    }
  };

  useEffect(() => {
    getVideoserverVersion1();
    getVideoserverVersion2();
    getVideoserverTelemetry();
    getVideoserverCloudCore();
  }, []);

  return (
    <ModalStyled>
      <ModalBackgroundStyled onClick={handleCloseAction} />
      <VersionsModalContentStyled>
        <VersionsModalCloseIconStyled onClick={handleCloseAction} />
        <VersionsModalHeaderStyled>Versions</VersionsModalHeaderStyled>
        <VersionsModalLine name="Videoserver 1" version={videoserver1} />
        <VersionsModalLine name="Videoserver 2" version={videoserver2} />
        <VersionsModalLine name="Telemetry" version={telemetry} />
        <VersionsModalLine name="Cloud core" version={cloudCore} />
        <VersionsModalLine name="Frontend" version={APP_VERSION_LONG} />
        <CopyToClipboard
          text={`Versions: 
Videoserver 1: ${videoserver1}
Videoserver 2: ${videoserver2}
Telemetry: ${telemetry}
Cloud core: ${cloudCore}
Frontend: ${APP_VERSION_LONG}
          `}
          onCopy={() => toast.success("Copied to Clipboard!")}
        >
          <VersionsModalCopyBtnStyled>
            Copy to Clipboard
          </VersionsModalCopyBtnStyled>
        </CopyToClipboard>
      </VersionsModalContentStyled>
    </ModalStyled>
  );
});
