import { RootState } from "../../index";
import { createSelector } from "@reduxjs/toolkit";
import { EMPTY_ARRAY, EMPTY_OBJECT } from "Utils";

export const getLiveQueueDeploymentsPageFleetId = (state: RootState) =>
  state.liveQueueDeploymentsPage.liveQueueDeploymentsPageFleetId;
export const getLiveQueueDeployments = (state: RootState) =>
  state.liveQueueDeploymentsPage.liveQueueDeployments;
export const getActiveLiveQueueDeploymentByVehicleId = createSelector(
  getLiveQueueDeployments,
  (deployments) => {
    if (!deployments) return EMPTY_OBJECT;
    return deployments.reduce(
      (acc: Record<VehicleId, Deployment | LimitedDeployment>, deployment) => {
        if (
          deployment.vehicleId &&
          ["IN_PROGRESS", "PAUSED"].includes(
            deployment?.vehicleDeployment?.status ?? ""
          )
        ) {
          if (!acc[deployment.vehicleId]) {
            acc[deployment.vehicleId] = deployment;
          }
        }
        return acc;
      },
      {}
    );
  }
);

export const getLiveDeployments = createSelector(
  getLiveQueueDeployments,
  (deployments) => {
    if (!deployments) return EMPTY_ARRAY;
    return deployments.filter((deployment) =>
      ["IN_PROGRESS", "PAUSED"].includes(
        deployment?.vehicleDeployment?.status ?? ""
      )
    );
  }
);

export const getLiveQueueDeploymentsById = (deploymentId: string | undefined) =>
  createSelector(getLiveQueueDeployments, (deployments) => {
    if (!deployments || !deploymentId) return;
    return deployments.find((deployment) => deployment.id === deploymentId);
  });

export const getIsLiveQueueDeploymentsUpdating = (state: RootState) =>
  state.liveQueueDeploymentsPage.isLiveQueueDeploymentsUpdating;
