import {
  patchCurrentUserCompany,
  patchPhoneNumber,
  fetchGetCurrentUser,
  postCurrentUserImage,
} from "./actions";
import {
  getCurrentUserId,
  getCurrentUserEmail,
  getCurrentUserFirstName,
  getCurrentUserLastName,
  getCurrentUserName,
  getCurrentUserImage,
  getCurrentUserCompanyId,
  getCurrentUserCompanyName,
  getCurrentUserPhoneNumber,
  getCurrentUserRole,
  getIsImageLoading,
  getIsPhoneNumberUpdating,
  getIsAdmin,
  getIsCurrentUserLoaded,
  getDashboardLinks,
} from "./selectors";
import CurrentUserSlice from "./reducers";

//actions
export const {
  setCurrentUserImage,
  setImageLoading,
  setPhoneNumber,
  setPhoneNumberUpdating,
  resetUser,
  setCurrentUser,
} = CurrentUserSlice.actions;

//async actions
export {
  patchCurrentUserCompany,
  patchPhoneNumber,
  fetchGetCurrentUser,
  postCurrentUserImage,
};

//selectors
export {
  getCurrentUserId,
  getCurrentUserEmail,
  getCurrentUserFirstName,
  getCurrentUserLastName,
  getCurrentUserName,
  getCurrentUserImage,
  getCurrentUserCompanyId,
  getCurrentUserCompanyName,
  getCurrentUserPhoneNumber,
  getCurrentUserRole,
  getIsImageLoading,
  getIsPhoneNumberUpdating,
  getIsAdmin,
  getIsCurrentUserLoaded,
  getDashboardLinks,
};

//reducer
export default CurrentUserSlice.reducer;
