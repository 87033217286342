import React, { useState, useRef, memo, useMemo, useCallback } from "react";

import { useOnClickOutside } from "Utils/hooks";

import {
  DropDownContainerStyled,
  DropDownHeaderStyled,
  DropDownListContainerStyled,
  ListItemStyled,
  DropDownListStyled,
} from "./styles";

type DropdownListElementType = {
  name: string;
  id: string;
  [key: string]: string | number | null | boolean;
};

type DropdownComponentProps<T extends DropdownListElementType> = {
  listData: Array<T>;
  currentItem: string;
  changeItemAction: (value: T) => void;
  placeholderValue?: string;
  specialAction?: string;
  specialActionHandler?: (specialAction?: string) => void;
};

const Dropdown = function <T extends DropdownListElementType>({
  listData,
  currentItem,
  changeItemAction,
  placeholderValue = "",
  specialAction,
  specialActionHandler,
}: DropdownComponentProps<T>) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const listItemClick = (value: T) => {
    changeItemAction?.(value);
    setIsOpen(!isOpen);
  };

  const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
  const handleClickOutside = useCallback(() => setIsOpen(false), []);
  useOnClickOutside(ref, handleClickOutside);

  const sortedListData: Array<T> = useMemo(
    () =>
      [...listData].sort((prev, next) => prev.name.localeCompare(next.name)),
    [listData]
  );

  return (
    <DropDownContainerStyled ref={ref}>
      <DropDownHeaderStyled
        isOpen={isOpen}
        currentItem={currentItem}
        onClick={toggleDropdown}
      >
        {currentItem || placeholderValue}
      </DropDownHeaderStyled>
      {isOpen && (
        <DropDownListContainerStyled>
          <DropDownListStyled>
            {specialAction && (
              <ListItemStyled
                onClick={() => {
                  specialActionHandler
                    ? specialActionHandler(specialAction)
                    : null;
                  setIsOpen(!isOpen);
                }}
              >
                {specialAction}
              </ListItemStyled>
            )}
            {sortedListData.map((element, index) => (
              <ListItemStyled
                key={`${element.id}-${index}`}
                onClick={() => listItemClick(element)}
              >
                {element.name}
              </ListItemStyled>
            ))}
          </DropDownListStyled>
        </DropDownListContainerStyled>
      )}
    </DropDownContainerStyled>
  );
};

export const DropdownComponent = memo(Dropdown) as typeof Dropdown;
