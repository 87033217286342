import React, { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getOperatorMissionStatus,
  getMissionOperatorFleetIdForSubscribe,
  setMissionOperatorFleetId,
} from "Slices/operatorData";
import { getIsStationEmpty } from "Slices/operatorStation";
import { StationPicker } from "./components/StationPicker/StationPicker";
import { VehicleWayStatus } from "./components/VehicleWayStatus";
import { VehicleStatuses } from "./components/VehicleStatuses";
import { SelectStation } from "./components/SelectStation";
import { FleetPicker } from "../common/FleetPicker/FleetPicker";
import { SemanticMapPicker } from "Components/common/SemanticMapPicker/SemanticMapPicker";

import {
  OperatorSidePanelWrapperStyled,
  MissionOperatorBoardStyled,
  PickerPaddingStyled,
  LoadingStyled,
  InteractionPaneWrapper,
} from "./styles";
import { storageKeys } from "Utils/localStorageUtils";
import { CallVehicleButton } from "Components/OperatorSidePanel/components/CallVehicleButton/CallVehicleButton";

export const OperatorSidePanel = memo(() => {
  const dispatch = useDispatch();
  const [isSelectStationModalOpen, setSelectStationModalVisible] =
    useState<boolean>(false);

  const operatorMissionStatus = useSelector(getOperatorMissionStatus);
  const isStationEmpty = useSelector(getIsStationEmpty);
  const operatorFleetIdForSubscribe = useSelector(
    getMissionOperatorFleetIdForSubscribe
  );

  const handleSelectedFleet = useCallback((fleetId: string | null) => {
    if (fleetId) {
      localStorage.setItem(
        storageKeys.MISSION_OPERATOR_SAVED_FLEET_ID,
        fleetId
      );
    }
    dispatch(setMissionOperatorFleetId(fleetId));
  }, []);

  const initFleetId =
    operatorMissionStatus &&
    operatorMissionStatus !== "NO_MISSION" &&
    operatorFleetIdForSubscribe
      ? operatorFleetIdForSubscribe
      : localStorage.getItem(storageKeys.MISSION_OPERATOR_SAVED_FLEET_ID) ?? "";

  return (
    <OperatorSidePanelWrapperStyled>
      <MissionOperatorBoardStyled>
        <PickerPaddingStyled>
          <SemanticMapPicker />
        </PickerPaddingStyled>
        <PickerPaddingStyled>
          {operatorMissionStatus || isStationEmpty ? (
            <FleetPicker
              initFleetId={initFleetId}
              handleSelectedFleet={handleSelectedFleet}
              isPickerDisabled={
                operatorMissionStatus && operatorMissionStatus !== "NO_MISSION"
              }
            />
          ) : (
            <LoadingStyled>Loading...</LoadingStyled>
          )}
        </PickerPaddingStyled>
        <InteractionPaneWrapper>
          <StationPicker
            isSelectStationModalOpen={isSelectStationModalOpen}
            setSelectStationModalVisible={setSelectStationModalVisible}
          />
          <CallVehicleButton />
        </InteractionPaneWrapper>
        {operatorMissionStatus && <VehicleWayStatus />}
      </MissionOperatorBoardStyled>
      <VehicleStatuses />
      {isSelectStationModalOpen && (
        <SelectStation
          setSelectStationModalVisible={setSelectStationModalVisible}
        />
      )}
    </OperatorSidePanelWrapperStyled>
  );
});
