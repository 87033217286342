import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";

import { fetchPatchPermission } from "Slices/intersections";

import { ActivePermission } from "./ActivePermission";
import {
  IntersectionDescStyled,
  IntersectionExpandDetailStyled,
  IntersectionItemWrapperStyled,
  IntersectionItemColumnsStyled,
  IntersectionItemDetailToggleStyled,
  IntersectionSubTitleStyled,
} from "./styles";
import { IntersectionNamePermission } from "../utils";
import { ReactComponent as BlockedIcon } from "../assets/blockedIcon.svg";
import { ReactComponent as ClearIcon } from "../assets/clearedIcon.svg";
import { ReactComponent as ArrowDownIcon } from "../../common/assets/selectIcon.svg";

interface IntersectionItemProps {
  data: IntersectionNamePermission;
}

export const IntersectionItem = memo(({ data }: IntersectionItemProps) => {
  const [showDetail, setShowDetail] = useState(false);
  const dispatch = useDispatch();

  const { id, name, activePermissions } = data;
  const totalActive = activePermissions.length;
  return (
    <IntersectionItemWrapperStyled key={id}>
      <IntersectionItemColumnsStyled>
        <IntersectionItemColumnsStyled>
          {totalActive === 0 ? <ClearIcon /> : <BlockedIcon />}
          <div>
            <div>{name}</div>
            <IntersectionDescStyled>
              {totalActive === 0 ? "Cleared" : `${totalActive} active`}
            </IntersectionDescStyled>
          </div>
        </IntersectionItemColumnsStyled>
        {totalActive > 0 && (
          <IntersectionItemDetailToggleStyled active={showDetail}>
            Detail <ArrowDownIcon onClick={() => setShowDetail(!showDetail)} />
          </IntersectionItemDetailToggleStyled>
        )}
      </IntersectionItemColumnsStyled>
      {showDetail && activePermissions.length > 0 && (
        <IntersectionExpandDetailStyled>
          <IntersectionSubTitleStyled>
            Active Permissions
          </IntersectionSubTitleStyled>
          {activePermissions.map((each) => {
            const { id, intersection_id, vehicle_id } = each;
            return (
              <ActivePermission
                key={id}
                data={each}
                onClickHandler={() => {
                  const payload = {
                    intersection_id,
                    entry_permission_id: id,
                    vehicle_id,
                    successMessage:
                      "The entry permission has been successfully revoked",
                  };
                  dispatch(fetchPatchPermission(payload));
                }}
              />
            );
          })}
        </IntersectionExpandDetailStyled>
      )}
    </IntersectionItemWrapperStyled>
  );
});
