import styled from "styled-components";

interface ButtonProps {
  active: boolean;
}

export const ButtonStyled = styled.div<ButtonProps>`
  display: flex;
  align-items: center;
  padding: 0 16px;
  gap: 8px;
  height: 48px;
  background: ${(props) => (props.active ? "#253f73" : "#ffffff")};
  border: ${(props) =>
    props.active ? "1px solid #253f73" : "1px solid #e9e9e9"};
  cursor: pointer;
  transition: all 0.3s;

  path {
    fill: ${(props) => (props.active ? "#ffffff" : "#606060")};
    transition: all 0.3s;
  }
`;

export const LabelStyled = styled.div<ButtonProps>`
  font-family: "Readex Pro", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => (props.active ? "#ffffff" : "#606060")};
  transition: all 0.3s;
`;

export const CounterBodyStyled = styled.div<ButtonProps>`
  height: 24px;

  path {
    fill: none;
    stroke: ${(props) => (props.active ? "#a4a4a4" : "#d1d1d1")};
    transition: all 0.3s;
  }
`;

export const CounterValueStyled = styled.div<ButtonProps>`
  position: relative;
  bottom: 25px;
  font-family: "Readex Pro", sans-serif;
  font-size: 12px;
  text-align: center;
  color: ${(props) => (props.active ? "#ffffff" : "#606060")};
  transition: all 0.3s;
`;
