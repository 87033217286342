import React, { memo } from "react";

import { EMPTY_ICON } from "Utils";

import { InterectionEntityWithVehicleInfo } from "../utils";
import {
  IntersectionDescStyled,
  IntersectionItemColumnsStyled,
  IntersectionCancelActionStyled,
  ImageStyled,
} from "./styles";

import { ReactComponent as WarningIcon } from "../assets/warningFilledIcon.svg";

interface IntersectionItemProps {
  data: InterectionEntityWithVehicleInfo;
  onClickHandler: () => void;
}

export const ActivePermission = memo(
  ({ data, onClickHandler }: IntersectionItemProps) => {
    const { id, events, vehicle_name, vehicle_link } = data;
    return (
      <>
        <IntersectionItemColumnsStyled key={`permission-${id}`}>
          <IntersectionItemColumnsStyled>
            <div>
              <ImageStyled src={vehicle_link ?? EMPTY_ICON} />
            </div>
            <IntersectionDescStyled>
              {vehicle_name}
              <IntersectionDescStyled isAction={true}>
                Go to Staging
              </IntersectionDescStyled>
            </IntersectionDescStyled>
          </IntersectionItemColumnsStyled>
          <IntersectionCancelActionStyled
            onClick={onClickHandler}
            title="Revoke the permission"
          >
            Revoke
            <WarningIcon />
          </IntersectionCancelActionStyled>
        </IntersectionItemColumnsStyled>
        {events &&
          events.length > 0 &&
          events.map((event, index) => {
            const { timestamp, event_data } = event;
            const timeString = timestamp.split(".")[0].split("T").join(" ");
            return (
              <IntersectionItemColumnsStyled key={`event-${index}`}>
                <div>{timeString}</div>
                <div>{event_data}</div>
              </IntersectionItemColumnsStyled>
            );
          })}
      </>
    );
  }
);
