import styled from "styled-components";

export const StatusNameStyled = styled.div`
  font-weight: 500;
  font-size: 10px;
  color: #1b1b1b;
`;

export const PaginationBodyStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 0;
  gap: 24px;
  height: 68px;
`;

export const ShowingItemStyled = styled.div`
  display: flex;
  font-family: "Readex Pro", sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #1b1b1b;
  gap: 4px;
`;

export const CounterStyled = styled.div`
  font-weight: 400;
`;

export const PaginationButton = styled.div`
  height: 20px;
  display: flex;
  gap: 7.58px;
  font-family: "Readex Pro", sans-serif;
  color: #3f7f7d;
  cursor: pointer;

  svg {
    height: 20px;
    width: 13px;

    path {
      fill: #3f7f7d;
    }
  }

  &:hover {
    border-bottom: 1px solid #3f7f7d;
    transition: opacity 0.3s;
    opacity: 0.5;
  }
`;
