import React, { FC, memo } from "react";

import { ReactComponent as SuccessIcon } from "../../assets/success.svg";
import { MessageStyled } from "../../styles";

import {
  SuccessContentStyled,
  GeneratedMissionsHeaderStyled,
  MissionsItemStyled,
  MissionsContainerStyled,
} from "./styles";

type SuccessContentProps = { bulkMissionPlans: Array<BulkMissionPlan> | null };

export const SuccessContent: FC<SuccessContentProps> = memo(
  ({ bulkMissionPlans }) => {
    const isBulkMissions =
      bulkMissionPlans !== null &&
      Boolean(bulkMissionPlans && bulkMissionPlans.length);

    return (
      <SuccessContentStyled>
        <MessageStyled>
          <SuccessIcon />
          <span>{bulkMissionPlans?.length || 0}</span> mission generated
        </MessageStyled>
        {isBulkMissions && (
          <>
            <GeneratedMissionsHeaderStyled>
              Missions Generated Sucessfully
            </GeneratedMissionsHeaderStyled>
            <MissionsContainerStyled>
              {bulkMissionPlans.map((bulkMission, index) => (
                <MissionsItemStyled key={`${bulkMission.name}-${index}`}>
                  {bulkMission.name}
                </MissionsItemStyled>
              ))}
            </MissionsContainerStyled>
          </>
        )}
      </SuccessContentStyled>
    );
  }
);
