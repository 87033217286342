import React, { FC, memo } from "react";
import {
  BackgroundStyled,
  ContainerStyled,
  ContentStyled,
  HeaderStyled,
  MessageStyled,
  WarningStyled,
} from "Components/Warning/styles";

type AbstractWarningProps = {
  message: string | JSX.Element;
  actionHandler: JSX.Element | null;
};

export const AbstractWarning: FC<AbstractWarningProps> = memo(
  ({ message, actionHandler }) => (
    <WarningStyled>
      <BackgroundStyled />
      <ContentStyled>
        <ContainerStyled>
          <HeaderStyled>Warning!</HeaderStyled>
          <MessageStyled>{message}</MessageStyled>
        </ContainerStyled>
        {actionHandler}
      </ContentStyled>
    </WarningStyled>
  )
);
