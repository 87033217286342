import React, { memo, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { InitialContent } from "./components/initialContent/initialContent";
import { AssignMissionsContent } from "./components/assignMissionsContent/assignMissionsContent";
import { SuccessContent } from "./components/successContent/successContent";
import {
  fetchDeployBulkMissions,
  fetchPostMissionsFile,
} from "Slices/autoMissionGenerator";
import { getVehicles } from "Slices/vehicles";
import { AutoGenerationDeploymentLoader } from "Components/common/Loader/Loader";
import { AssignedData } from "./types";

import {
  AutoMissionGeneratorStyled,
  AutoMissionGeneratorContentStyled,
  AutoMissionGeneratorBackgroundStyled,
  HeaderStyled,
  FooterStyled,
  CancelButtonStyled,
  SuccessButtonStyled,
  PageContentStyled,
} from "./styles";

enum CurrentStatus {
  initial = "initial",
  success = "success",
  assignMissions = "assignMissions",
}

const HEADER_BY_STATUS: { [T: string]: string } = {
  initial: "Automatic Mission Generator",
  success: "Success!",
  assignMissions: "Assign Missions!",
};

const BUTTON_BY_STATUS: { [T: string]: string } = {
  initial: "Generate Mission Plans",
  success: "Assign Missions",
  assignMissions: "Deploy to Queue",
};

export const AutoMissionGenerator = memo(() => {
  const navigate = useNavigate();
  const vehicles = useSelector(getVehicles);
  const [missionPlansFile, setMissionPlansFile] = useState<File | null>(null);
  const [fleetSemanticMap, setFleetSemanticMap] = useState<ModalItem | null>(
    null
  );
  const [bulkMissionPlans, setBulkMissionPlans] =
    useState<Array<BulkMissionPlan> | null>(null);
  const [assignedData, setAssignedData] = useState<AssignedData | null>(null);
  const [status, setStatus] = useState<CurrentStatus>(CurrentStatus.initial);
  const [isSuccessButtonDisabled, setIsSuccessButtonDisabled] =
    useState<boolean>(false);
  const [isShowLoader, setShowLoaderStatus] = useState<boolean>(false);

  const handleDisableSuccessButton = useCallback(
    (value: boolean) => {
      if (isSuccessButtonDisabled !== value) {
        setIsSuccessButtonDisabled(value);
      }
    },
    [isSuccessButtonDisabled]
  );

  const getFleetIdByVehicleId = (vehicleId: string) => {
    const foundVehicle = vehicles.find(
      (vehicle: Vehicle) => vehicle.id === vehicleId
    );
    return foundVehicle?.fleetId;
  };

  const handleInitial = async () => {
    if (missionPlansFile && fleetSemanticMap?.id) {
      let loadedMissions = null;
      try {
        setIsSuccessButtonDisabled(true);
        loadedMissions = await fetchPostMissionsFile(
          missionPlansFile,
          fleetSemanticMap.id
        );
      } catch (error) {
        setIsSuccessButtonDisabled(false);
      }
      if (!loadedMissions) {
        setMissionPlansFile(null);
        return;
      }
      setBulkMissionPlans(loadedMissions);
      setStatus(CurrentStatus.success);
      setIsSuccessButtonDisabled(false);
    }
  };

  const handleAssignMissions = async () => {
    if (assignedData && bulkMissionPlans) {
      let fleetId;
      let vehicleId;

      if (assignedData.fleetId) {
        fleetId = assignedData.fleetId;
      } else if (assignedData.vehicleId) {
        fleetId = getFleetIdByVehicleId(assignedData.vehicleId);
        vehicleId = assignedData.vehicleId;
      }

      if (!fleetId) {
        toast.error("The vehicle is not in the fleet");
        return;
      }
      setIsSuccessButtonDisabled(true);
      setShowLoaderStatus(true);
      await fetchDeployBulkMissions({
        fleetId,
        vehicleId,
        bulkMissionPlans,
      });
      setShowLoaderStatus(false);
      setIsSuccessButtonDisabled(false);
      navigate("/queue_missions");
    }
  };

  const handleClickSuccessButton = async () => {
    if (isSuccessButtonDisabled) return;

    if (status === CurrentStatus.initial) {
      await handleInitial();
      return;
    }
    if (status === CurrentStatus.success) {
      setStatus(CurrentStatus.assignMissions);
      return;
    }
    if (status === CurrentStatus.assignMissions) {
      await handleAssignMissions();
      return;
    }
  };

  const handleClickCancelButton = () => {
    if (status === CurrentStatus.initial) {
      navigate(-1);
    }
    if (status === CurrentStatus.success) {
      setIsSuccessButtonDisabled(false);
      setStatus(CurrentStatus.initial);
    }
    if (status === CurrentStatus.assignMissions) {
      setIsSuccessButtonDisabled(false);
      setStatus(CurrentStatus.success);
    }
  };

  return (
    <AutoMissionGeneratorStyled>
      <AutoMissionGeneratorContentStyled>
        {isShowLoader && <AutoGenerationDeploymentLoader />}
        <PageContentStyled>
          <HeaderStyled>{HEADER_BY_STATUS[status]}</HeaderStyled>
          {status === CurrentStatus.initial && (
            <InitialContent
              fleetSemanticMap={fleetSemanticMap}
              setFleetSemanticMap={setFleetSemanticMap}
              missionPlansFile={missionPlansFile}
              setMissionPlansFile={setMissionPlansFile}
              handleDisableSuccessButton={handleDisableSuccessButton}
            />
          )}
          {status === CurrentStatus.success && (
            <SuccessContent bulkMissionPlans={bulkMissionPlans} />
          )}
          {status === CurrentStatus.assignMissions && (
            <AssignMissionsContent
              setAssignedData={setAssignedData}
              handleDisableSuccessButton={handleDisableSuccessButton}
            />
          )}
        </PageContentStyled>
        <FooterStyled>
          <CancelButtonStyled onClick={handleClickCancelButton}>
            Cancel
          </CancelButtonStyled>
          <SuccessButtonStyled
            isDisabled={isSuccessButtonDisabled}
            onClick={handleClickSuccessButton}
          >
            {BUTTON_BY_STATUS[status]}
          </SuccessButtonStyled>
        </FooterStyled>
      </AutoMissionGeneratorContentStyled>
      <AutoMissionGeneratorBackgroundStyled />
    </AutoMissionGeneratorStyled>
  );
});
