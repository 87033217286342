import styled from "styled-components";

export const DashboardDropdownStyled = styled.div`
  margin-right: 24px;
  position: relative;
`;

interface DashboardDropdownBtnStyled {
  active: boolean;
}

export const DashboardDropdownBtnStyled = styled.div<DashboardDropdownBtnStyled>`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: ${(props) => (props.active ? "#55b8b3" : "#bbbbbb")};

  svg {
    margin-right: 62px;
    transition: all 0.3s;
  }

  path {
    fill: ${(props) => (props.active ? "#55b8b3" : "#bbbbbb")};
    transition: all 0.3s;
  }
`;

export const DashboardDropdownContentStyled = styled.div`
  position: absolute;
  background-color: #ffffff;
  width: 200px;
  right: -10px;
  top: 55px;
  padding: 8px 0;
  z-index: 2;
  border-top: 2px solid #55b8b3;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

export const DashboardDropdownLinkStyled = styled.a`
  display: flex;
  align-items: center;
  height: 44px;
  cursor: pointer;
  color: #606060;
  text-decoration: none;
  padding-left: 18px;
  border-bottom: 1px solid transparent;
  transition: all 0.3s;

  &:hover {
    color: #55b8b3;
    border-bottom: 1px solid #55b8b3;
  }
`;
