import React, { memo, useContext, useEffect } from "react";
import { QueueManagerTableHeader } from "./components/QueueManagerTableHeader/QueueManagerTableHeader";
import { QueueManagerTableBody } from "./components/QueueManagerTableBody/QueueManagerTableBody";

import { QueueManagerWrapperStyled } from "./styles";
import { MissionsPageSearchContext } from "Components/LiveQueueDeployments/SearchContextHelper";

export const QUEUE_MANAGER_WRAPPER = "QueueManagerWrapper";

export const QueueDeploymentsTable = memo(() => {
  const { setSearchValue } = useContext(MissionsPageSearchContext);

  useEffect(
    () => () => {
      setSearchValue?.("");
    },
    []
  );

  return (
    <QueueManagerWrapperStyled id={QUEUE_MANAGER_WRAPPER}>
      <QueueManagerTableHeader />
      <QueueManagerTableBody />
    </QueueManagerWrapperStyled>
  );
});
