import React, { FC, ReactNode, useCallback, useState } from "react";
import { toast } from "react-toastify";

interface DragAndDropFileHOCProps {
  children: (isDragActive: boolean) => ReactNode;
  setFile: (file: File | null) => void;
  acceptableFileFormats: Array<string>;
  errorMessage: string;
}

export const DragAndDropFileHOC: FC<DragAndDropFileHOCProps> = ({
  children,
  setFile,
  acceptableFileFormats,
  errorMessage,
}) => {
  const [isDragActive, setDragActive] = useState(false);

  const dragOverHandler = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    setDragActive(true);
  }, []);
  const dragLeaveHandler = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    setDragActive(false);
  }, []);
  const onDropHandler = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (acceptableFileFormats.includes(file.type)) {
      setFile(file);
    } else {
      setFile(null);
      toast.error(errorMessage);
    }
    setDragActive(false);
  }, []);

  return (
    <div
      onDragOver={dragOverHandler}
      onDragLeave={dragLeaveHandler}
      onDrop={onDropHandler}
    >
      {children(isDragActive)}
    </div>
  );
};
