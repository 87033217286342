import React from "react";

import {
  MissionDeploymentsPageGuard,
  NotForMissionOperatorGuard,
  OnlyForAdminGuard,
} from "./NavigationGuards";
import { RealTimeMapPage } from "Pages/RealTimeMap/RealTimeMap.page";
import { MissionOperatorPage } from "Pages/MissionOperator/MissionOperator.page";
import { AllDeploymentsPage } from "Pages/AllDeployments/AllDeployments.page";
import { WelcomePage } from "Pages/Welcome/Welcome.page";
import { VehiclesListPage } from "Pages/VehiclesList/VehiclesList.page";
import { VehicleTypesListPage } from "Pages/VehicleTypesList/VehicleTypesList.page";
import { FleetsListPage } from "Pages/FleetsList/FleetsList.page";
import { UsersListPage } from "Pages/UsersList/UsersList.page";
import { LiveQueueDeploymentsPage } from "Pages/LiveQueueDeployments/LiveQueueDeployments.page";
import { AutoMissionGeneratorPage } from "Pages/AutoMissionGeneratorPage/AutoMissionGeneratorPage.page";
import { QueueDeploymentsTable } from "Components/QueueDeploymentsTable/QueueDeploymentsTable";
import { LiveDeploymentsTable } from "Components/LiveDeploymentsTable/LiveDeploymentsTable";

export const QUEUE_MISSIONS_PATH = "/queue_missions";
export const LIVE_MISSIONS_PATH = "/live_missions";
export const MISSIONS_PATH = "/missions";
export const AUTO_MISSION_GENERATOR_PATH = "/auto_mission_generator";

export const AuthorizedRouter = [
  {
    path: "",
    element: (
      <NotForMissionOperatorGuard>
        <RealTimeMapPage />
      </NotForMissionOperatorGuard>
    ),
  },
  {
    path: "/operator",
    element: <MissionOperatorPage />,
  },
  {
    path: MISSIONS_PATH,
    element: (
      <MissionDeploymentsPageGuard>
        <AllDeploymentsPage />
      </MissionDeploymentsPageGuard>
    ),
  },
  {
    path: "",
    element: (
      <MissionDeploymentsPageGuard>
        <LiveQueueDeploymentsPage />
      </MissionDeploymentsPageGuard>
    ),
    children: [
      {
        path: LIVE_MISSIONS_PATH,
        element: <LiveDeploymentsTable />,
      },
      {
        path: QUEUE_MISSIONS_PATH,
        element: <QueueDeploymentsTable />,
      },
    ],
  },
  {
    path: "/welcome",
    element: <WelcomePage />,
  },
  {
    path: "/vehicles",
    element: (
      <OnlyForAdminGuard>
        <VehiclesListPage />
      </OnlyForAdminGuard>
    ),
  },
  {
    path: "/vehicletypes",
    element: (
      <OnlyForAdminGuard>
        <VehicleTypesListPage />
      </OnlyForAdminGuard>
    ),
  },
  {
    path: "/fleets",
    element: (
      <OnlyForAdminGuard>
        <FleetsListPage />
      </OnlyForAdminGuard>
    ),
  },
  {
    path: "/people",
    element: (
      <OnlyForAdminGuard>
        <UsersListPage />
      </OnlyForAdminGuard>
    ),
  },
  {
    path: AUTO_MISSION_GENERATOR_PATH,
    element: (
      <OnlyForAdminGuard>
        <AutoMissionGeneratorPage />
      </OnlyForAdminGuard>
    ),
  },
];
