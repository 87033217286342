import React, { FC, useState } from "react";

import { MISSION_DEPLOYMENT_ACTIONS } from "Utils/constants";
import { FMSLogger } from "FMSLogger";
import { EMPTY_ICON } from "Utils";

import LocationOpenedIcon from "../../assets/location-opened.svg";
import LocationOpenedIconBlack from "../../assets/location-black-arrow.svg";
import {
  VehicleItemStyled,
  FriendlyNameStyled,
  DetailsStyled,
  VehicleItemLabelStyled,
  VehicleItemTextStyled,
  VehicleDriveModeStyled,
  VehicleItemLineStyled,
  ImgStyled,
  DetailsBlockStyled,
  TopContentStyled,
  TopLeftContentStyled,
  DetailsIconStyled,
} from "./styles";

const missionDeploymentActionsLogger = FMSLogger.byPrefix(
  MISSION_DEPLOYMENT_ACTIONS
);

type DriveModText = {
  [key: string]: string;
};

const DRIVER_MODE_TEXT: DriveModText = {
  AUTO: "AUTONOMOUS",
  AUTONOMOUS: "AUTONOMOUS",
  MANUAL: "MANUAL DRIVER",
  REMOTE_CONTROL: "REMOTE CONTROL",
  STOPPING: "STOPPING3",
  IDLE: "IDLE",
};

type VehicleItemProps = {
  vehicleData: Vehicle;
  vehicleType: VehicleType | undefined;
  vehicleStatesData: Record<string, VehicleState>;
};

export const VehicleItem: FC<VehicleItemProps> = ({
  vehicleData,
  vehicleType,
  vehicleStatesData,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  if (!vehicleType) {
    return <></>;
  }

  const { driveMode, webstate, vbStatus, state, id, name } = vehicleData;

  const {
    t_drive_mode: tDriveMode,
    t_vehicle_status: tVehicleStatus,
    t_virtual_bumper_status: tVirtualBumperStatus,
    t_web_status: tWebStatus,
  } = vehicleStatesData[id] ?? {};

  const currentDriveMod = DRIVER_MODE_TEXT[tDriveMode || driveMode] || "";
  const currentVehicleStatus = tVehicleStatus || webstate || "UNAVAILABLE";
  const currentVirtualBumperStatus =
    tVirtualBumperStatus || vbStatus || "UNAVAILABLE";
  const currentWebStatus = tWebStatus || state || "UNAVAILABLE";

  const openShowDetails = () => {
    missionDeploymentActionsLogger.debug(`toggle vehicle details for ${id}`);
    setShowDetails(!showDetails);
  };

  return (
    <VehicleItemStyled showDetails={showDetails}>
      <TopContentStyled>
        <ImgStyled src={vehicleType.image?.link || EMPTY_ICON} />
        <TopLeftContentStyled>
          <VehicleItemLineStyled>
            <FriendlyNameStyled data-testid={`vehicle-${name}-name`}>
            {name}
            </FriendlyNameStyled>
            {currentDriveMod && (
              <VehicleDriveModeStyled text={currentDriveMod.toUpperCase()}
              data-testid={`vehicle-${name}-drive-mod`}>
                {currentDriveMod}
              </VehicleDriveModeStyled>
            )}
          </VehicleItemLineStyled>
          <DetailsStyled
            data-testid={`vehicle-${name}-details`}
            showDetails={showDetails}
            onClick={openShowDetails}
          >
            Details
            <DetailsIconStyled
              showDetails={showDetails}
              src={showDetails ? LocationOpenedIconBlack : LocationOpenedIcon}
              alt="Location"
            />
          </DetailsStyled>
        </TopLeftContentStyled>
      </TopContentStyled>
      {showDetails && (
        <DetailsBlockStyled>
          <VehicleItemLabelStyled>Vehicle Type</VehicleItemLabelStyled>
          <VehicleItemTextStyled data-testid={`vehicle-${name}-type`}>
            {vehicleType.name.toLowerCase()}
          </VehicleItemTextStyled>
          <VehicleItemLabelStyled>Vehicle Status</VehicleItemLabelStyled>
          <VehicleItemTextStyled data-testid={`vehicle-${name}-status`}>
          {currentVehicleStatus}</VehicleItemTextStyled>
          <VehicleItemLabelStyled>Virtual Bumper</VehicleItemLabelStyled>
          <VehicleDriveModeStyled
            marginBottom={true}
            text={currentVirtualBumperStatus.toUpperCase()}
            data-testid={`vehicle-${name}-bumper-status`}
          >
            {currentVirtualBumperStatus}
          </VehicleDriveModeStyled>
          <VehicleItemLabelStyled>
            DriveMod Software Status
          </VehicleItemLabelStyled>
          <VehicleDriveModeStyled
            marginBottom={true}
            text={currentWebStatus.toUpperCase()}
            data-testid={`vehicle-${name}-drive-mode-status`}
          >
            {currentWebStatus === "CALL_FOR_TELEOP"
              ? "Vehicle Stuck"
              : currentWebStatus}
          </VehicleDriveModeStyled>
        </DetailsBlockStyled>
      )}
    </VehicleItemStyled>
  );
};
