export const AVALIBLE_ACTIONS_TYPES: Array<MissionActionType> = [1, 2, 4];

export const ACTIONS_TYPES_DICT: {
  [key: number]: string;
} = {
  1: "Go To Point",
  2: "Wait",
  // 3: "Hitch",
  4: "Unhitch",
};

export const WAIT_ACTION_TYPE = 2;
