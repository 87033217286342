import React, { memo, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DragDropContext, DropResult } from "react-beautiful-dnd";

import { getActiveLiveQueueDeploymentByVehicleId } from "Slices/liveQueueDeploymentsPage/selectors";
import { getVehiclesDict } from "Slices/vehicles/selectors";
import { MissionsPageSearchContext } from "Components/LiveQueueDeployments/SearchContextHelper";

import { DeploymentsByVehicleSection } from "../DeploymentsByVehicleSection/VehicleSection";
import { filterDeployments } from "Components/QueueDeploymentsTable/utils";
import { QueueManagerDeploymentData } from "Components/QueueDeploymentsTable/types";

import { QueueManagerTableBodyStyled } from "./styles";
import {
  UNASSIGNED_DEPLOYMENT_KEY,
  getQueueDeployments,
} from "Slices/queueDeploymentsSection";

export const QueueManagerTableBody = memo(() => {
  const queueManagerDeploymentsData = useSelector(getQueueDeployments);
  const vehiclesDict = useSelector(getVehiclesDict);
  const [data, setData] = useState<QueueManagerDeploymentData>(
    queueManagerDeploymentsData
  );
  const activeDeploymentByVehicleId = useSelector(
    getActiveLiveQueueDeploymentByVehicleId
  );

  const { searchValue } = useContext(MissionsPageSearchContext);

  useEffect(() => {
    const filteredData = filterDeployments(
      queueManagerDeploymentsData,
      searchValue
    );
    setData(filteredData);
  }, [queueManagerDeploymentsData, searchValue]);

  const onDragEnd = (result: DropResult): void => {
    const currentData = { ...data };

    if (!result.destination) return;

    const { source, destination } = result;

    const sourceItems = [...data[source.droppableId]];
    const destItems = [...data[destination.droppableId]];

    const [removed] = sourceItems.splice(source.index, 1);

    if (source.droppableId === destination.droppableId) {
      sourceItems.splice(destination.index, 0, removed);
    } else {
      destItems.splice(destination.index, 0, removed);

      currentData[destination.droppableId] = destItems;
    }

    currentData[source.droppableId] = sourceItems;
    setData(currentData);
  };

  return (
    <QueueManagerTableBodyStyled>
      <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
        {Object.entries(data).map(([vehicleId, deployments]) => {
          if (vehicleId === UNASSIGNED_DEPLOYMENT_KEY) {
            return (
              <DeploymentsByVehicleSection
                key={vehicleId}
                vehicleId={vehicleId}
                vehicleName={"Fleet Unassigned"}
                deployments={deployments}
              />
            );
          }
          const activeDeployment = activeDeploymentByVehicleId[vehicleId];
          return (
            <DeploymentsByVehicleSection
              key={vehicleId}
              vehicleId={vehicleId}
              vehicleName={vehiclesDict[vehicleId]?.name ?? "..."}
              deployments={deployments}
              activeDeployment={activeDeployment}
            />
          );
        })}
      </DragDropContext>
    </QueueManagerTableBodyStyled>
  );
});
