import {
  fetchGetVehicles,
  fetchPostVehicles,
  fetchPatchVehicle,
} from "./actions";
import {
  getVehiclesTransportFacilityModals,
  getVehiclesTransportFacilityModalsById,
  getIsVehiclesLoaded,
  getIsVehiclesLoadingError,
  getVehiclesByCurrentSemanticMap,
  getVehicles,
  getVehicleById,
} from "./selectors";
import VehiclesSlice from "./reducers";

// actions
export const { addNewVehicle, updateVehicle, resetVehicles, updateVehicles } =
  VehiclesSlice.actions;

// async actions
export { fetchGetVehicles, fetchPostVehicles, fetchPatchVehicle };

// selectors
export {
  getVehiclesTransportFacilityModals,
  getVehiclesTransportFacilityModalsById,
  getIsVehiclesLoaded,
  getIsVehiclesLoadingError,
  getVehiclesByCurrentSemanticMap,
  getVehicles,
  getVehicleById,
};

// reducer
export default VehiclesSlice.reducer;
