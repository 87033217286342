import React, { FC, memo, useMemo } from "react";
import { useSelector } from "react-redux";

import { getStopsByCurrentSemanticMapDict } from "Slices/stops";
import { ReactComponent as RectangleIcon } from "../../assets/rectangleIcon.svg";
import { ReactComponent as ActionStatusIcon } from "../../assets/actionStatusIcon.svg";

import {
  DeploymentDetailsBodyWrapperStyled,
  LoopsBodyStyled,
  CounterLoopsBodyStyled,
  CounterLoopsStyled,
  DividerLoopsCountersStyled,
  ActionsBodyStyled,
  ActionRowStyled,
  CheckboxActionBodyStyled,
  ActionNameStyled,
  ProgressBarStyled,
} from "./styles";
import { getActionName } from "Utils";

type DeploymentDetailsBodyProps = {
  totalLoops: number;
  passedLoops: number | null;
  passedActions: number | null;
  actions: Array<MissionAction>;
};

export const DeploymentDetailsBody: FC<DeploymentDetailsBodyProps> = memo(
  ({ totalLoops, passedLoops, actions, passedActions }) => {
    const isInfinityLoops = Number(totalLoops) === -1;
    const stopsObject = useSelector(getStopsByCurrentSemanticMapDict);
    const formattedLoopsActions = useMemo(() => {
      const preparedLoopsActions = [];
      if (isInfinityLoops) {
        preparedLoopsActions.push(actions);
      } else {
        for (let i = 0; i < Number(totalLoops); i++) {
          preparedLoopsActions.push([...actions]);
        }
      }
      return preparedLoopsActions;
    }, [actions, totalLoops]);

    const isActionReached = (currentLoop: number, currentAction: number) => {
      if (passedLoops === null || passedActions === null) return false;
      const noStartedMission = passedLoops === 0 && passedActions === 0;
      const isLoopReached = currentLoop <= passedLoops;
      const isActionReached = currentAction <= passedActions;

      if (noStartedMission) return false;
      if (isInfinityLoops) return isActionReached;
      if (isLoopReached) return true;
      if (isActionReached && currentLoop - 1 === passedLoops) return true;
      else return false;
    };

    return (
      <DeploymentDetailsBodyWrapperStyled>
        {formattedLoopsActions.map((loopActions, loopIndex) => (
          <LoopsBodyStyled key={loopIndex}>
            <CounterLoopsBodyStyled>
              {isInfinityLoops ? "Cycle Total:" : "Cycle:"}
              <CounterLoopsStyled>
                {isInfinityLoops ? (passedLoops ?? -1) + 1 : loopIndex + 1}
              </CounterLoopsStyled>
              {!isInfinityLoops && (
                <>
                  <DividerLoopsCountersStyled>
                    <RectangleIcon />
                  </DividerLoopsCountersStyled>
                  <CounterLoopsStyled>{totalLoops}</CounterLoopsStyled>
                </>
              )}
            </CounterLoopsBodyStyled>
            <ActionsBodyStyled>
              {loopActions.map(
                ({ id, type, stopPk, duration }, actionIndex) => (
                  <>
                    <ActionRowStyled key={id}>
                      <CheckboxActionBodyStyled
                        isActionReached={isActionReached(
                          loopIndex + 1,
                          actionIndex + 1
                        )}
                      >
                        <ActionStatusIcon />
                      </CheckboxActionBodyStyled>
                      <ActionNameStyled>
                        {getActionName(
                          type,
                          stopsObject[stopPk].name,
                          duration
                        )}
                      </ActionNameStyled>
                    </ActionRowStyled>
                    {actionIndex + 1 < Number(actions.length) && (
                      <ProgressBarStyled
                        isActionReached={isActionReached(
                          loopIndex + 1,
                          actionIndex + 1
                        )}
                      />
                    )}
                  </>
                )
              )}
            </ActionsBodyStyled>
          </LoopsBodyStyled>
        ))}
      </DeploymentDetailsBodyWrapperStyled>
    );
  }
);
