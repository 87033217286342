import React, { ReactNode, FC } from "react";

import { TextStyled } from "./styles";

type TextProps = {
  children?: ReactNode;
};

export const Text: FC<TextProps> = ({ children }) => (
  <TextStyled>{children}</TextStyled>
);
