import { createSlice } from "@reduxjs/toolkit";
import { getTodayEndISO, getTodayStartISO } from "Utils";

import { getSavedPeriod, getSavedVehiclesIds } from "./utils";
import { RowData } from "Components/NewList/types";

export const getDefaultPeriod = () => ({
  startDate: getTodayStartISO(),
  endDate: getTodayEndISO(),
});

type AllDeploymentsPage = {
  currentFleetId: string | null;
  period: MissionPlannerPeriodFilter | null;
  searchValue: string;
  activeTab: MissionPlannerTabName;
  headerData: {
    name: string;
    assignedTo: string;
    status: string;
    actions: string;
    loops: string;
  };
  limit: number;
  allMissionsCount: number;
  completedMissionsCount: number;
  deployedMissionsCount: number;
  deploymentsQueue: null | Array<Deployment | LimitedDeployment>;
  selectedVehicleIds: Array<string>;
  isUpdating: boolean;
  isMissionsUpdatesPaused: null | boolean;
  missionsUpdatesStorage: Array<Deployment | LimitedDeployment | never>;
};

const initialState: AllDeploymentsPage = {
  currentFleetId: null,
  period: null,
  searchValue: "",
  activeTab: "All",
  headerData: {
    name: "Mission Name",
    assignedTo: "Vehicle Assigned",
    status: "Status",
    actions: "Actions",
    loops: "Cycles",
  },
  limit: 20,
  allMissionsCount: 0,
  completedMissionsCount: 0,
  deployedMissionsCount: 0,
  deploymentsQueue: null,
  selectedVehicleIds: [],
  isUpdating: false,
  isMissionsUpdatesPaused: false,
  missionsUpdatesStorage: [],
};

const cachedInitialState = {
  ...initialState,
  period: getSavedPeriod(),
  selectedVehicleIds: getSavedVehiclesIds(),
};

export const Slice = createSlice({
  name: "allDeploymentsPage",
  initialState: cachedInitialState,
  reducers: {
    resetMissionPlannerData: () => initialState,
    setIsMissionPlannerUpdating: (state, action) => {
      state.isUpdating = action.payload;
      return state;
    },
    setCurrentFleetId: (state, action) => {
      state.currentFleetId = action.payload;
      return state;
    },
    setCurrentFleetPeriod: (state, action) => {
      state.period = action.payload;
      return state;
    },
    setMissionRowsLimit: (state, action) => {
      state.limit = action.payload;
      return state;
    },
    setCurrentSearchValue: (state, action) => {
      state.searchValue = action.payload;
      return state;
    },
    setHeaderData: (state, action) => {
      state.headerData = action.payload;
      return state;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
      return state;
    },
    setMissionCounts: (state, action) => {
      state.allMissionsCount = action.payload.count;
      state.deployedMissionsCount = action.payload.deployedCount;
      state.completedMissionsCount = action.payload.completedCount;
      return state;
    },
    resetMissionsQueue: (state) => {
      state.deploymentsQueue = null;
      state.allMissionsCount = 0;
      state.completedMissionsCount = 0;
      state.deployedMissionsCount = 0;
      state.limit = 20;
      return state;
    },
    setMissionsQueue: (state, action) => {
      state.deploymentsQueue = action.payload;
      return state;
    },
    reorderMissionsQueue: (state, action) => {
      const reorderedMissions = action.payload
        ?.map?.((id: RowData["id"]) => {
          const foundedReorderedItem = state.deploymentsQueue?.find(
            (item) => item.id === id
          );
          return foundedReorderedItem;
        })
        .filter(Boolean);

      state.deploymentsQueue = reorderedMissions
        ? reorderedMissions
        : state.deploymentsQueue;
      return state;
    },
    updateDeploymentsQueue: (state, action) => {
      const receivedDeployment: Deployment = action.payload;
      if (!receivedDeployment) return state;

      if (state.isMissionsUpdatesPaused) {
        state.missionsUpdatesStorage.push(action.payload?.data);
        return state;
      }

      const isNewDeployment = !state.deploymentsQueue?.find(
        (deployment) => deployment?.id === receivedDeployment?.id
      );

      if (isNewDeployment) {
        const currentSelectedVehicleIds = state.selectedVehicleIds;

        const noSelectedVehicles = currentSelectedVehicleIds.length === 0;
        const newMissionIsPartOfSelectedVehicles =
          currentSelectedVehicleIds.includes(
            receivedDeployment?.vehicleId ?? ""
          );

        if (noSelectedVehicles || newMissionIsPartOfSelectedVehicles) {
          state.deploymentsQueue = [
            receivedDeployment,
            ...(state.deploymentsQueue?.length ? state.deploymentsQueue : []),
          ];
        }
      } else {
        state.deploymentsQueue =
          state.deploymentsQueue?.map((deployment: LimitedDeployment) =>
            deployment.id === receivedDeployment?.id
              ? receivedDeployment
              : deployment
          ) ?? [];
      }
      return state;
    },
    setSelectedVehicleIds: (state, action) => {
      state.selectedVehicleIds = action.payload.selected
        ? [...state.selectedVehicleIds, action.payload.vehicleId]
        : state.selectedVehicleIds.filter(
            (selectedVehicleId) =>
              selectedVehicleId !== action.payload.vehicleId
          );
      return state;
    },
    resetPagination: (state) => {
      state.limit = 20;
      return state;
    },
    pauseMissionsUpdates: (state) => {
      state.isMissionsUpdatesPaused = true;
      return state;
    },
    continueMissionsUpdates: (state) => {
      state.isMissionsUpdatesPaused = false;
      return state;
    },
    resetMissionsUpdatesStorage: (state) => {
      state.missionsUpdatesStorage = [];
      return state;
    },
  },
});

export default Slice;
