import { memo, useContext, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchLiveQueueDeployment,
  updateLiveQueueDeployments,
  getLiveQueueDeploymentsPageFleetId,
} from "Slices/liveQueueDeploymentsPage";
import { DEPLOYMENTS_WS, WS_SPINNER_LOGS } from "Utils/constants";
import { useFMSWebsocket } from "Utils/webSocketUtils";
import { WebsocketLoaderContext } from "Pages/AuthorizedApp/WebsocketLoaderController";
import { FMSLogger } from "FMSLogger";
import { isDeploymentValid } from "Utils";
import {
  getIsQueueDeploymentsUpdatesPaused,
  getQueueDeploymentsUpdatesStorage,
  resetQueueDeploymentsSectionUpdatesStorage,
  updateQueueDeploymentsSection,
} from "Slices/queueDeploymentsSection";

const wsSpinnerLogs = FMSLogger.byPrefix(
  `${WS_SPINNER_LOGS}:LiveQueueDeployments`
);

export const LiveQueueDeploymentsController = memo(() => {
  const currentFleetId = useSelector(getLiveQueueDeploymentsPageFleetId);

  const isQueueDeploymentsUpdatesPaused = useSelector(
    getIsQueueDeploymentsUpdatesPaused
  );
  const queueDeploymentsUpdatesStorage = useSelector(
    getQueueDeploymentsUpdatesStorage
  );
  const dispatch = useDispatch();

  const setIsWSConnecting = useContext(WebsocketLoaderContext);

  useLayoutEffect(
    () => () => {
      wsSpinnerLogs.debug("unmount component spinner off");
      setIsWSConnecting?.(false);
    },
    []
  );

  useEffect(() => {
    if (currentFleetId) {
      wsSpinnerLogs.debug("new fleet id spinner on");
      setIsWSConnecting?.(true);
    } else {
      wsSpinnerLogs.debug("no fleet id spinner off");
      setIsWSConnecting?.(false);
    }
  }, [currentFleetId]);

  useFMSWebsocket({
    url: "/fleets/deployment_states/",
    subscriptionData: currentFleetId,
    loggerFlag: DEPLOYMENTS_WS,
    getDataForSubscribe: (subscriptionId) => ({
      fleet_ids: [subscriptionId],
    }),
    getDataForUnsubscribe: (subscriptionId) => ({
      fleet_ids: [subscriptionId],
    }),
    handleInitialMessage: () => {
      wsSpinnerLogs.debug("create message spinner off");
      setIsWSConnecting?.(false);
    },
    handleMessage: (data) => {
      if (data.deployment && !isDeploymentValid(data.deployment)) {
        delete data.deployment;
      }
      const shouldIgnoreUpdate =
        !data?.deployment || data?.deployment?.state === "STATE_RUNNING";
      if (shouldIgnoreUpdate) return;

      dispatch(updateLiveQueueDeployments(data.deployment));
      dispatch(updateQueueDeploymentsSection(data.deployment));
    },
  });

  // get deployment queue if filters has been changed
  useEffect(() => {
    if (!currentFleetId) return;
    dispatch(fetchLiveQueueDeployment(currentFleetId));
  }, [currentFleetId]);

  // update deployments from storage
  useEffect(() => {
    if (
      isQueueDeploymentsUpdatesPaused !== false ||
      queueDeploymentsUpdatesStorage.length === 0
    )
      return;

    queueDeploymentsUpdatesStorage.forEach((update) => {
      dispatch(updateQueueDeploymentsSection({ data: update }));
    });
    dispatch(resetQueueDeploymentsSectionUpdatesStorage());
  }, [isQueueDeploymentsUpdatesPaused, queueDeploymentsUpdatesStorage]);

  return null;
});
