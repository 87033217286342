import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchPatchMissionPlan } from "Slices/missionPlans";
import { getVehiclesUpdateData } from "Slices/vehiclesState";
import { AssignModal } from "Components/Modals/AddEditMissionModal/components/AssignModal";
import { ConfirmMissionsModal } from "Components/Modals/AddEditMissionModal/components/ConfirmMissionsModal/ConfirmMissionsModal";
import { MISSION_DEPLOYMENT_ACTIONS } from "Utils/constants";
import { FMSLogger } from "FMSLogger";

import { MissionPlansList } from "./components/MissionPlansList/MissionPlansList";

const missionDeploymentActionsLogger = FMSLogger.byPrefix(
  MISSION_DEPLOYMENT_ACTIONS
);

type MissionPlansListProps = {
  vehicleName?: string;
  vehicleId?: string;
  deployPlanMissionAction: (data: Omit<Deployment, "actions">) => void;
  cancelAction: () => void;
  previousMode: "CONTINUE" | "CANCEL";
  currentFleetId: string;
};

export const DeployedMissionList: FC<MissionPlansListProps> = ({
  vehicleName,
  vehicleId,
  deployPlanMissionAction,
  cancelAction,
  previousMode,
  currentFleetId,
}) => {
  const dispatch = useDispatch();
  const [savedMissionsDisabled, setSavedMissionsDisabled] = useState(false);
  const [isShowAssignPicker, setShowAssignPicker] = useState(false);
  const [currentMissionData, setCurrentMissionData] =
    useState<null | MissionPlan>(null);
  const [confirmOverrideMissionData, setConfirmOverrideMissionData] =
    useState<null | {
      text: string;
      confirmAction: () => void;
      cancelAction: () => void;
    }>(null);

  const vehiclesUpdateData = useSelector(getVehiclesUpdateData);

  const deployAction = (missionData: MissionPlan) => () => {
    if (savedMissionsDisabled) {
      return;
    }
    setCurrentMissionData(missionData);
    setShowAssignPicker(true);
  };

  const deploy = async (
    missionData: MissionPlan | null,
    assignedData: {
      vehicleId?: string;
      fleetId: string;
      loops: number;
    }
  ) => {
    if (!missionData) return;
    setSavedMissionsDisabled(true);
    await deployPlanMissionAction({
      ...missionData,
      ...assignedData,
      previous: previousMode,
      missionPlanId: missionData.plan.id,
    });
    await dispatch(
      fetchPatchMissionPlan({
        id: missionData.id,
        data: {
          timeStamp: String(new Date().toISOString()),
        },
        hideToast: true,
      })
    );
    setSavedMissionsDisabled(false);
    setConfirmOverrideMissionData(null);
  };

  const deployWithOverride = (
    missionData: MissionPlan | null,
    assignedData: {
      vehicleId: string;
      loops: number;
      fleetId: string;
    }
  ) => {
    if (
      previousMode === "CANCEL" &&
      vehiclesUpdateData &&
      vehiclesUpdateData[assignedData.vehicleId] &&
      vehiclesUpdateData[assignedData.vehicleId].mission_status ===
        "IN_PROGRESS"
    ) {
      setConfirmOverrideMissionData({
        text: "This vehicle has a mission in progress. Would you like to cancel the current mission and redeploy this one?",
        confirmAction: () => {
          missionDeploymentActionsLogger.debug("deploy mission with override");
          deploy(missionData, assignedData);
        },
        cancelAction: () => {
          missionDeploymentActionsLogger.debug(
            "cancel deploy mission with override"
          );
          setConfirmOverrideMissionData(null);
        },
      });
      return;
    }
    missionDeploymentActionsLogger.debug("deploy mission");
    deploy(missionData, assignedData);
  };

  return (
    <>
      {!confirmOverrideMissionData &&
        isShowAssignPicker &&
        currentMissionData && (
          <AssignModal
            currentFleetId={currentFleetId}
            vehicleId={vehicleId}
            vehicleName={vehicleName}
            currentMissionActions={currentMissionData.plan.actions}
            handleVehiclePick={(vehicleId, selectedLoopsValue, fleetId) => {
              deployWithOverride(currentMissionData, {
                vehicleId,
                loops: Number(selectedLoopsValue),
                fleetId,
              });
              setShowAssignPicker(false);
            }}
            handleFleetPick={(id, selectedLoopsValue) => {
              missionDeploymentActionsLogger.debug("deploy mission to fleet");
              deploy(currentMissionData, {
                fleetId: id,
                loops: Number(selectedLoopsValue),
              });
              setShowAssignPicker(false);
            }}
            cancelAction={() => setShowAssignPicker(false)}
          />
        )}
      {confirmOverrideMissionData && (
        <ConfirmMissionsModal {...confirmOverrideMissionData} />
      )}
      <MissionPlansList
        isShowAssignPicker={isShowAssignPicker}
        cancelAction={cancelAction}
        deployAction={deployAction}
        savedMissionsDisabled={savedMissionsDisabled}
        currentFleetId={currentFleetId}
      />
    </>
  );
};
