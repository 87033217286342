import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import { getIsVehiclesLoaded, getVehicles } from "Slices/vehicles";

const getOperatorMissionState = (state: RootState) =>
  state.operatorData.operatorMissionState;
const getOperatorDeployments = (state: RootState) =>
  state.operatorData.operatorDeployments;
export const getOperatorMissionStatus = createSelector(
  getOperatorMissionState,
  (operatorMissionState) => operatorMissionState?.status
);
export const getMissionOperatorPickedFleetId = (state: RootState) =>
  state.operatorData.pickedFleetId;

export const getIsPickedFleetHasNoVehicles = createSelector(
  getMissionOperatorPickedFleetId,
  getVehicles,
  getIsVehiclesLoaded,
  (fleetId, vehicles, isVehiclesLoaded) => {
    return (
      isVehiclesLoaded &&
      vehicles.filter((vehicle) => vehicle.fleetId === fleetId).length === 0
    );
  }
);

export const getMissionOperatorFleetIdForSubscribe = (state: RootState) =>
  state.operatorData.operatorMissionState?.fleetId;

export const getOperatorMissionOrder = createSelector(
  getOperatorMissionState,
  (operatorMissionState) => operatorMissionState?.order
);

export const getInProgressOperatorDeploymentsCount = createSelector(
  getOperatorDeployments,
  (deployments) =>
    deployments.length > 0
      ? deployments.filter(
          (deployment) =>
            deployment?.vehicleDeployment?.status === "IN_PROGRESS"
        ).length
      : "..."
);
export const getInQueueOperatorDeploymentsCount = createSelector(
  getOperatorDeployments,
  (deployments) =>
    deployments.length > 0
      ? deployments.filter(
          (deployment) =>
            deployment?.vehicleDeployment?.status === "NEW" ||
            deployment?.vehicleDeployment?.status === "SENT" ||
            deployment?.vehicleDeployment?.status === "RECEIVED"
        ).length
      : "..."
);
