import React, {
  createContext,
  Dispatch,
  memo,
  SetStateAction,
  useState,
} from "react";
import { ChildrenProps } from "Utils/types";
import { WebsocketLoader } from "Components/common/Loader/Loader";

export type WebsocketLoaderContextType = Dispatch<
  SetStateAction<boolean>
> | null;

export const WebsocketLoaderContext =
  createContext<WebsocketLoaderContextType>(null);

export const WebsocketLoaderController = memo(({ children }: ChildrenProps) => {
  const [isWSConnecting, setIsWSConnecting] = useState(false);
  return (
    <WebsocketLoaderContext.Provider value={setIsWSConnecting}>
      {isWSConnecting && <WebsocketLoader />}
      {children}
    </WebsocketLoaderContext.Provider>
  );
});
