import {
  getOperatorMissionStatus,
  getMissionOperatorPickedFleetId,
  getIsPickedFleetHasNoVehicles,
  getMissionOperatorFleetIdForSubscribe,
  getOperatorMissionOrder,
  getInProgressOperatorDeploymentsCount,
  getInQueueOperatorDeploymentsCount,
} from "./selectors";
import OperatorDataSlice from "./reducers";

// actions
export const {
  setInitialMissionOperatorData,
  setMissionOperatorFleetId,
  resetMissionOperatorData,
  updateMissionOperatorData,
} = OperatorDataSlice.actions;

// selectors
export {
  getOperatorMissionStatus,
  getMissionOperatorPickedFleetId,
  getIsPickedFleetHasNoVehicles,
  getMissionOperatorFleetIdForSubscribe,
  getOperatorMissionOrder,
  getInProgressOperatorDeploymentsCount,
  getInQueueOperatorDeploymentsCount,
};

// reducer
export default OperatorDataSlice.reducer;
