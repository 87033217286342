import React from "react";

import { ReactComponent as PolygonIcon } from "../assets/polygonIcon.svg";
import { ReactComponent as RectangleIcon } from "../assets/rectangleIcon.svg";
import { TableFieldBodyStyled } from "Components/NewList/styles";
import {
  CounterBodyStyled,
  CounterStopsStyled,
  CounterLoopsStyled,
  DividerStopsCountersStyled,
  DividerLoopsCountersStyled,
} from "./styles";

export const getActionsField = (
  passedActions: number | string,
  totalActions: number | string
) => {
  const zeroPassedActions = Number(passedActions) === 0;

  return (
    <TableFieldBodyStyled>
      <CounterBodyStyled>
        <PolygonIcon />
        <CounterStopsStyled zeroCounter={zeroPassedActions}>
          {passedActions}
        </CounterStopsStyled>
      </CounterBodyStyled>
      <DividerStopsCountersStyled>Out of</DividerStopsCountersStyled>
      <CounterBodyStyled>
        <PolygonIcon />
        <CounterStopsStyled>{totalActions}</CounterStopsStyled>
      </CounterBodyStyled>
    </TableFieldBodyStyled>
  );
};

export const getLoopsField = (
  passedLoops: number | string,
  totalLoops: number | string
) => {
  const isInfinity = Number(totalLoops) === -1;
  const zeroPassedLoops = Number(passedLoops) === 0;

  return isInfinity ? (
    <CounterLoopsStyled zeroCounter={zeroPassedLoops} isInfinity={isInfinity}>
      {passedLoops}
    </CounterLoopsStyled>
  ) : (
    <TableFieldBodyStyled>
      <CounterLoopsStyled zeroCounter={zeroPassedLoops}>
        {passedLoops}
      </CounterLoopsStyled>
      <DividerLoopsCountersStyled>
        <RectangleIcon />
      </DividerLoopsCountersStyled>
      <CounterLoopsStyled>{totalLoops}</CounterLoopsStyled>
    </TableFieldBodyStyled>
  );
};
