import React, { memo } from "react";
import { MissionListSearch } from "../MissionListSearch";
import { MissionListTabs } from "../MissionListTabs";
import { MissionListControlsStyled } from "./styles";

export const AllDeploymentsListControls = memo(() => (
  <MissionListControlsStyled>
    <MissionListTabs />
    <MissionListSearch />
  </MissionListControlsStyled>
));
