import React, { FC, memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ButtonComponent } from "Components/common/ButtonComponent/Button.component";
import { AsideLoader } from "Components/common/Loader/Loader";
import {
  getIsMissionPlansLoadedForCurrentSemanticMap,
  getMissionPlansByCurrentSemanticMap,
} from "Slices/missionPlans/selectors";
import { fetchDeleteMissionPlan } from "Slices/missionPlans";
import { MISSION_DEPLOYMENT_ACTIONS } from "Utils/constants";
import { useMissionPlansLoader } from "Utils/loaders";
import { ADD_EDIT_MISSION_MODAL, toggleModal } from "Slices/modals";
import { FMSLogger } from "FMSLogger";
import { EmptyCover } from "../EmptyCover";
import { MissionPlanItem } from "../MissionPlaItem/MissionPlanItem";
import { getCurrentSemanticMapId } from "Slices/appState";
import { MissionDataLoadingError } from "Components/common/MissionDataLoadingError";

import { MissionListContentStyled } from "../../styles";

const missionDeploymentActionsLogger = FMSLogger.byPrefix(
  MISSION_DEPLOYMENT_ACTIONS
);

type MissionListProps = {
  isShowAssignPicker: boolean;
  cancelAction: () => void;
  deployAction: (missionAsset: MissionPlan) => VoidFunction;
  savedMissionsDisabled: boolean;
  currentFleetId: string;
};

export const MissionPlansList: FC<MissionListProps> = memo(
  ({
    isShowAssignPicker,
    cancelAction,
    deployAction,
    savedMissionsDisabled,
    currentFleetId,
  }) => {
    const dispatch = useDispatch();
    const missionPlansForCurrentSemanticMap = useSelector(
      getMissionPlansByCurrentSemanticMap
    );
    const isMissionPlansLoadedForCurrentSemanticMap = useSelector(
      getIsMissionPlansLoadedForCurrentSemanticMap
    );
    const deletePlanMissionAction = (id: string) =>
      dispatch(fetchDeleteMissionPlan(id));

    const currentSemanticMapId = useSelector(getCurrentSemanticMapId);

    const { isMissionPlansLoading, isMissionPlansLoadingError } =
      useMissionPlansLoader(currentSemanticMapId);

    return (
      <MissionListContentStyled isShowAssignPicker={isShowAssignPicker}>
        {isMissionPlansLoading && <AsideLoader />}
        {isMissionPlansLoadingError && (
          <MissionDataLoadingError errorMessage="Error loading missions" />
        )}
        {isMissionPlansLoadedForCurrentSemanticMap && (
          <>
            {missionPlansForCurrentSemanticMap.length === 0 && (
              <EmptyCover
                btnClick={() => {
                  missionDeploymentActionsLogger.debug(
                    "new mission from empty cover"
                  );
                  dispatch(
                    toggleModal({
                      type: ADD_EDIT_MISSION_MODAL,
                      data: { fleetId: currentFleetId },
                    })
                  );
                }}
              />
            )}
            {missionPlansForCurrentSemanticMap.map((missionPlan) => (
              <MissionPlanItem
                key={missionPlan.id}
                missionData={missionPlan}
                deletePlanMissionAction={deletePlanMissionAction}
                savedMissionsDisabled={savedMissionsDisabled}
                deployAction={deployAction(missionPlan)}
                currentFleetId={currentFleetId}
              />
            ))}
            <ButtonComponent
              clickAction={() => {
                missionDeploymentActionsLogger.debug("cancel on mission list");
                cancelAction();
              }}
              type="cancel"
              posCenter={true}
            >
              Cancel
            </ButtonComponent>
          </>
        )}
      </MissionListContentStyled>
    );
  }
);
