import styled from "styled-components";

export const ConfirmMissionsModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 594px;
  height: 100%;
  position: fixed;
  background: rgba(37, 63, 115, 0.5);
  top: 0;
  left: 0;
  z-index: 1;
`;

export const ConfirmMissionsModalContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  width: 440px;
  padding: 40px;
  height: max-content;
  background: #ffffff;
  z-index: 1;
  border-radius: 4px;
`;

export const ConfirmMissionsModalButtonsStyled = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  width: 80%;

  button {
    width: 120px;
    min-width: 120px;
  }
`;

export const ConfirmMissionsModalTextStyled = styled.div`
  display: flex;
  font-weight: normal;
  font-size: 16px;
  line-height: 14px;
  margin-bottom: 8px;
`;
