import { RootState } from "../../index";
import { createSelector } from "@reduxjs/toolkit";
import { EMPTY_OBJECT } from "Utils/index";
import { UNASSIGNED_DEPLOYMENT_KEY } from "Slices/queueDeploymentsSection/index";

export const getIsQueueDeploymentsUpdatesPaused = (state: RootState) =>
  state.queueDeploymentsSection.isQueueDeploymentsUpdatesPaused;
export const getQueueDeploymentsUpdatesStorage = (state: RootState) =>
  state.queueDeploymentsSection.queueDeploymentsUpdatesStorage;
const getQueueDeploymentsSection = (state: RootState) =>
  state.queueDeploymentsSection.deployments;

const addDeploymentsByVehicle = (
  store: Record<VehicleId, Array<Deployment | LimitedDeployment>>,
  vehicleId: string,
  deployment: Deployment | LimitedDeployment
) => {
  if (store[vehicleId]) {
    store[vehicleId] = store[vehicleId].concat(deployment);
  } else {
    store[vehicleId] = [deployment];
  }
};

export const getQueueDeployments = createSelector(
  getQueueDeploymentsSection,
  (deployments) => {
    if (!deployments) return EMPTY_OBJECT;
    return deployments.reduce(
      (
        acc: Record<VehicleId, Array<Deployment | LimitedDeployment>>,
        deployment
      ) => {
        if (
          ["NEW", "SENT", "RECEIVED"].includes(
            deployment?.vehicleDeployment?.status ?? ""
          ) ||
          !deployment.vehicleDeployment
        ) {
          addDeploymentsByVehicle(
            acc,
            deployment.vehicleId ?? UNASSIGNED_DEPLOYMENT_KEY,
            deployment
          );
        }
        return acc;
      },
      {}
    );
  }
);
