import React, { memo, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";

import { getSemanticMapModalItems } from "Slices/semanticMaps/selectors";
import { Dropdown } from "Components/Modals/ListDataPagesModals/components/Dropdown";
import { ReactComponent as InfoIcon } from "../../assets/info.svg";
import { ReactComponent as UploadedFileIcon } from "../../assets/uploadedFile.svg";
import { MessageStyled, PickerStyled } from "../../styles";
import { MissionPlansFileLoader } from "../MissionPlansFileLoader/MissionPlansFileLoader";

import {
  InitialContentStyled,
  UploadedFileContentStyled,
  UploadedFileInfoStyled,
  RemoveFileButtonStyled,
  UploadFileWrapperStyled,
} from "./styles";

export type InitialContentProps = {
  handleDisableSuccessButton?: (value: boolean) => void;
  missionPlansFile: File | null;
  setMissionPlansFile: React.Dispatch<React.SetStateAction<File | null>>;
  fleetSemanticMap: ModalItem | null;
  setFleetSemanticMap: React.Dispatch<React.SetStateAction<ModalItem | null>>;
};

export const InitialContent = memo<InitialContentProps>(
  ({
    handleDisableSuccessButton,
    missionPlansFile,
    setMissionPlansFile,
    fleetSemanticMap,
    setFleetSemanticMap,
  }) => {
    const semanticMapModalItems = useSelector(getSemanticMapModalItems);
    const changeAction = useCallback(
      (zone: ModalItem | null) => setFleetSemanticMap(zone),
      []
    );

    useEffect(() => {
      if (!handleDisableSuccessButton) return;
      if (missionPlansFile && fleetSemanticMap) {
        handleDisableSuccessButton(false);
        return;
      }
      handleDisableSuccessButton(true);
    }, [missionPlansFile, fleetSemanticMap]);

    return (
      <InitialContentStyled>
        <MessageStyled>
          <InfoIcon />
          Welcome to Cyngn’s automatic mission generator! This feature is in
          beta
        </MessageStyled>
        <PickerStyled>
          <span>You are generating missions in Zone</span>
          <Dropdown
            isIncludesEmptyValue
            currentValue={fleetSemanticMap}
            options={semanticMapModalItems}
            changeAction={changeAction}
          />
        </PickerStyled>
        <UploadFileWrapperStyled>
          <span>Upload your File</span>
          {missionPlansFile ? (
            <UploadedFileContentStyled>
              <UploadedFileInfoStyled>
                <UploadedFileIcon />
                <span>File Uploaded</span>
                {missionPlansFile?.name}
              </UploadedFileInfoStyled>
              <RemoveFileButtonStyled onClick={() => setMissionPlansFile(null)}>
                remove
              </RemoveFileButtonStyled>
            </UploadedFileContentStyled>
          ) : (
            <MissionPlansFileLoader setFile={setMissionPlansFile} />
          )}
        </UploadFileWrapperStyled>
      </InitialContentStyled>
    );
  }
);
