import React, { FC, memo } from "react";

import { ReactComponent as ErrorInfoIcon } from "Components/common/assets/errorInfoIcon.svg";
import { MissionDataLoadingErrorStyled, TextStyled } from "./styles";

type MissionDataLoadingErrorProps = {
  errorMessage: string;
};

export const MissionDataLoadingError: FC<MissionDataLoadingErrorProps> = memo(
  ({ errorMessage }) => (
    <MissionDataLoadingErrorStyled>
      <ErrorInfoIcon />
      <TextStyled>{errorMessage}</TextStyled>
    </MissionDataLoadingErrorStyled>
  )
);
