import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { prepareVehiclesData } from ".";
import { EMPTY_ARRAY, sortByColumn } from "Utils";

type VehiclesDataState = {
  assignedDeploymentsByVehicleId: Record<
    string,
    LimitedDeployment | Deployment
  >;
  driveModeStatusesByVehicleId: Record<string, VehicleState["t_drive_mode"]>;
  vehicles: Array<VehiclesPageVehicle>;
  fleets: Array<Fleet>;
  vehicleTypes: Array<VehicleType>;
};

const initialState: VehiclesDataState = {
  assignedDeploymentsByVehicleId: {},
  driveModeStatusesByVehicleId: {},
  vehicles: EMPTY_ARRAY,
  fleets: [],
  vehicleTypes: [],
};

export const Slice = createSlice({
  name: "vehiclesPage",
  initialState,
  reducers: {
    resetVehiclesPageData: () => initialState,
    setVehiclesPageDeployments: (state, action) => {
      state.assignedDeploymentsByVehicleId = action.payload.reduce(
        (acc: Record<string, LimitedDeployment>, item: Deployment) => {
          if (!item.vehicleDeployment || !item.vehicleId) return acc;
          acc[item.vehicleId] = item;
          return acc;
        },
        {}
      );
      state.vehicles = prepareVehiclesData(state);
      return state;
    },
    setVehiclesPageVehicles: (state, action) => {
      state.vehicles = prepareVehiclesData({
        ...state,
        vehicles: action.payload,
      });
      return state;
    },
    setVehiclesPageFleets: (state, action) => {
      state.fleets = action.payload;
      state.vehicles = prepareVehiclesData(state);
      return state;
    },
    setVehiclesPageTypeWithNewTypes: (state, action) => {
      state.vehicleTypes = [action.payload, ...state.vehicleTypes];
      state.vehicles = prepareVehiclesData(state);
      return state;
    },
    setVehiclesPageTypeWithUpdatedTypes: (state, action) => {
      state.vehicleTypes = state.vehicleTypes.map((vehicleType) =>
        vehicleType.id === action.payload.id ? action.payload : vehicleType
      );
      state.vehicles = prepareVehiclesData(state);
      return state;
    },
    setVehiclesPageTypes: (state, action) => {
      state.vehicleTypes = action.payload;
      state.vehicles = prepareVehiclesData(state);
      return state;
    },
    updateVehiclesPageDeployments: (state, action) => {
      const updatedDeployment: Deployment = action.payload;
      if (!updatedDeployment.vehicleId || !updatedDeployment.vehicleDeployment)
        return state;

      const assignedDeploymentsByVehicleId =
        state.assignedDeploymentsByVehicleId;
      assignedDeploymentsByVehicleId[updatedDeployment.vehicleId] = {
        ...updatedDeployment,
      };
      state.assignedDeploymentsByVehicleId = assignedDeploymentsByVehicleId;
      state.vehicles = prepareVehiclesData(state);

      return state;
    },
    updateVehicleDriveModeStatus: (state, action) => {
      try {
        const currentDriveMode =
          state.driveModeStatusesByVehicleId[action.payload.vehicle_id];
        const newDriveMode = action.payload.t_drive_mode;
        if (currentDriveMode !== newDriveMode) {
          state.driveModeStatusesByVehicleId[action.payload.vehicle_id] =
            action.payload.t_drive_mode;
          state.vehicles = prepareVehiclesData(state);
        }
      } catch (e) {
        toast.error("Can not update drive mode status");
      }

      return state;
    },
    setInitialDriveModeStatuses: (state, action) => {
      state.driveModeStatusesByVehicleId = action.payload;
      state.vehicles = prepareVehiclesData(state);
      return state;
    },
    setVehiclesPageSorting: (state, action) => {
      state.vehicles = sortByColumn(
        state.vehicles,
        action.payload.columnName,
        action.payload.order
      );
      return state;
    },
  },
});

export default Slice;
