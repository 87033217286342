import { QueueManagerDeploymentData } from "./types";

export const filterDeployments = (
  deploymentsData: QueueManagerDeploymentData,
  searchString: string
) => {
  if (!searchString) return deploymentsData;

  const filteredData: QueueManagerDeploymentData = {};

  Object.keys(deploymentsData).forEach((vehicleId) => {
    const deployments = deploymentsData[vehicleId].filter((deployment) =>
      deployment.name?.toLowerCase().includes(searchString.toLowerCase())
    );

    filteredData[vehicleId] = deployments;
  });

  return filteredData;
};
