import { createAsyncThunk } from "@reduxjs/toolkit";
import { z } from "zod";

import API, { showNetworkErrorToast } from "Utils/api";

export const fetchGetFlags = createAsyncThunk(
  "flags/fetchGetFlags",
  async () => {
    try {
      const response = await API.get("feature_flags/");

      return FlagsValidator.parse(response.data.results);
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

// validators
const FlagsValidator = z.object({
  unhitch: z.record(z.string(), z.boolean()).optional(),
  missionUpdates: z
    .union([z.literal(0), z.literal(1), z.literal(2)])
    .optional(),
});
