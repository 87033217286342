import styled from "styled-components";

export const LiveDeploymentsWrapperStyled = styled.div`
  height: calc(100% - 110px);
`;

export const MissionNameCellStyled = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  color: #3f7f7d;
`;

export const VehicleTypeImgStyled = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
`;

export const VehicleNameStyled = styled.div`
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const VehicleFieldBodyStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const UniqTextStyled = styled.div`
  display: contents;
  font-weight: 700;
`;
