import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import API, { showNetworkErrorToast } from "Utils/api";
import { removeMissionPlan, updateMissionPlan } from "Slices/missionPlans";
import { MissionPlansValidator } from "Slices/validationTypes";

export const fetchGetMissionPlans = createAsyncThunk(
  "missions/fetchGetMissionPlans",
  async (semanticMap: string) => {
    try {
      const response = await API.get(
        `mission_plans/?semantic_map=${semanticMap}`
      );
      const parsedMissionPlans = MissionPlansValidator.parse(
        response.data.results
      );
      return {
        missionPlans: parsedMissionPlans,
        semanticMap,
      };
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

export const fetchGetMissionPlanById = async (
  id: string | undefined
): Promise<MissionPlan> => {
  try {
    const response = await API.get(`mission_plans/${id}/`);
    return response.data;
  } catch (error) {
    showNetworkErrorToast(error);
    return Promise.reject();
  }
};

type MissionCorePatch = {
  name: string;
  semanticMap: string;
  plan: {
    name: string;
    actions: Array<MissionActionPatch>;
  };
};

export const fetchPostMissionPlan = createAsyncThunk(
  "missions/fetchPostMissionPlan",
  async (data: MissionCorePatch, thunkAPI) => {
    try {
      const response = await API.post("mission_plans/", data);
      await thunkAPI.dispatch(fetchGetMissionPlans(data.semanticMap));
      toast.success("Mission plan Added");
      return response.data;
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

export const fetchPatchMissionPlan = createAsyncThunk<
  void,
  {
    id: string;
    data: MissionPlanPatch;
    hideToast?: boolean;
  }
>("missions/fetchPatchMissionPlan", async (missionPlanData, thunkAPI) => {
  try {
    const response = await API.patch(
      `mission_plans/${missionPlanData.id}/`,
      missionPlanData.data
    );
    await thunkAPI.dispatch(updateMissionPlan(response.data));
    if (!missionPlanData.hideToast) {
      toast.success("Mission plan Changed");
    }
  } catch (error) {
    showNetworkErrorToast(error);
    return Promise.reject();
  }
});

export const fetchDeleteMissionPlan = createAsyncThunk<void, string>(
  "missions/fetchDeleteMissionPlan",
  async (id: string, thunkAPI) => {
    try {
      await API.delete(`missions/${id}`);
      await thunkAPI.dispatch(removeMissionPlan(id));
      toast.success("Mission plan Deleted");
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

export const fetchDeployMissionPlan = createAsyncThunk(
  "missions/fetchDeployMissionPlan",
  async (data: Omit<Deployment, "id" | "actions">) => {
    try {
      const missionPlans = await API.post("/mission_deployment/", {
        ...data,
        // it is required to support tracking of an operator-launched mission
        // mission: data.id,
      });
      toast.success("Mission plan Deployed");
      return missionPlans.data;
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);
