import styled from "styled-components";

export const CallVehicleButtonStyled = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 32px;
  gap: 8px;
  width: 414px;
  height: 168px;
  background: #3f7f7d;
  font-size: 20px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  font-family: "Readex Pro", sans-serif;

  &:disabled {
    border: none;
    cursor: default;
    background: #d6d8de;
    color: #606060;

    svg {
      path {
        stroke: #606060;
      }
    }
  }
`;
