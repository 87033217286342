import styled from "styled-components";

export const MissionDetailsHelperTextStyled = styled.div`
  display: flex;
  margin-bottom: 16px;
  font-size: 10px;
  line-height: 120%;
  color: #004ecc;

  svg {
    margin-right: 4px;
  }
`;

export const MissionDetailsItemContentStyled = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-left: 0;
`;

export const MissionDetailsItemStyled = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 0 4px;
  margin-bottom: 8px;
  height: 36px;
`;

interface LabelProps {
  bold?: boolean;
  header?: boolean;
}

export const MissionDetailsLabelStyled = styled.div<LabelProps>`
  font-weight: ${(props) => (props.bold ? "800" : "normal")};
  display: flex;
  align-items: flex-end;
  font-size: 24px;
  line-height: 1.5;
  margin-bottom: 2px;
  letter-spacing: 0.005em;
`;

export const MissionDetailsModalBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
`;

export const MissionDetailsModalContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  padding: 40px;
  width: 455px;
  height: max-content;
  background: #ffffff;
  z-index: 1;
  border-radius: 4px;
`;

export const MissionDetailsModalDropdown = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

export const MissionDetailsModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 60px;
  width: 650px;
  height: 100%;
  position: fixed;
  background: rgba(37, 63, 115, 0.5);
  top: 0;
  left: 0;
  z-index: 1;
`;

export const MissionDetailsTitleItemStyled = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 12px;
`;

export const MissionDetailsModalHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  justify-content: start;
  width: 100%;
`;
