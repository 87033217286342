import styled from "styled-components";

interface CounterProps {
  zeroCounter?: boolean;
  isInfinity?: boolean;
}

export const CounterBodyStyled = styled.div`
  height: 24px;
`;

export const CounterStopsStyled = styled.div<CounterProps>`
  position: relative;
  bottom: 26px;
  font-family: "Readex Pro", sans-serif;
  font-size: 12px;
  text-align: center;
  color: ${(props) => (props.zeroCounter ? "#a4a4a4" : "#606060")};
`;

export const CounterLoopsStyled = styled.div<CounterProps>`
  font-family: "Readex Pro", sans-serif;
  font-size: 12px;
  text-align: ${(props) => (props.isInfinity ? "start" : "center")};
  color: ${(props) => (props.zeroCounter ? "#a4a4a4" : "#606060")};
`;

export const DividerStopsCountersStyled = styled.div`
  font-family: "Readex Pro", sans-serif;
  font-weight: 300;
  font-size: 12px;
  color: #a4a4a4;
`;

export const DividerLoopsCountersStyled = styled.div`
  padding: 0 4px;
`;
