import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { z } from "zod";

import API, { showNetworkErrorToast } from "Utils/api";

import { addNewFleet, setFleets } from ".";
import { setVehiclesPageFleets } from "../vehiclesPage";
import { updateVehicles } from "../vehicles";

export const fetchGetFleets = createAsyncThunk(
  "fleets/fetchGetFleets",
  async (_, thunkAPI) => {
    try {
      const response = await API.get("fleets/");
      const validatedFleets = FleetsValidator.parse(response.data.results);

      thunkAPI.dispatch(setVehiclesPageFleets(validatedFleets));
      return validatedFleets;
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

export const fetchPostFleet = createAsyncThunk<void, FleetPatch>(
  "fleets/fetchPostFleet",
  async (data, thunkAPI) => {
    try {
      const response = await API.post("/fleets/", { ...data });
      thunkAPI.dispatch(addNewFleet(response.data));

      const updatedVehicles = await API.get("vehicles/");
      thunkAPI.dispatch(updateVehicles(updatedVehicles.data.results));

      toast.success("Fleet added successfully");
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

export const fetchUpdateFleetById = createAsyncThunk<
  void,
  { id: string; data: FleetPatch }
>("fleets/fetchUpdateFleetById", async ({ id, data }, thunkAPI) => {
  try {
    await API.patch(`/fleets/${id}/`, data);

    const updatedVehicles = await API.get("vehicles/");
    thunkAPI.dispatch(updateVehicles(updatedVehicles.data.results));

    const getResponse = await API.get("fleets/");
    thunkAPI.dispatch(setFleets(getResponse.data.results));
    thunkAPI.dispatch(setVehiclesPageFleets(getResponse.data.results));
    toast.success(`Fleet ${data.name} was updated`);
  } catch (error) {
    showNetworkErrorToast(error);
    return Promise.reject();
  }
});

// validators

const MissionId = z.string();

export const FleetsValidator = z.array(
  z.object({
    id: z.string(),
    name: z.string(),
    semanticMapId: z.string(),
    vehiclesId: z.array(z.string()),
    onVehicleMissionsData: z.record(z.string(), MissionId),
    returnHomeMission: MissionId.nullable(),
    missionId: MissionId,
    shortName: z.string(),
    url: z.string(),
    siteId: z.string(),
  })
);
