import { createSelector } from "@reduxjs/toolkit";
import { getCurrentUserCompanyId } from "Slices/currentUser";

import { RootState } from "../../index";
import { DataStatus } from "../../types";
import { MissionPageUpdates } from "Slices/flags/index";

export const getIsFlagsLoaded = (state: RootState): boolean =>
  state.flags.status === DataStatus.succeeded;
export const getIsFlagsLoadingFailed = (state: RootState): boolean =>
  state.flags.status === DataStatus.failed;

const getUnhitchFlags = (state: RootState) => state.flags.data?.unhitch || null;
export const isUnhitchEnable = createSelector(
  getCurrentUserCompanyId,
  getUnhitchFlags,
  (companyId, unhitchFlags) => {
    if (!companyId) return false;
    return unhitchFlags?.[companyId] ?? false;
  }
);
export const getMissionUpdatesFlag = (state: RootState) =>
  state.flags.data?.missionUpdates ?? MissionPageUpdates.enabledAllMissionPages;
