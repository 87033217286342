import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../../index";
import { arrayToObjectByField } from "Utils";
import { DataStatus } from "../../types";

export const getVehicleTypes = (state: RootState) => state.vehicleTypes.data;
export const getVehicleTypesTransportFacilityModals = createSelector(
  getVehicleTypes,
  (vehicleTypes) =>
    vehicleTypes.map(({ id, name }) => ({
      id,
      text: name,
    }))
);
export const getVehicleTypesById = createSelector(
  getVehicleTypes,
  (vehicleTypes) => arrayToObjectByField(vehicleTypes)
);
const getVehicleTypesStatus = (state: RootState) => state.vehicleTypes.status;

export const getIsVehicleTypesLoaded = createSelector(
  getVehicleTypesStatus,
  (status) => status === DataStatus.succeeded
);
export const getIsVehicleTypesLoadingError = createSelector(
  getVehicleTypesStatus,
  (status) => status === DataStatus.failed
);

export const getVehicleTypeById = (vehicleTypeId: string | undefined) =>
  createSelector(getVehicleTypes, (vehicleTypes) => {
    if (!vehicleTypes || !vehicleTypeId) return;
    return vehicleTypes.find((vehicleType) => vehicleType.id === vehicleTypeId);
  });
