import React, {
  memo,
  FC,
  useState,
  useRef,
  useMemo,
  useCallback,
  ReactNode,
} from "react";

import { LabelField } from "../LabelField";
import { useOnClickOutside } from "Utils/hooks";
import { SearchDropdownListItem } from "../SearchDropdownListItem";
import { HelperText } from "Components/common/HelperText/HelperText";
import { ErrorText } from "Components/common/ErrorText/ErrorText";

import { ReactComponent as ArrowIcon } from "../../assets/arrowIcon.svg";
import { ReactComponent as CancelSelectedItemIcon } from "../../assets/cancelSelectedItemIcon.svg";
import { ReactComponent as SearchIcon } from "../../assets/searchIcon.svg";
import {
  SearchDropdownInputStyled,
  SearchDropdownContainerStyled,
  SearchInputContainerStyled,
  SearchDropdownBtnStyled,
  SearchDropdownListContainerStyled,
  SearchDropdownListStyled,
  DelimiterStyled,
  SearchDropdownPickedItemsStyled,
  SearchDropdownPickedItemsContainerStyled,
  SearchIconStyled,
} from "./styles";
import {
  DropdownListItemStyled,
  DropdownListTextItemStyled,
} from "./../SearchDropdownListItem/styles";

interface SearchDropdownProps {
  isLoading?: boolean;
  isLoadingError?: boolean;
  loadingErrorText?: string;
  pickedItems: Array<ModalItem>;
  labelText: string;
  placeholderText: string;
  isRequired?: boolean;
  additionalInfo?: string;
  options: Array<ModalItem>;
  changeAction: (pickedItems: Array<ModalItem>) => void;
  children?: ReactNode;
}

export const SearchDropdown: FC<SearchDropdownProps> = memo(
  ({
    isLoading = false,
    isLoadingError = false,
    loadingErrorText = "",
    pickedItems,
    labelText,
    placeholderText,
    options,
    additionalInfo,
    isRequired = false,
    changeAction,
    children,
  }) => {
    const [searchValue, setSearchValue] = useState<string>("");
    const [isOpened, setIsOpened] = useState<boolean>(false);

    const dropdownRef = useRef() as React.MutableRefObject<HTMLDivElement>;
    const handleClickOutside = useCallback(() => setIsOpened(false), []);
    useOnClickOutside(dropdownRef, handleClickOutside);

    const filteredOptions: Array<ModalItem> = useMemo(
      () =>
        options.filter((option) =>
          option.text.toLowerCase().includes(searchValue.toLowerCase())
        ),
      [options, searchValue]
    );

    const pickedItemsIds: Array<string> = useMemo(
      () => pickedItems.map((pickedItem) => pickedItem.id),
      [pickedItems]
    );

    const changeItemAction = useCallback(
      (isSelected: boolean, item: ModalItem) =>
        isSelected
          ? changeAction(
              [...pickedItems, item].sort((prev, next) =>
                prev.text.localeCompare(next.text)
              )
            )
          : changeAction(
              pickedItems.filter((pickedItem) => pickedItem.id !== item.id)
            ),
      [pickedItems]
    );

    const dropdownFooter = useMemo(() => {
      if (isLoadingError) {
        return <ErrorText errorMessage={loadingErrorText} />;
      } else if (additionalInfo) {
        return <HelperText additionalInfo={additionalInfo} />;
      } else return null;
    }, [isLoadingError, additionalInfo]);

    return (
      <>
        <LabelField labelText={labelText} isRequired={isRequired} />
        <SearchDropdownContainerStyled ref={dropdownRef}>
          {children}
          <SearchInputContainerStyled>
            <SearchIconStyled>
              <SearchIcon />
            </SearchIconStyled>
            <SearchDropdownInputStyled
              isLoading={isLoading}
              onClick={() => {
                if (!isLoading) setIsOpened(true);
              }}
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              placeholder={placeholderText}
            />
            <SearchDropdownBtnStyled
              isOpened={isOpened}
              onClick={() => setIsOpened(!isOpened)}
            >
              <ArrowIcon />
            </SearchDropdownBtnStyled>
          </SearchInputContainerStyled>
          {isOpened && (
            <SearchDropdownListContainerStyled>
              <DelimiterStyled />
              <SearchDropdownListStyled>
                {filteredOptions.length > 0 &&
                  filteredOptions.map((item, index) => (
                    <SearchDropdownListItem
                      changeItemAction={changeItemAction}
                      item={item}
                      key={`${item.id}-${index}`}
                      isSelected={pickedItemsIds.includes(item.id)}
                    />
                  ))}
                {filteredOptions.length === 0 && (
                  <DropdownListItemStyled>
                    <DropdownListTextItemStyled>
                      Empty
                    </DropdownListTextItemStyled>
                  </DropdownListItemStyled>
                )}
              </SearchDropdownListStyled>
            </SearchDropdownListContainerStyled>
          )}
          {dropdownFooter}
          <SearchDropdownPickedItemsContainerStyled>
            {pickedItems.map((pickedItem) => (
              <SearchDropdownPickedItemsStyled key={pickedItem.id}>
                {pickedItem.text}
                <CancelSelectedItemIcon
                  onClick={() => changeItemAction(false, pickedItem)}
                />
              </SearchDropdownPickedItemsStyled>
            ))}
          </SearchDropdownPickedItemsContainerStyled>
        </SearchDropdownContainerStyled>
      </>
    );
  }
);
