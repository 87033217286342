export const parseDateTime = (dateString: string) => {
  const parsedDate = new Date(dateString);
  const year = parsedDate.toLocaleString("en-US", { year: "numeric" });
  const month = parsedDate.toLocaleString("en-US", {
    month: "2-digit",
  });
  const day = parsedDate.toLocaleString("en-US", { day: "2-digit" });
  const time = new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }).format(parsedDate);

  return [month, day, year, time].join("-");
};
