import React, { ChangeEvent, FC } from "react";

import {
  CheckboxContainerStyled,
  HiddenCheckboxStyled,
  StyledCheckboxStyled,
  Icon,
  CheckboxLabelContainerStyled,
} from "./styles";

type CheckboxComponentProps = {
  checked: boolean;
  disabled?: boolean;
  labelText: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const CheckboxComponent: FC<CheckboxComponentProps> = ({
  checked,
  disabled,
  labelText,
  onChange,
}) => (
  <CheckboxLabelContainerStyled checked={checked}>
    <CheckboxContainerStyled>
      <HiddenCheckboxStyled
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
      <StyledCheckboxStyled checked={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckboxStyled>
    </CheckboxContainerStyled>
    <span>{labelText}</span>
  </CheckboxLabelContainerStyled>
);
