import {
  fetchGetMissionQueue,
  cancelDeployment,
  changeMissionOrder,
} from "./actions";
import {
  getMissionPlannerFleetId,
  getMissionRowsLimit,
  getSearchValue,
  getHeaderData,
  getActiveTab,
  getMissionPlannerPeriod,
  getAllMissionsCount,
  getDeployedMissionsCount,
  getCompletedMissionsCount,
  getDeployments,
  getSelectedVehicleIds,
  getIsAllDeploymentsUpdating,
  getIsMissionsUpdatesPaused,
  getMissionsUpdatesStorage,
} from "./selectors";
import MissionsQueueSlice from "./reducers";
import { getSavedPeriod, getSavedVehiclesIds } from "./utils";

//utils
export { getSavedPeriod, getSavedVehiclesIds };

// actions
export const {
  setMissionsQueue,
  setCurrentFleetPeriod,
  setMissionRowsLimit,
  setCurrentSearchValue,
  setCurrentFleetId,
  setActiveTab,
  setMissionCounts,
  updateDeploymentsQueue,
  resetMissionsQueue,
  setSelectedVehicleIds,
  setIsMissionPlannerUpdating,
  resetPagination,
  reorderMissionsQueue,
  pauseMissionsUpdates,
  continueMissionsUpdates,
  resetMissionsUpdatesStorage,
  resetMissionPlannerData,
} = MissionsQueueSlice.actions;

// async actions
export { fetchGetMissionQueue, cancelDeployment, changeMissionOrder };

// selectors
export {
  getMissionPlannerFleetId,
  getMissionRowsLimit,
  getSearchValue,
  getHeaderData,
  getActiveTab,
  getMissionPlannerPeriod,
  getAllMissionsCount,
  getDeployedMissionsCount,
  getCompletedMissionsCount,
  getDeployments,
  getSelectedVehicleIds,
  getIsAllDeploymentsUpdating,
  getIsMissionsUpdatesPaused,
  getMissionsUpdatesStorage,
};

// reducer
export default MissionsQueueSlice.reducer;
