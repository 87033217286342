import React, { FC } from "react";

import { ReactComponent as EmptyImg } from "../../../common/assets/empty.svg";

import {
  EmptyCoverStyled,
  EmptyCoverTextStyled,
  EmptyCoverBtnStyled,
} from "./styles";

type EmptyCoverProps = {
  btnClick: () => void;
};

export const EmptyCover: FC<EmptyCoverProps> = ({ btnClick }) => {
  return (
    <EmptyCoverStyled>
      <EmptyImg />
      <EmptyCoverTextStyled>
        Looks like you have no mission history saved.
      </EmptyCoverTextStyled>
      <EmptyCoverBtnStyled onClick={btnClick}>
        Plan a New Mission
      </EmptyCoverBtnStyled>
    </EmptyCoverStyled>
  );
};
